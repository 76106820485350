import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import { Checkbox, Tooltip } from "@material-ui/core";

const FromTenants = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `tenant_tenant_mappings?to_tenant=${props.user.tenant}`;

  const tableColumns = [
    {
      id: "from_tenant_name",
      label: "FROM TENANT",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "accepted",
      label: "",
      minWidth: 300,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach((mapping) => {
          mapping["accepted"] =  
            <Tooltip title="Accepted">
            <Checkbox style={{float:"right"}}
              checked={mapping.is_accepted}
              onClick={(e) => {
                mapping.is_accepted = !mapping.is_accepted;                
                http.Put(
                  `tenant_tenant_mappings/${mapping.id}`,
                  mapping,
                  () => {
                    fetchData();
                  },
                  (status, error) => {
                  }
                );
              }}
            /></Tooltip>;
        });
        setData(data);
        setLoadData(false);
      },
      () => {}
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Tenants"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["from_tenant_name",]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Tenants"}
      url={url}
      tableColumns={tableColumns}
      searchFields={["from_tenant_name",]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
      excludeAddButton={true}
    />
    }
    </>
  );
};

export default FromTenants;
