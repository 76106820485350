import React, { useEffect, useState } from "react";
import ServiceForm from "./ServiceForm";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onWebsiteClick, onCopy } from "../../Common/TableUtility";
import { format } from "date-fns-tz";
import { ConvertDateToLocale } from "../../Common/Utility";
import SnackbarComponent from "../../Common/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import ObjectVersions from "../../Common/ObjectVersions";
import HistoryIcon from "@material-ui/icons/History";
import OTP from "../../Common/OTP";
import { Dialog } from "@material-ui/core";
import ServiceDetails from "./ServiceDetails";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
    color: theme.linkColorFaded,
    "&:hover": {
      color: theme.linkColor,
    },
    width: 15,
    float: "right",
  },
  passwordColorScheme: {
    cursor: "pointer",
    // color: theme.colorScheme,
    fontFamily: "Raleway",
  },
}));

const Services = (props) => {
  const selectedSite = localStorage.getItem("Site");
  const classes = useStyles();
  const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [site, setSite] = useState(selectedSite);
  const [unchangedData, setUnchangedData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();
  const url = `companies/${id}/services`;
  const csvHeader = commonConstants.serviceHeader;
  const exportFormat = commonConstants.serviceExportFormat;
  const [anchorEl, setAnchorEl] = useState(null);
  const [versions, setVersions] = useState();
  const actions = {
    delete:
      props.companyPermission && props.companyPermission === "readAndWrite",
    importCSV:
      props.companyPermission && props.companyPermission === "readAndWrite",
    exportCSV: true,
    exportPDF: false,
  };
  const [objectId, setObjectId] = useState();

  const tableColumns = [
    {
      id: "service_name",
      label: "SERVICE NAME",
      minWidth: 150,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, object, property) => {
            setObjectId(object.id);
          },
    },
    {
      id: "description",
      label: "DESCRIPTION",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "userNameCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "username",
      label: "USERNAME",
      minWidth: 100,
      sortable: true,
    },
    {
      id: "passwordCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "staredPassword",
      label: "PASSWORD",
      minWidth: 100,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "websiteCopy",
      label: "",
      minWidth: 10,
      sortable: false,
      disableTooltip: true,
    },
    {
      id: "website",
      label: "WEBSITE",
      minWidth: 150,
      sortable: true,
      onClick: onWebsiteClick,
      style: commonConstants.cellCursor,
      colored: true,
    },
    {
      id: "notes",
      label: "NOTES",
      minWidth: 150,
      sortable: false,
    },
    {
      id: "otp",
      label: " ",
      minWidth: 100,
      disableTooltip: true,
      colored: true,
    },
    {
      id: "versions",
      label: " ",
      minWidth: 10,
      disableTooltip: true,
      style: commonConstants.cellCursor,
      colored: true,
    },
  ];

  useEffect(() => {
    var showId = new URLSearchParams(window.location.search).get("show_id");
    if (showId) {
      setObjectId(showId);
      props.history.replace({
        search: window.location.search.replace("&show_id=" + showId, ""),
      });
    }

    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    const services = [];

    http.Get(
      url,
      (data) => {
        setUnchangedData(
          data.map((item) => {
            const obj = {};
            Object.keys(exportFormat).forEach((key) => {
              if (commonConstants.dateTimeFields.includes(key))
                obj[[exportFormat[key]]] = format(
                  ConvertDateToLocale(item[key]),
                  "MM/dd/yyyy"
                );
              else obj[[exportFormat[key]]] = item[key];
            });
            return obj;
          })
        );
        data.map((item) => {
          if (item["username"]){
          item["userNameCopy"] = (
            <>
              <FilterNoneIcon
                fontSize="small"
                className={classes.iconColorScheme}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "username");
                  setSnackBarMessage("Username Copied");
                }}
              />
            </>
          );}
          if (item["password"]){
          item["passwordCopy"] = (
            <>
              <FilterNoneIcon
                fontSize="small"
                className={classes.iconColorScheme}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "password");
                  setSnackBarMessage("Password Copied");
                }}
              />
            </>
          );}

          item["staredPassword"] = !item["password"] ? (
            ""
          ) : (
            <span
              className={classes.passwordColorScheme}
              onClick={(e) => {
                if (e.target.innerHTML === "************")
                  e.target.innerHTML = String(item["password"])
                    .replaceAll("<", "&lt;")
                    .replaceAll(">", "&gt;");
                else e.target.innerHTML = "************";
              }}
            >
              ************
            </span>
          );
          if (item["website"]){
          item["websiteCopy"] = (
            <>
              <FilterNoneIcon
                fontSize="small"
                className={classes.iconColorScheme}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "website");
                  setSnackBarMessage("Website Copied");
                }}
              />
            </>
          );}

          item["otp"] = <OTP {...props} otpKey={item["otp"]} />;
          item["versions"] = (
            <HistoryIcon
              className={classes.cursor}
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
                setVersions(null);
                http.Get(
                  `versions?object_id=${item.id}&model_name=companyservice`,
                  (versions) => {
                    setVersions(versions);
                  },
                  () => {
                    setVersions(null);
                  }
                );
              }}
            />
          );

          return site === "All" || item["location_id"] == site
            ? services.push(item)
            : "";
        });
        setData(services);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <div>
      {objectId && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(objectId)}
          onClose={() => {
            setObjectId(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <ServiceDetails
              {...props}
              objectId={objectId}
              handleClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
              onClose={() => {
                setObjectId(null);
                if (props.handleClose) {
                  props.handleClose();
                }
              }}
            />
          </div>
        </Dialog>
      )}
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={"success"}
      ></SnackbarComponent>
      {anchorEl && (
        <ObjectVersions
          {...props}
          versions={versions}
          fetchData={fetchData}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        />
      )}
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={"Other Services"}
          url={url}
          importUrl={`companies/${id}/import_services`}
          csvHeader={csvHeader}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          unchangedData={unchangedData}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_other_service > -1 &&
              unchangedData.length >=
                props.companyTenant.max_company_other_service)
              ? null
              : ServiceForm
          }
          orderBy={"service_name"}
          searchFields={["service_name", "description", "notes"]}
          pageHeader={(props.company.company_name || "") + " | Other Services"}
          loadData={loadData}
          packageLimit={props.companyTenant.max_company_other_service}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Other Services"}
          url={url}
          importUrl={`companies/${id}/import_services`}
          csvHeader={csvHeader}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          unchangedData={unchangedData}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_other_service > -1 &&
              unchangedData.length >=
                props.companyTenant.max_company_other_service)
              ? null
              : ServiceForm
          }
          orderBy={"service_name"}
          searchFields={["service_name", "description", "notes"]}
          pageHeader={(props.company.company_name || "") + " | Other Services"}
          loadData={loadData}
          packageLimit={props.companyTenant.max_company_other_service}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Services);
