import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import countryCodes from "../../Common/CountryCodes";
import ScreenLoader from "../../Common/Loader";

const EmployeeForm = (props) => {
  const { id } = props.match.params;
  const [formFields, setFormFields] = useState();
  const [categories, setCategories] = useState();
  const [category, setCategory] = useState();
  const [roles, setRoles] = useState();
  const [role, setRole] = useState();
  const [sites, setSites] = useState();
  const [loadData, setLoadData] = useState(false);
  const [status, setStatus] = useState(true);
  var countryCodeMap = { "": "NONE" };

  Object.keys(countryCodes).forEach(function (c) {
    if (!countryCodeMap[countryCodes[c].code]) {
      countryCodeMap[countryCodes[c].code] = countryCodes[c].name;
    } else {
      countryCodeMap[countryCodes[c].code] =
        countryCodeMap[countryCodes[c].code] + " / " + countryCodes[c].name;
    }
  });

  Object.entries(countryCodeMap).map(([key, value]) => {
    countryCodeMap[key] = value + " (" + key + ")";
  });

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Employee Type`,
      (data) => {
        var valueMap = { "": "NONE" };
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
          if (data[i].is_default) {
            setCategory(data[i].id);
          }
        }
        setCategories(valueMap);
        setLoadData(false);
      },
      () => {}
    );

    http.Get(
      `portal-roles?tenant=${props.companyTenant.id}`,
      (data) => {
        data = data.filter((role) => role.status)
        const map = {
          '-1': 'NONE'
        };
        for (let i = 0; i < data.length; i++) {
          map[data[i].id] = data[i].role_name;
        }
        setRoles(map);
        setLoadData(false);
      },
      () => {}
    );

    http.Get(
      `companies/${id}/sites`,
      (data) => {
        var valueMap = { "": "NONE" };
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].site_name;
        }
        setSites(valueMap);
        setLoadData(false);
      },
      () => {}
    );

    if (props.objectId) {
      http.Get(
        `company_employees/${props.objectId}`,
        (object) => {
          setStatus(object.status);
          setRole(object.role_id);
          setLoadData(false);
        },
        () => {}
      );
    }
  }, []);

  useEffect(() => {
    if (
      sites &&
      categories &&
      roles
    ) {
      setFormFields([
        {
          id: "employee_name",
          label: "EMPLOYEE NAME *",
          type: status ? "text" : "readonly",
        },
        {
          id: "email",
          label: "EMAIL",
          type: "email",
        },
        {
          id: "location_id",
          label: "SITE",
          type: "select",
          valueMap: sites,
        },
        {
          id: "phone_number",
          label: "PHONE NUMBER",
          type: "text",
        },
        {
          id: "mobile",
          label: "MOBILE",
          type: "text",
        },
        {
          id: "country_code",
          label: "COUNTRY CODE",
          type: "select",
          valueMap: countryCodeMap,
        },
        {
          id: "short_note",
          label: "SHORT NOTE",
          type: "text",
        },
        {
          id: "work_station",
          label: "WORKSTATION",
          type: "text",
        },
        {
          id: "category",
          label: "CATEGORY",
          type: "select",
          valueMap: categories,
        },
        {
          id: "status",
          label: "ACTIVE",
          type: "radio",
          onChange: (e, object, setObject, setErrors, value) => {
            setStatus(!object[e.target.name]);
            setObject({
              ...object,
              [e.target.name]: !object[e.target.name],
            });
          },
        },
        {
          id: "role_id",
          label: "PORTAL ROLE",
          type: "select",
          valueMap: roles,
          onChange: (e, object, setObject) => {
            setObject({
              ...object,
              [e.target.name]: e.target.value !== '-1' ? e.target.value : null,
            });
          },
        },
        {
          id: "is_hidden",
          label: "HIDDEN",
          type: "radio",
        },
        {
          id: "is_billing_contact",
          label: "BILLING CONTACT",
          type: "radio",
        },
        {
          id: "dataMappings",
          label: "RELATED ENTRIES",
          type: "dataMapping",
          model: "Employee",
          objectId: props.objectId,
        },
      ]);
    }
  }, [
    sites,
    categories,
    roles,
  ]);

  return (
    <div>
      {loadData && <ScreenLoader/>}

      {formFields && (
        <ObjectForm
          {...props}
          url={`company_employees`}
          formName={"Employees"}
          formFields={formFields}
          object={{
            company_id: id,
            status: true,
            category: category,
            role_id: role,
          }}
        />
      )}
    </div>
  );
};

export default EmployeeForm;
