import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState, useEffect } from "react";
import http from "../../../Common/RestAPIHandler";
import Checkbox from "@material-ui/core/Checkbox";
import { stableSort, getSorting } from "../../../Common/Utility";
import { Chip } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
	colorScheme: {
		color: theme.colorScheme
	},
  containerItem: {
    textAlign: "right",
  },
  chipOutlined: {
    borderRadius: 8,
    borderColor: theme.colorScheme,
    marginRight: "2px",
    color: theme.subText,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  input: {
		marginLeft: theme.spacing(2),
  },
  iconButton: {
		padding: 5,
  },
  divContent: {
		border: "1px solid #cecece",
    padding: 20,
    height: "calc(100vh/2.4)",
    overflow: "auto",
    color: theme.colorScheme,
  },
  textColor: {
		color: theme.colorScheme,
  },
  checkbox: {
		width: "max-content",
  },
  floatRight: {
		color: theme.colorScheme,
    float: "right",
    cursor: "pointer"
  },
	search: {
		display: "flex",
		minWidth: 100,
		height: 32,
		border: "1px solid rgba(0, 0, 0, 0.23)",
		borderColor: theme.borderColor + " !important"
	},
	subText: {
		color: theme.subText
	},
	text: {
		color: theme.text
	}
}));

const RolePermission = (props) => {
  const classes = useStyles();
  const [permissionCheck, setPermissionCheck] = useState({});
  const [companies, setCompanies] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const [companyCheck, setCompanyCheck] = useState({});
  const [assignedCompanyCheck, setAssignedCompanyCheck] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [search, setSearch] = useState("");
  const { role_id } = props.match.params;
  const [permissions, setPermissions] = useState([]);
  const [permissionCategories, setPermissionCategories] = useState([]);
  const [role, setRole] = useState({});

  useEffect(() => {
    http.Get(
      `roles/${role_id}`,
      (role) => {
        console.log(role);
        setRole(role);
      },
      () => {}
    );
    http.Get(
      `permissions`,
      (permissions) => {
        setPermissions(permissions);
      },
      () => {}
    );
    http.Get(
      `permission_categories`,
      (permissionCategories) => {
        permissionCategories.map((permissionCategory)=>{
          permissionCategory["show"] = true;
        });
        setPermissionCategories(permissionCategories);
      },
      () => {}
    );
    fetchRolePermission();
    fetchRoleCompanies();
    http.Get(
      `companies`,
      (companies) => {
        var sortedObjects = stableSort(
          companies,
          getSorting("asc", "company_name")
        );
        setCompanyList(sortedObjects);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    var searchedCompanies = companyList.filter((object) =>
      object.company_name.toLowerCase().includes(search.toLowerCase())
    );
    setCompanies(searchedCompanies);
    let checks = {};
    Object.keys(assignedCompanyCheck).forEach((key) => {
      let isExist = searchedCompanies.some((item) => item.id === key);
      if (isExist) checks[key] = isExist;
    });
    setCompanyCheck(checks);
  }, [companyList, search, assignedCompanyCheck]);

  useEffect(() => {
    const checkLength = Object.keys(companyCheck).length;
    setCheckAll(checkLength > 0 && companies.length === checkLength);
  }, [companyCheck]);

  const fetchRolePermission = () => {
    http.Get(
      `roles/${role_id}/permissions`,
      (data) => {
        let checks = {};
        data.forEach((item) => {
          checks[item.permission_id] = true;
        });
        setPermissionCheck(checks);
      },
      () => {}
    );
  };

  const fetchRoleCompanies = () => {
    http.Get(
      `roles/${role_id}/companies`,
      (data) => {
        let checks = {};
        data.forEach((item) => {
          checks[item.company_id] = true;
        });
        setAssignedCompanyCheck(checks);
      },
      () => {}
    );
  };

  const postRoleCompanies = (data) => {
    http.Post(
      `roles/${role_id}/companies`,
      data,
      () => {
        fetchRoleCompanies();
      },
      () => {}
    );
  };

  const deleteRoleCompanies = (data) => {
    http.Delete(
      `roles/${role_id}/companies`,
      { ids: data },
      () => {
        fetchRoleCompanies();
      },
      () => {}
    );
  };

  const handlePermissionCheckChange = (permissionId) => (event) => {
    setPermissionCheck({
      ...permissionCheck,
      [permissionId]: event.target.checked,
    });
    if (event.target.checked) {
      http.Post(
        `roles/${role_id}/permissions/${permissionId}`,
        {},
        () => {
          fetchRolePermission();
        },
        () => {}
      );
    } else {
      http.Delete(
        `roles/${role_id}/permissions/${permissionId}`,
        {},
        () => {
          fetchRolePermission();
        },
        () => {}
      );
    }
  };

  const handleCompanyCheckChange = (companyId) => (event) => {
    let checks = companyCheck;
    event.target.checked
      ? (checks = { ...checks, [companyId]: true })
      : delete checks[companyId];
    setCompanyCheck(checks);

    if (event.target.checked) {
      postRoleCompanies([{ role_id: role_id, company_id: companyId }]);
    } else {
      deleteRoleCompanies([companyId]);
    }
  };

  const handleCheckAll = (e) => {
    if (e.target.checked && companies.length > 0) {
      setCheckAll(true);
      let roleCompanies = [];
      companies.forEach((item) => {
        if (!companyCheck[item.id]) {
          roleCompanies.push({ role_id: role_id, company_id: item.id });
        }
      });
      if (roleCompanies.length > 0) postRoleCompanies(roleCompanies);
    } else {
      setCheckAll(false);
      let companyIds = [];
      companies.forEach((item) => {
        if (companyCheck[item.id]) {
          companyIds.push(item.id);
        }
      });
      if (companyIds.length > 0) deleteRoleCompanies(companyIds);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <span className={classes.subText}> {props.user.company_name} <span className={classes.colorScheme}> | </span><span className={classes.subText} onClick={()=>{props.history.push(process.env.PUBLIC_URL + "/settings/user/roles")}}>Users and Control</span></span>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={11}>
              <Typography variant="h4" color="initial" className={classes.text}>
                {role.role_name}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <Chip
                label="Back"
                variant="outlined"
                style={{
                  height: 35,
                  fontSize: "initial",
                  margin: 0,
                }}
                className={classes.chipOutlined}
                onClick={() => props.history.goBack()}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <div className={classes.divContent}>
                <Grid container spacing={1} alignItems="center">
                  {permissionCategories.map((permissionCategory) => {
                    return (
                      <Grid item xs={12}>
                        {permissionCategory.name}
                        {permissionCategory.show &&
                          <ArrowDropUpIcon
                            fontSize="default"
                            className={classes.floatRight}
                            onClick={()=>{
                              permissionCategory.show = false;
                              var newPermissionCategories = permissionCategories.map((pc)=>{
                                return {...pc};
                              });
                              setPermissionCategories(newPermissionCategories);
                            }}
                          />
                        }
                        {!permissionCategory.show &&
                          <ArrowDropDownIcon
                            fontSize="default"
                            className={classes.floatRight}
                            onClick={()=>{
                              permissionCategory.show = true;
                              var newPermissionCategories = permissionCategories.map((pc)=>{
                                return {...pc};
                              });
                              setPermissionCategories(newPermissionCategories);
                            }}
                          />
                        }
                        <hr/>
                        {permissionCategory.show && permissions.map((permission) => {
                          if(permission.permission_category === permissionCategory.id){
                            return (
                              <Grid container spacing={1} alignItems="center">
                                <Grid item className={classes.checkbox}>
                                  <Checkbox
                                    checked={permissionCheck[permission.id] ? true : false}
                                    onChange={handlePermissionCheckChange(permission.id)}
                                  />
                                </Grid>
                                <Grid item xs>
                                  {permission.permission_name}
                                </Grid>
                              </Grid>
                            );
                          }
                        })}
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8}>
              <div className={classes.divContent}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
										<span className={classes.subText}>COMPANIES ACCESS</span>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12} sm={7} md={6} lg={8}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item className={classes.checkbox}>
                            <Checkbox
                              checked={checkAll ? true : false}
                              disableRipple
                              onChange={handleCheckAll}
                            />
                          </Grid>
                          <Grid item xs={12}>
														<span className={classes.subText}>Allow All Companies</span>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        xs={7}
                        sm={5}
                        md={6}
                        lg={4}
                        className={classes.containerItem}
                      >
                        <div className={classes.search}>
                          <InputBase
                            className={classes.input}
                            placeholder="Search Companies"
                            inputProps={{ "aria-label": "search" }}
                            fullWidth
                            onChange={handleSearch}
                          />
                          <IconButton
                            className={classes.iconButton}
                            aria-label="search"
                            disabled
                          >
                            <SearchIcon />
                          </IconButton>
                        </div>
                      </Grid>
                      {companies.map((item) => {
                        return (
                          <Grid item xs={6} sm={6} md={6} lg={4} key={item.id}>
                            <Grid container spacing={1} alignItems="center">
                              <Grid item className={classes.checkbox}>
                                <Checkbox
                                  checked={companyCheck[item.id] ? true : false}
                                  onChange={handleCompanyCheckChange(item.id)}
                                />
                              </Grid>
                              <Grid item xs className={classes.textColor}>
                                {item.company_name}
                              </Grid>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default RolePermission;
