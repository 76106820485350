import React, { useEffect, useState } from "react";
import JsonSchemaForm from "./JsonSchemaForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "name",
    label: "MODEL NAME",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "order",
    label: "ORDER",
    minWidth: 200,
    sortable: true,
  },
];

const JsonSchemas = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `json_schemas`;
  const actions = {
    delete: true,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Customized Data Models"}
      url={url}
      actions={actions}
      objectForm={props.user.max_custom_tab > -1 && data.length >= props.user.max_custom_tab ? null : JsonSchemaForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"order"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Customized Data Models"}
      loadData={loadData}
      onDelete={()=>{window.location.reload();}}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Customized Data Models"}
      url={url}
      actions={actions}
      objectForm={props.user.max_custom_tab > -1 && data.length >= props.user.max_custom_tab ? null : JsonSchemaForm}
      tableColumns={tableColumns}
      searchFields={["name"]}
      orderBy={"order"}
      data={data}
      fetchData={fetchData}
      pageHeader={"Customized Data Models"}
      loadData={loadData}
      onDelete={()=>{window.location.reload();}}
    />
    }
    </>
  );
};

export default JsonSchemas;
