import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import ClassicEditor from "@ckeditor-linkpoint/ckeditor5-build-classic";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import commonConstraints from "../../Common/CommonConstants";
import MyComponent from "../../Common/RichTextEditor";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../Common/Loader";

const TemplateForm = (props) => {
  const classes = useStyles();
  const [template, setTemplate] = useState({});
  const [templateError, setTemplateError] = useState({});
  const [data, setData] = useState("");
  const [isDataValid, setIsDataValid] = useState(false);
  const { requiredError, blankError } = commonConstants.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `templates/${props.objectId}`,
        (template) => {
          let data = template.data;
          if (!data) data = "";
          delete template.data;
          setData(data);
          setTemplate(template);
          setLoadData(false);
          // setEditor(data);
        },
        () => {
          setLoadData(false);
        }
      );
    } else {
      setData("");
    }
  }, []);

  // const setEditor = (data) => {
  //   ClassicEditor.create(document.querySelector("#ckeditor"), {
  //     ckfinder: {
  //       uploadUrl:
  //         commonConstraints.serverName + "ckeditor_file",
  //     },
  //   })
  //     .then((editor) => {
  //       editor.setData(data);
  //       editor.editing.view.change((writer) => {
  //         writer.setStyle(
  //           "height",
  //           "calc(100vh/4)",
  //           editor.editing.view.document.getRoot()
  //         );
  //       });
  //       editor.model.document.on("change:data", () => {
  //         setData(editor.getData());
  //         setIsDataValid(false);
  //       });
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     });
  // };


  const onChange = (e) => {
    setTemplate({
      ...template,
      [e.target.name]: e.target.value == -1 ? null : e.target.value,
    });
    setTemplateError({
      ...templateError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!data.trim()) {
      setIsDataValid(true);
      return;
    }

    const templateData = { ...template, data: data };

    setIsSubmitted(true);
    if (props.edit && props.objectId) {
      http.Put(
        `templates/${props.objectId}`,
        templateData,
        (template) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setTemplateError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `templates`,
        templateData,
        (template) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setTemplateError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              {props.edit ? "Edit Template" : "Add New Template"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>TITLE *</p>
              <TextValidator
                id="title"
                fullWidth
                variant="outlined"
                margin="dense"
                name="title"
                value={template.title || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(templateError.title)}
                helperText={templateError.title && templateError.title[0]}
              />

              <p className={classes.label}>TEMPLATE</p>
              <div className={classes.editor}>
              <MyComponent
                  {...props}
                  default = {data} 
                  onChange={(data)=>{setData(data);}}/>
              </div>

              {isDataValid && <p className={classes.error}>{requiredError}</p>}

              <p className={classes.label}>TEMPLATE TYPE *</p>
              <TextValidator
                id="template_type_name"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="template_type_name"
                value={template.template_type_name || ""}
                onChange={onChange}
                validators={["required"]}
                errorMessages={[requiredError]}
              >
                <MenuItem key={1} value={"HR"}>
                  HR
                </MenuItem>
                <MenuItem key={2} value={"Document"}>
                  Document
                </MenuItem>
                <MenuItem key={3} value={"Meeting"}>
                  Meeting
                </MenuItem>
                <MenuItem key={4} value={"Incident Reporting"}>
                  Incident Reporting
                </MenuItem>
              </TextValidator>
            </div>
          )}
        </div>

        <div className={classes.divider}>
          <Divider />
        </div>

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default TemplateForm;
