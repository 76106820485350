import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import AlertDialog from "../../Common/AlertDialog";
import ScreenLoader from "../../Common/Loader";
import MenuItem from "@material-ui/core/MenuItem";
import DataMappings from "../DataMappings";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

export var site_status = 0;
export function setSS(prop){
  site_status = prop;
}


const SiteForm = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [site, setSite] = useState({ company_id: props.match.params.id });
  const [siteError, setSiteError] = useState({});
  const [loadData, setLoadData] = useState(false);
  const { requiredError, blankError } =
    commonConstants.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [employees, setEmployees] = useState();
  const [dataMappings, setDataMappings] = useState([]);
  const [tags, setTags] = useState([]);
  const [status,setStatus] = useState(1);

  useEffect(() => {
    if (props.objectId) {
      setLoadData(true);
      http.Get(
        `companies/${id}/sites/${props.objectId}`,
        (site) => {
          setSite(site);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );

      http.Get(
        `data_mappings?from_id=${props.objectId}`,
        (data) => {
          setDataMappings(data);
        },
        () => {}
      );
    }

    http.Get(
      `company_employees?company_id=${props.match.params.id}`,
      (employees) => {
        setEmployees(employees);
      },
      () => {}
    );

    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Tag Type`,
      (data) => {
        if (data.length === 0) setAlertOpen(true);
        setTags(data);
      },
      () => {}
    );
  }, []);

  const handleAgree = () => {
    setSite({
      ...site,
      is_head_office: true,
    });
    setAlertOpen(false);
  };
  const handleDisagree = () => {
    setAlertOpen(false);
  };

  const onChange = (e) => {
    if (e.target.name === "is_head_office") {
      if (!site.is_head_office) setAlertOpen(true);
      else
        setSite({
          ...site,
          is_head_office: !site.is_head_office,
        });
    } else
      setSite({
        ...site,
        [e.target.name]: e.target.value,
      });
    setSiteError({
      ...siteError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();

    setIsSubmitted(true);
    if (props.objectId) {
      http.Put(
        `companies/${id}/sites/${props.objectId}`,
        site,
        (site) => {
          http.Delete(
            "data_mappings",
            { from_id: props.objectId },
            () => {
              var promises = dataMappings.map((dataMapping) => {
                dataMapping.from_id = props.objectId;
                return http.Post(
                  "data_mappings",
                  dataMapping,
                  (data) => {
                    console.log(data);
                  },
                  (status, error) => {
                    console.log(status);
                    console.log(error);
                  }
                );
              });
              Promise.all(promises).then((results) => {
                props.handleObjectSubmit();
                setLoadData(false);
              });
            },
            () => {
              props.handleObjectSubmit();
              setLoadData(false);
            }
          );
        },
        (status, error) => {
          if (status === 400) setSiteError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `companies/${id}/sites`,
        site,
        (site) => {
          var promises = dataMappings.map((dataMapping) => {
            dataMapping.from_id = site.id;
            return http.Post(
              "data_mappings",
              dataMapping,
              (data) => {
                console.log(data);
              },
              (status, error) => {
                console.log(status);
                console.log(error);
              }
            );
          });
          Promise.all(promises).then((results) => {
            props.handleObjectSubmit();
            setLoadData(false);
          });
        },
        (status, error) => {
          if (status === 400) setSiteError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      <AlertDialog
        open={alertOpen}
        onAgree={handleAgree}
        onDisagree={handleDisagree}
        primaryMessage={"Are you sure you want to change head office?"}
        secondaryMessage={
          "Assigning a new head office will replace the previous head office."
        }
      />
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              classes={{ h6: classes.title }}
            >
              {props.objectId ? "Edit Site" : "Add New Site"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButtonColored}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>SITE NAME *</p>
              <TextValidator
                id="site_name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="site_name"
                value={site.site_name || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(siteError.site_name)}
                helperText={siteError.site_name && siteError.site_name[0]}
              />
              <p className={classes.label}>ADDRESS</p>
              <TextValidator
                id="address"
                fullWidth
                variant="outlined"
                margin="dense"
                name="address"
                value={site.address || ""}
                onChange={onChange}
                inputProps={{ maxLength: 40 }}
                error={Boolean(siteError.address)}
                helperText={siteError.address && siteError.address[0]}
              />
              <p className={classes.label}>CITY</p>
              <TextValidator
                id="city"
                fullWidth
                variant="outlined"
                margin="dense"
                name="city"
                value={site.city || ""}
                onChange={onChange}
                inputProps={{ maxLength: 40 }}
                error={Boolean(siteError.city)}
                helperText={siteError.city && siteError.city[0]}
              />
              <p className={classes.label}>PROVINCE</p>
              <TextValidator
                id="state"
                fullWidth
                variant="outlined"
                margin="dense"
                name="state"
                value={site.state || ""}
                onChange={onChange}
                inputProps={{ maxLength: 80 }}
                error={Boolean(siteError.state)}
                helperText={siteError.state && siteError.state[0]}
              />
              <p className={classes.label}>POSTAL</p>
              <TextValidator
                id="postal"
                fullWidth
                variant="outlined"
                margin="dense"
                name="postal"
                value={site.postal || ""}
                onChange={onChange}
                inputProps={{ maxLength: 10 }}
                error={Boolean(siteError.postal)}
                helperText={siteError.postal && siteError.postal[0]}
              />
              <p className={classes.label}>COUNTRY</p>
              <TextValidator
                id="country"
                fullWidth
                variant="outlined"
                margin="dense"
                name="country"
                value={site.country || ""}
                onChange={onChange}
                inputProps={{ maxLength: 40 }}
                error={Boolean(siteError.country)}
                helperText={siteError.country && siteError.country[0]}
              />
              {/* <p className={classes.label}>WEBSITE</p>
              <TextValidator
                id="website"
                fullWidth
                variant="outlined"
                margin="dense"
                name="website"
                value={site.website || ""}
                placeholder={websitePlaceholder}
                onChange={onChange}
                inputProps={{ maxLength: 250 }}
                error={Boolean(siteError.website)}
                helperText={siteError.website && siteError.website[0]}
              /> */}
              {/* <p className={classes.label}>PRIMARY CONTACT NUMBER</p> */}

              <p className={classes.label}>PRIMARY CONTACT</p>
              <TextValidator
                id="primary_contact"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="primary_contact"
                value={site.primary_contact || ""}
                inputProps={{ maxLength: 50 }}
                error={Boolean(siteError.primary_contact)}
                helperText={
                  siteError.primary_contact && siteError.primary_contact[0]
                }

              >
                <MenuItem
                      key={"empty"}
                      value={null}
                      
                      onClick={() => {
                        setSite({
                          ...site,
                          ["primary_contact"]: null,
                          ["primary_contact_number"]: "",
                        });
                      }}
                    >
                      {"NONE"}
                </MenuItem>
                {employees &&
                  employees.map((employee) => (
                    <MenuItem
                      key={employee.employee_name}
                      value={employee.employee_name}
                      onClick={() => {
                        setSite({
                          ...site,
                          ["primary_contact"]: employee.employee_name,
                          ["primary_contact_number"]: employee.phone_number,
                        });
                      }}
                    >
                      {employee.employee_name}
                    </MenuItem>
                  ))}
              </TextValidator>
              <p className={classes.label}>HOURS OF OPERATION</p>
              <TextValidator
                id="hours_of_operation"
                fullWidth
                variant="outlined"
                margin="dense"
                name="hours_of_operation"
                value={site.hours_of_operation || ""}
                onChange={onChange}
                inputProps={{ maxLength: 80 }}
                error={Boolean(siteError.hours_of_operation)}
                helperText={siteError.hours_of_operation && siteError.hours_of_operation[0]}
              />
              <p className={classes.label}>PRIMARY CONTACT NUMBER</p>
              <TextValidator
                id="primary_contact_number"
                fullWidth
                variant="outlined"
                margin="dense"
                name="primary_contact_number"
                value={site.primary_contact_number}
                onChange={onChange}
                inputProps={{ maxLength: 50 }}
                error={Boolean(siteError.primary_contact_number)}
                helperText={
                  siteError.primary_contact_number &&
                  siteError.primary_contact_number[0]
                }
              />
              <FormControlLabel
                control={
                  <Radio
                    name="is_head_office"
                    checked={site.is_head_office ? true : false}
                    onClick={onChange}
                    color="primary"
                  />
                }
                label="HEAD OFFICE"
                classes={{ label: classes.headOffice }}
                labelPlacement="end"
              />
              <p style={{ color: "red" }}>{siteError.is_head_office}</p>

              <p className={classes.label}>TAG</p>
              <TextValidator
                id="tag_type"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="tag_type"
                value={site.tag_type || ""}
                onChange={onChange}
                error={Boolean(siteError.tag_type)}
                helperText={siteError.tag_type && siteError.tag_type[0]}
              >
                <MenuItem
                      key={"empty"}
                      value={null}
                      
                    >
                      {"NONE"}
                </MenuItem>
                {tags && tags.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No tags found
                  </MenuItem>
                ) : (
                  
                  tags.map((option) => (
                
                    <MenuItem key={option.id} value={option.category_name}>
                      {option.category_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>

              <p className={classes.label}>Related Entries</p>
              <DataMappings
                {...props}
                fromId={props.objectId}
                fromModel={"Site"}
                dataMappings={dataMappings}
                setDataMappings={setDataMappings}
              />
            </div>
          )}
        </div>

        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.outlinedButton}
              onClick={() => props.setOpen(!props.open)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
              onClick={() => props.objectId ?site_status=0:site_status=1}
              //onClick={() => window.location.reload()}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default SiteForm;

