import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import http from "../../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  link: {
    color: theme.linkColor,
  },
  rightAlign: {
    textAlign: "right",
    fontSize: 14,
  },
  subTitle: {
    color: theme.subText,
  },
  title: {
    color: theme.text,
  },
}));

const HRSettings = (props) => {
  const classes = useStyles();
	const [message, setMessage] = useState(null);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [selectedTenantUsers, setSelectedTenantUsers] = useState();

  useEffect(() => {
    http.Get(
      "tenant_users",
      (usersData) => {
        var selectedUsers = [];
        var tenantUsers = usersData.map((item) => {
          if (item.is_vacation_cc) {
            selectedUsers.push(item);
          }
          return item;
        });
        setTenantUsers(tenantUsers);
        setSelectedTenantUsers(selectedUsers);
      },
      () => {}
    );
  }, []);

  const onClick = (e) => {
    tenantUsers.map((tenantUser) => {
      tenantUser.is_vacation_cc = false;
      if (
        selectedTenantUsers.find(
          (selectedTenantUser) => selectedTenantUser.id === tenantUser.id
        )
      ) {
        tenantUser.is_vacation_cc = true;
      }
      http.Patch(
        "tenant_users/" + tenantUser.id,
        { is_vacation_cc: tenantUser.is_vacation_cc },
        (data) => {
					setMessage("Vacation CC updated!")
        },
        (status, error) => {
          console.log(error);
        }
      );
    });
  };

  return (
    <div className={classes.root}>
      <Snackbar
        open={Boolean(message)}
        autoHideDuration={3000}
        onClose={() => {
          setMessage(null);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setMessage(null);
          }}
          severity="success"
          elevation={6}
          variant="filled"
        >
          {message}
        </Alert>
      </Snackbar>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <span className={classes.subTitle}>{props.user.company_name} </span>
          <span className={classes.link}> | </span>
          <span className={classes.subTitle}> HR Settings</span>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" noWrap classes={{ h4: classes.title }}>
            HR Settings
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <span className={classes.title}>Also Send Vacation Requests to*</span>
        </Grid>
        <Grid item xs={12}>
          {selectedTenantUsers && (
            <Autocomplete
              key={setSelectedTenantUsers.length}
              multiple
              filterSelectedOptions
              classes={{
                option: classes.option,
                popper: classes.popper,
              }}
              options={tenantUsers}
              defaultValue={selectedTenantUsers}
              getOptionLabel={(option) =>
                option.first_name + " " + option.last_name
              }
              renderOption={(option) => (
                <React.Fragment>
                  <span>
                    {option.profile_image_src ? (
                      <Avatar
                        className={classes.smallAvatar}
                        alt={option.first_name}
                        src={option.profile_image_src}
                      />
                    ) : (
                      <AccountCircleIcon fontSize="large" color="action" />
                    )}
                  </span>
                  <span>
                    <div style={{ fontWeight: 400 }}>
                      {option.first_name + " " + option.last_name}
                    </div>
                    <div style={{ fontWeight: 350, fontSize: 13 }}>
                      {option.email}
                    </div>
                  </span>
                </React.Fragment>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  fullWidth
                  margin="dense"
                  placeholder={selectedTenantUsers.length === 0 && "Emails"}
                />
              )}
              onChange={(event, value) => {
                setSelectedTenantUsers(value);
              }}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            className={classes.saveButton}
            variant="outlined"
            margin="dense"
            size="small"
            type="submit"
            onClick={onClick}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default HRSettings;
