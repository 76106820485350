import React from "react";
import ObjectForm from "../../Common/ObjectForm";

const ToTenantForm = (props) => {
  const [formFields, setFormFields] = React.useState();

  React.useEffect(() => {
    setFormFields([
      { 
        id: "to_tenant_email", 
        label: "TENANT ADMIN EMAIL", 
        type: "email", 
      },
    ]);
  }, []);


  return (
    <>
    {formFields && (
      <ObjectForm
        {...props}
        url={`invite_tenant`}
        formName={"Sharing"}
        formFields={formFields}
        object={{from_tenant:props.user.tenant}}
      />
    )}
    </>
  );
};

export default ToTenantForm;
