import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import IntegrationForm from "./IntegrationForm";
import FeaturedContent from "./FeaturedContent";
import InstalledContent from "./InstalledContent";
import useStyles from "./style";
import DeleteAlertDialog from "../../Common/AlertDialog";
import http from "../../Common/RestAPIHandler";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ScreenLoader from "../../Common/Loader";

const Integrations = (props) => {
  const [open, setOpen] = useState(false);
  const [featuredContent, setFeaturedContent] = useState([]);
  const [installedContent, setInstalledContent] = useState({});
  const [integration, setIntegration] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [uninstall, setUninstall] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [deleteAgree, setDeleteAgree] = useState(false);
  const [loadfeatured, setLoadfeatured] = useState(false);
  const [loadInstalled, setLoadInstalled] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();
  const [startWidth, setStartWidth] = useState();
  const [auth,setAuth] = useState(true);

  const onDragStart = (e) => {
    setStartX(e.screenX);
    setStartWidth(width);
  };

  const onDrag = (e) => {
    setEndX(e.screenX);
    setWidth(startWidth + startX - endX);
  };

  const handleObjectSubmit = () => {
    setIsEdit(false);
    setOpen(false);
    setUninstall(false);
  };

  useEffect(() => {
    http.Get(
      "featured_integration/",
      (data) => {
        setFeaturedContent(data);
        setLoadfeatured(true);
      },
      () => {}
    );

    http.Get(
      "integration/",
      (data) => {
        setInstalledContent(data);
        setLoadInstalled(true);
        if(!data.auth){setAuth(false)}
      },
      () => {}
    );
  }, [isEdit, open, uninstall]);

  const handleSetOpen = (open) => {
    setOpen(open);
  };

  const handleInstallClick = (selectedContent) => {
    setIntegration(selectedContent);
    setOpen(true);
  };

  const handleEditClick = (content) => {
    setIntegration(content);
    setIsEdit(true);
    setOpen(true);
  };

  const handleDeleteClick = (selectedContent) => {
    setIntegration(selectedContent);
    setAlertOpen(true);
  };

  const handleDeleteAgree = () => {
    setAlertOpen(false);
    http.Delete(
      `integration/`,
      integration,
      (data) => {
        window.location.reload(false);
      },
      (status, error) => {
        console.log(error);
      }
    );
  };

  const featuredContentList = featuredContent.map((content) => {
    content.onClick = handleInstallClick;
    const installed = Object.keys(installedContent).includes(content.key);
    if(((content.title === 'Azure SSO') || (content.title === 'OneLogin SSO'))&&(!installed)){
      if (content.title === 'Azure SSO'){content.installable = Object.keys(installedContent).includes('onelogin');}
      else{content.installable = Object.keys(installedContent).includes('azure');}
    }
    content.auth = auth
    return (
      <FeaturedContent
        content={content}
        installed={installed}
        key={content.key}
      />
    );
  });

  const installedContentList = featuredContent.map((content) => {
    const installed = Object.keys(installedContent).includes(content.key);
    if(installed){
      content.onClick = handleEditClick;
      content.onDelete = handleDeleteClick;
      content.info = installedContent[content.key];
      content.auth = auth
      return <InstalledContent content={content} key={content.key} />;
    }
  });


  const classes = useStyles();
  return (
    <div className={classes.root}>
      <DeleteAlertDialog
        open={alertOpen}
        onAgree={handleDeleteAgree}
        onDisagree={() => {
          setDeleteAgree(false);
          setAlertOpen(false);
        }}
        primaryMessage="Are you sure you want to uninstall?"
        secondaryMessage="Uninstalling will delete data parmanently."
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <br/>
              <span className={classes.title}>Company Settings</span>
              <span className={classes.link}>{" "}|{" "}</span>
              <span className={classes.title}>Extensions</span>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4" color="initial" classes={{ h4: classes.title }}>
                Extensions
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {!(loadfeatured && loadfeatured) ? (
            <ScreenLoader />
          ) : (
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography classes={{ root: classes.title }}>FEATURED</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={2} className={classes.grid}>
                      {loadfeatured && loadInstalled && featuredContentList}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography classes={{ root: classes.title }}>INSTALLED</Typography>
                  </Grid>

                  <Grid item xs={12}>
                    {loadfeatured && loadInstalled && (
                      <Grid container spacing={2} className={classes.grid}>
                        {(!Object.keys(installedContent).length && (
                          <Grid item>No extensions installed.</Grid>
                        )) ||
                          installedContentList}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          handleSetOpen(false);
        }}
        disableEnforceFocus
        classes={{
          paper: classes.drawerForm,
        }}
      >
        <div style={{ width: width }}>
          <table>
            <tbody>
              <tr>
                <td
                  draggable
                  style={{ cursor: "ew-resize" }}
                  onDragStart={onDragStart}
                  onDrag={onDrag}
                >
                  <DragIndicatorIcon />
                </td>
                <td width="100%">
                  <IntegrationForm
                    isEdit={isEdit}
                    uninstall={uninstall}
                    content={integration}
                    onClose={handleSetOpen}
                    handleObjectSubmit={handleObjectSubmit}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>
    </div>
  );
};

export default Integrations;
