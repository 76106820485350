import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import { Button, Input, InputBase, InputLabel } from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { connect } from "react-redux";
import Typography from "@material-ui/core/Typography";
import useStyles from "../../Common/Style/FormStyle";
import commonConstants from "../../Common/CommonConstants";
import AlertDialog from "../../Common/AlertDialog";
import MenuItem from "@material-ui/core/MenuItem";
import { hasPermission } from "../../Common/permission";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ScreenLoader from "../../Common/Loader";
import Chip from "@material-ui/core/Chip";

// import { getTheme } from "../../Common/Utility";
// import { Loader } from "@googlemaps/js-api-loader";

const CompanyForm = (props) => {
  // const theme = getTheme();
  const classes = useStyles();
  const [sites, setSites] = useState([]);
  const [site, setSite] = useState({});
  const [company, setCompany] = useState({
    tenant_id: props.user.tenant,
    user_id: props.user.id,
    tags:"",
  });
  const [payment, setPayment] = useState({});
  const [isPaymentNotEmpty, setIsPaymentNotEmpty] = useState(false);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [companyError, setCompanyError] = useState({});
  const [paymentError, setPaymentError] = useState({});
  const { requiredError, blankError, websitePlaceholder } =
    commonConstants.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [collapse, setCollapse] = useState(true);
  const [loadData, setLoadData] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  useEffect(() => {
    let paymentNotEmpty = Boolean(
      payment.vault_id ||
        payment.card_number ||
        payment.expiration ||
        payment.payment_charge_day ||
        payment.payment_type ||
        payment.payment_gateway_type ||
        payment.payment_frequency_type
    );
    setIsPaymentNotEmpty(paymentNotEmpty);
  }, [payment]);

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);

      http.Get(
        `companies/${props.objectId}`,
        (company) => {
          if (company.payment) {
            var paymentData = company.payment;

            var expiration = paymentData.expiration.toString();
            while (expiration.length < 4) expiration = "0" + expiration;
            paymentData.expiration = expiration;
            var chargeDay = paymentData.payment_charge_day.toString();
            while (chargeDay.length < 2) chargeDay = "0" + chargeDay;
            paymentData.payment_charge_day = chargeDay;
            setCollapse(false);
            setPayment(paymentData);
          }
          delete company.payment;
          delete company.logo;
          setCompany(company);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
      http.Get(
        `companies/${props.objectId}/sites`,
        (data) => {
          setSites(data);
          setSite(data[0] ? data[0] : { site_name: "Site list is empty." });

          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    }
    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Industry Type`,
      (data) => {
        if (data.length === 0) setAlertOpen(true);
        setCategories(data);
      },
      () => {}
    );
    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Tag Type`,
      (data) => {
        if (data.length === 0) setAlertOpen(true);
        setTags(data);
      },
      () => {}
    );
  }, []);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Call to google places API to get full address information (postal code)
  // async function handlePlacesID(placeId) {
  // function handlePlacesID(arg) {
  // 	const key = "AIzaSyCt5raqgoef0oKG1zVVoRTXLkHLi2GVVJ0";
  // 	// const url = `http://maps.googleapis.com/maps/api/place/details/json?key=${key}&place_id=${placeId}`;
  // 	let request = {
  //     placeId: placeId
  //   }
  //   let map = new google.maps.Map;
  //   let service = new google.maps.places.PlacesService(map)
  //   service.getDetails(request, (x) => {console.log(x)});
  // }

  const handleMenuClick = (id) => {
    var newSite = sites.filter((item) => item.id === id)[0];
    setSite(newSite);
    handleClose();
  };

  const onChangeCompany = (e) => {
    if (e.target.name === "logo")
      setCompany({
        ...company,
        logo: e.target.files[0],
        logo_src: null,
      });
    else if (e.target.name === "company_name")
      setCompany({
        ...company,
        company_name: e.target.value,
        name_abbreviation:
          e.target.value && e.target.value.match(/\b([A-Z])/g)
            ? e.target.value.match(/\b([A-Z])/g).join("")
            : "",
      });
    else
      setCompany({
        ...company,
        [e.target.name]: e.target.value,
      });
    setCompanyError({
      ...companyError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onPaymentChange = (e) => {
    setPayment({
      ...payment,
      [e.target.name]: e.target.value == -1 ? null : e.target.value,
    });
    setPaymentError({});
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setLoadData(true);
    if (isPaymentNotEmpty) {
      var paymentErrorData = {};
      var hasPaymentError = false;
      var checkPayment = Boolean(
        payment.expiration &&
          payment.payment_charge_day &&
          payment.payment_type &&
          payment.payment_gateway_type &&
          payment.payment_frequency_type
      );
      if (!checkPayment) {
        if (!payment.expiration) {
          hasPaymentError = true;
          paymentErrorData.expiration = [requiredError];
        }
        if (!payment.payment_charge_day) {
          hasPaymentError = true;
          paymentErrorData.payment_charge_day = [requiredError];
        }
        if (!payment.payment_type) {
          hasPaymentError = true;
          paymentErrorData.payment_type = [requiredError];
        }
        if (!payment.payment_gateway_type) {
          hasPaymentError = true;
          paymentErrorData.payment_gateway_type = [requiredError];
        }
        if (!payment.payment_frequency_type) {
          hasPaymentError = true;
          paymentErrorData.payment_frequency_type = [requiredError];
        }
        setCollapse(false);
        setPaymentError(paymentErrorData);
        setLoadData(false);
        return;
      }
      var expiration = parseInt(payment.expiration);
      if (
        payment.expiration.length != 4 ||
        expiration % 100 < 1 ||
        expiration % 100 > 12
      ) {
        hasPaymentError = true;
        paymentErrorData.expiration = [
          "Insert 4 digit number of format YYMM. The first 2 digit value must be within 00-99 and the last 2 digit value must be within 01-12.",
        ];
      }
      var chargeDay = parseInt(payment.payment_charge_day);
      if (
        payment.payment_charge_day.length != 2 ||
        chargeDay < 1 ||
        chargeDay > 31
      ) {
        hasPaymentError = true;
        paymentErrorData.payment_charge_day = [
          "Insert 2 digit number of format DD. The value must be within 01-31.",
        ];
      }
      if (hasPaymentError) {
        setCollapse(false);
        setPaymentError(paymentErrorData);
        return;
      }
    }

    const data = new FormData();
    for (var key in company) {
      data.append(key, company[key]);
    }

    if (payment) {
      Object.keys(payment).forEach(
        (key) =>
          (payment[key] === null || payment[key] === "") && delete payment[key]
      );
      if (isPaymentNotEmpty) {
        data.append("payment", JSON.stringify(payment));
      } else if (payment.id) {
        payment["delete_payment"] = true;
        data.append("payment", JSON.stringify(payment));
      }
    }

    setIsSubmitted(true);
    if (props.edit && props.objectId) {
      http.Put(
        `companies/${props.objectId}`,
        data,
        (company) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setCompanyError(error);
          if (status === 400) setPaymentError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `companies`,
        data,
        (company) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setCompanyError(error);
          if (status === 400) setPaymentError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
  };

  const handleAgree = () => {
    setAlertOpen(false);
    props.history.replace(process.env.PUBLIC_URL + "/settings/categories");
  };
  const handleDisagree = () => {
    setAlertOpen(false);
    props.closeOpen(false);
  };
  const handleArrowClick = () => {
    setCollapse(!collapse);
  };

  return (
    <div className={classes.list}>
      <AlertDialog
        open={alertOpen}
        primaryMessage="No Industry Type or/and Tag Type found. Would you like to add an Industry Type or/and a Tag Type?"
        secondaryMessage="You need to add an Industry Type and a Tag Type before adding a Company. Agreeing will redirect you to Categories page."
        onAgree={handleAgree}
        onDisagree={handleDisagree}
        onClose={handleDisagree}
      />
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={8}>
            <Typography
              variant="h6"
              component="h6"
              classes={{ h6: classes.title }}
            >
              {props.edit ? "Edit Company" : "Add New Company"}
            </Typography>
          </Grid>
          <Grid item xs={4} className={classes.gridItem}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>COMPANY NAME *</p>
              <TextValidator
                id="company_name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="company_name"
                value={company.company_name || ""}
                onChange={onChangeCompany}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(companyError.company_name)}
                inputProps={{ maxLength: 50 }}
                helperText={
                  companyError.company_name && companyError.company_name[0]
                }
              />
              <p className={classes.label}>NAME ABBREVIATION *</p>
              <TextValidator
                id="name_abbreviation"
                fullWidth
                variant="outlined"
                margin="dense"
                name="name_abbreviation"
                value={company.name_abbreviation || ""}
                onChange={onChangeCompany}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(companyError.name_abbreviation)}
                inputProps={{ maxLength: 50 }}
                helperText={
                  companyError.name_abbreviation &&
                  companyError.name_abbreviation[0]
                }
              />
              {props.edit ? (
                <>
                  <p className={classes.label}>HEAD OFFICE</p>
                  <TextValidator
                    id="site_id"
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    name="site_id"
                    className={classes.input}
                    value={company.site_id || ""}
                    onChange={onChangeCompany}
                    inputProps={{
                      maxLength: 50,
                    }}
                    errorMessages={[requiredError, blankError]}
                    error={Boolean(companyError.site_id)}
                    helperText={companyError.site_id && companyError.site_id[0]}
                  >
                    {sites && sites.length === 0 ? (
                      <MenuItem value={""} selected disabled>
                        No sites found
                      </MenuItem>
                    ) : (
                      sites.map((siteItem) => (
                        <MenuItem
                          onClick={() => handleMenuClick(siteItem.id)}
                          key={siteItem.id}
                          value={siteItem.id}
                        >
                          {siteItem.site_name}
                        </MenuItem>
                      ))
                    )}
                  </TextValidator>
                  {/* <p className={classes.label}>HEAD OFFICE CONTACT FIRST NAME</p>
                  <TextValidator
                    id="first_name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="first_name"
                    value={company.first_name || ""}
                    onChange={onChangeCompany}
                    inputProps={{ maxLength: 30 }}
                    error={Boolean(companyError.first_name)}
                    helperText={
                      companyError.first_name && companyError.first_name[0]
                    }
                  />
                  <p className={classes.label}>HEAD OFFICE CONTACT LAST NAME</p>
                  <TextValidator
                    id="last_name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="last_name"
                    value={company.last_name || ""}
                    onChange={onChangeCompany}
                    inputProps={{ maxLength: 30 }}
                    error={Boolean(companyError.last_name)}
                    helperText={
                      companyError.last_name && companyError.last_name[0]
                    }
                  /> */}
                </>
              ) : (
                <>
                  {/* <p className={classes.label}>ADDRESS</p> */}
                  {/* <PlacesAutocomplete
										value={company.address || ""}
										onChange={(address) => {
											setCompany({
												...company,
												["address"]: address,
											});
										}}
										onSelect={(address, placeId) => {
											console.log(address);
											var addresses = address.split(", ");
											if (addresses.length === 4) {
												setCompany({
													...company,
													["address"]: addresses[0],
													["site_name"]: addresses[1] + " " + addresses[2],
													["city"]: addresses[1],
													["state"]: addresses[2],
													["country"]: addresses[3],
												});
											}
											else if (addresses.length === 3){
												setCompany({
													...company,
													["address"]: addresses[0],
													["site_name"]: addresses[1],
													["city"]: addresses[1],
													["country"]: addresses[2],
												});

											}
										}}
									>
										{({
											getInputProps,
											suggestions,
											getSuggestionItemProps,
											loading,
										}) => (
											<div>
												<TextValidator
													id="address"
													fullWidth
													variant="outlined"
													margin="dense"
													name="address"
													value={company.address || ""}
													onChange={onChangeCompany}
													inputProps={{ maxLength: 50 }}
													validators={["required", "trim"]}
													errorMessages={[requiredError, blankError]}
													error={Boolean(companyError.address)}
													helperText={
														companyError.address && companyError.address[0]
													}
													{...getInputProps({
														placeholder: "Search Places ...",
														className: "location-search-input",
													})}
												/>
												<div className="autocomplete-dropdown-container">
													{loading && <div>Loading...</div>}
													{suggestions.map((suggestion) => {
														const className = suggestion.active
															? "suggestion-item--active"
															: "suggestion-item";
														// inline style for demonstration purpose
														const style = suggestion.active
															? {
																	backgroundColor: "#fafafa",
																	cursor: "pointer",
															  }
															: {
																	backgroundColor: "#ffffff",
																	cursor: "pointer",
															  };
														return (
															<div
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}
															>
																<span>{suggestion.description}</span>
															</div>
														);
													})}
												</div>
											</div>
										)}
									</PlacesAutocomplete> */}
                  <p className={classes.label}>ADDRESS *</p>
                  <TextValidator
                    id="address"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="address"
                    value={company.address || ""}
                    onChange={onChangeCompany}
                    inputProps={{ maxLength: 50 }}
                    validators={["required", "trim"]}
                    errorMessages={[requiredError, blankError]}
                    error={Boolean(companyError.address)}
                    helperText={companyError.address && companyError.address[0]}
                  />
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="space-between"
                  >
                    <Grid item sm md lg style={{ textAlign: "start" }}>
                      <p className={classes.label}>CITY *</p>
                      <TextValidator
                        fullWidth
                        id="city"
                        variant="outlined"
                        margin="dense"
                        name="city"
                        value={company.city || ""}
                        onChange={onChangeCompany}
                        inputProps={{ maxLength: 50 }}
                        validators={["required", "trim"]}
                        errorMessages={[requiredError, blankError]}
                        error={Boolean(companyError.main_site)}
                        // helperText={companyError.address && companyError.address[0]}
                      />
                    </Grid>
                    <Grid item sm md lg style={{ textAlign: "center" }}>
                      <p className={classes.label}>PROVINCE *</p>
                      <TextValidator
                        id="state"
                        variant="outlined"
                        margin="dense"
                        name="state"
                        value={company.state || ""}
                        onChange={onChangeCompany}
                        inputProps={{ maxLength: 50 }}
                        validators={["required", "trim"]}
                        errorMessages={[requiredError, blankError]}
                        error={Boolean(companyError.main_site)}
                        // helperText={companyError.address && companyError.address[0]}
                      />
                    </Grid>
                    <Grid item sm md lg style={{ textAlign: "end" }}>
                      <p className={classes.label}>POSTAL CODE *</p>
                      <TextValidator
                        fullWidth
                        id="postal_code"
                        variant="outlined"
                        margin="dense"
                        name="postal_code"
                        value={company.postal_code || ""}
                        onChange={onChangeCompany}
                        inputProps={{ maxLength: 50 }}
                        validators={["required", "trim"]}
                        errorMessages={[requiredError, blankError]}
                        error={Boolean(companyError.postal_code)}
                        // helperText={companyError.address && companyError.address[0]}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {/* //-----------------------------------------------------------------------LinkPoint-----------------AS-----------------------------|| */}
              <p className={classes.label}>MAIN PHONE NUMBER *</p>
              <TextValidator
                id="main_phone_number"
                fullWidth
                variant="outlined"
                margin="dense"
                name="main_phone_number"
                value={company.main_phone_number || ""}
                onChange={onChangeCompany}
                inputProps={{ maxLength: 30 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(companyError.main_phone_number)}
                helperText={
                  companyError.main_phone_number &&
                  companyError.main_phone_number[0]
                }
              />
              <p className={classes.label}>FAX NUMBER</p>
              <TextValidator
                id="fax_number"
                fullWidth
                variant="outlined"
                margin="dense"
                name="fax_number"
                value={company.fax_number || ""}
                onChange={onChangeCompany}
                inputProps={{ maxLength: 30 }}
                error={Boolean(companyError.fax_number)}
                helperText={
                  companyError.fax_number && companyError.fax_number[0]
                }
              />
              <p className={classes.label}>WEBSITE</p>
              <TextValidator
                id="website"
                fullWidth
                variant="outlined"
                margin="dense"
                name="website"
                value={company.website || ""}
                onChange={onChangeCompany}
                placeholder={websitePlaceholder}
                inputProps={{ maxLength: 255, color: "red" }}
                error={Boolean(companyError.website)}
                helperText={companyError.website && companyError.website[0]}
              />
              <p className={classes.label}>ACTIVE DIRECTORY DOMAIN</p>
              <TextValidator
                id="active_directory_domain"
                fullWidth
                variant="outlined"
                margin="dense"
                name="active_directory_domain"
                value={company.active_directory_domain || ""}
                onChange={onChangeCompany}
                inputProps={{ maxLength: 100 }}
              />
              <p className={classes.label}>NOTES</p>
              <TextValidator
                id="notes"
                fullWidth
                variant="outlined"
                margin="dense"
                name="notes"
                multiline
                rows={3}
                value={company.notes || ""}
                onChange={onChangeCompany}
              />
              {!props.edit && (
                <>
                  <p className={classes.label}>HOURS OF OPERATION</p>
                  <TextValidator
                    id="hour"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="hour"
                    value={company.hour || ""}
                    onChange={onChangeCompany}
                    inputProps={{ maxLength: 50 }}
                    validators={["required", "trim"]}
                    errorMessages={[requiredError, blankError]}
                    error={Boolean(companyError.hour)}
                    helperText={companyError.hour && companyError.hour[0]}
                  />
                </>)}

              <p className={classes.label}>TAG(S)</p>
              <TextValidator
                id="tags"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="tags"
                value={""}
                onChange={(e) => {
                  e.preventDefault();
                  setCompany({
                    ...company,
                    ["tags"]:
                      (company["tags"] ? `${company["tags"]}, ` : "") +
                      e.target.value,
                  });
                  e.target.value = "";
                }}
                error={Boolean(companyError.tags)}
                helperText={companyError.tags && companyError.tags[0]}
              >
                {tags && tags.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No tags found
                  </MenuItem>
                ) : (
                  tags.map((option) => (
                    !(company["tags"].includes(option.category_name))&&(
                    <MenuItem key={option.id} value={option.category_name}>
                      {option.category_name}
                    </MenuItem>)
                  ))
                )}
              </TextValidator>

              {company["tags"] &&
                company["tags"].split(",").map((tag) => {
                  if (tag) {
                    return (
                      <Chip
                        label={
                          <span>
                            {tag}{" "}
                            <span
                              style={{ color: "black", marginLeft: "10px" }}
                            >
                              X
                            </span>
                          </span>
                        }
                        clickable
                        className={classes.chip}
                        onClick={() => {
                          company["tags"] = company["tags"]
                            .replaceAll(tag, "")
                            .replaceAll("," + tag, "")
                            .replaceAll(tag + ",", "")
                            .replaceAll("," + tag + ",", ",")
                            .replaceAll(",,", ",");
                          setCompany({
                            ...company,
                          });
                        }}
                      />
                    );
                  }
                })}

              {/* <p className={classes.label}>ADDITIONAL TAGS</p>
							<TextValidator
								id="tags_input"
								fullWidth
								variant="outlined"
								margin="dense"
								name="tags_input"
								onKeyPress={(e) => {
									if ((e.key === "," || e.key === "Enter") && e.target.value) {
										e.preventDefault();
										setCompany({
											...company,
											["tags"]: (
												(company["tags"] ? company["tags"] : "") +
												"," +
												e.target.value +
												","
											).replaceAll(",,", ","),
										});
										e.target.value = "";
									}
								}}
								inputProps={{ maxLength: 50 }}
							/>
							{company["tags"] &&
								company["tags"].split(",").map((tag) => {
									if (tag) {
										return (
											<Chip
												label={
													<span>
														{tag}{" "}
														<span
															style={{ color: "black", marginLeft: "10px" }}
														>
															X
														</span>
													</span>
												}
												clickable
												className={classes.chip}
												onClick={() => {
													company["tags"] = company["tags"]
														.replaceAll("," + tag + ",", ",")
														.replaceAll(",,", ",");
													setCompany({
														...company,
													});
												}}
											/>
										);
									}
								})} */}

              <p className={classes.label}>INDUSTRY TYPE</p>
              <TextValidator
                id="industry_type"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="industry_type"
                value={company.industry_type || ""}
                onChange={onChangeCompany}
                error={Boolean(companyError.industry_type)}
                helperText={
                  companyError.industry_type && companyError.industry_type[0]
                }
              >
                {categories && categories.length > 0 && (
                  <MenuItem key={-1} value={""}>
                    NONE
                  </MenuItem>
                )}
                {categories && categories.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No industry types found
                  </MenuItem>
                ) : (
                  categories.map((option) => (
                    <MenuItem key={option.id} value={option.category_name}>
                      {option.category_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>

              <p className={classes.label}>STATUS</p>
              <TextValidator
                id="status"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="status"
                value={
                  company.status !== undefined ? Boolean(company.status) : true
                }
                onChange={onChangeCompany}
                validators={["required"]}
                errorMessages={[requiredError]}
              >
                <MenuItem key={1} value={true}>
                  Active
                </MenuItem>
                <MenuItem key={2} value={false}>
                  Disabled
                </MenuItem>
              </TextValidator>
              <p className={classes.label}>LOGO</p>
              {company.logo_src && (
                <p>
                  <img
                    style={{ "max-width": "200px" }} // JSX syntax not working for some reason
                    alt={"logo_src"}
                    src={company.logo_src}
                  />
                  <IconButton
                    style={{ backgroundColor: "transparent" }}
                    onClick={(e) => {
                      http.Delete(
                        `companies/${props.objectId}/logo`,
                        {},
                        (res) => {
                          setCompany({
                            ...company,
                            logo: null,
                            logo_src: null,
                          });
                        },
                        (status, error) => {
                          console.log(error);
                        }
                      );
                    }}
                  >
                    <HighlightOffIcon className={classes.iconButtonColored} />
                  </IconButton>
                </p>
              )}
              <Input
								id="logo"
                type="file"
                inputProps={{
                  style: { visibility: "hidden" },
                  accept: "image/png, image/jpeg, image/jpg",
                }}
                name="logo"
                onChange={onChangeCompany}
                className={classes.fileUpload}
                startAdornment={
                  <InputLabel for="logo" className={classes.w100}>
                    {(company.logo && company.logo.name) ? company.logo.name : "Click to upload..."}
                  </InputLabel>
                }
                aria-describedby="my-upload"
              />
              {hasPermission(props, ["Accounting"]) && (
                <div style={{ marginTop: 20 }}>
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        style={{ fontWeight: 500 }}
                        classes={{ root: classes.title }}
                      >
                        Company Payment (Optional)
                      </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.gridItem}>
                      {(collapse && (
                        <ArrowDropDownIcon
                          className={classes.collapseButton}
                          onClick={handleArrowClick}
                        />
                      )) || (
                        <ArrowDropUpIcon
                          className={classes.collapseButton}
                          onClick={handleArrowClick}
                        />
                      )}
                    </Grid>
                  </Grid>

                  {!collapse && (
                    <div>
                      <p className={classes.label}>VAULT ID</p>
                      <TextValidator
                        id="vault_id"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="vault_id"
                        value={payment.vault_id || ""}
                        onChange={onPaymentChange}
                        inputProps={{ maxLength: 25 }}
                        validators={["maxStringLength:25"]}
                        errorMessages={[
                          "This field can not exceed 25 characters.",
                        ]}
                        error={Boolean(paymentError.vault_id)}
                        helperText={
                          paymentError.vault_id && paymentError.vault_id[0]
                        }
                      />
                      <p className={classes.label}>CARD NUMBER</p>
                      <TextValidator
                        id="card_number"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="card_number"
                        value={payment.card_number || ""}
                        onChange={onPaymentChange}
                        inputProps={{ maxLength: 19, minLength: 12 }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        error={Boolean(paymentError.card_number)}
                        helperText={
                          paymentError.card_number &&
                          paymentError.card_number[0]
                        }
                      />
                      <p className={classes.label}>EXPIRATION (YYMM)</p>
                      <TextValidator
                        id="expiration"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="expiration"
                        value={payment.expiration || ""}
                        onChange={onPaymentChange}
                        inputProps={{ maxLength: 4, minLength: 4 }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        error={Boolean(paymentError.expiration)}
                        helperText={
                          paymentError.expiration && paymentError.expiration[0]
                        }
                      />
                      <p className={classes.label}>PAYMENT CHARGE DAY (DD)</p>
                      <TextValidator
                        id="payment_charge_day"
                        fullWidth
                        variant="outlined"
                        margin="dense"
                        name="payment_charge_day"
                        value={payment.payment_charge_day || ""}
                        onChange={onPaymentChange}
                        inputProps={{ maxLength: 2, minLength: 2 }}
                        onInput={(e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        }}
                        error={Boolean(paymentError.payment_charge_day)}
                        helperText={
                          paymentError.payment_charge_day &&
                          paymentError.payment_charge_day[0]
                        }
                      />
                      <p className={classes.label}>PAYMENT TYPE</p>
                      <TextValidator
                        id="payment_type"
                        fullWidth
                        select
                        variant="outlined"
                        margin="dense"
                        name="payment_type"
                        value={payment.payment_type || ""}
                        onChange={onPaymentChange}
                        error={Boolean(paymentError.payment_type)}
                        helperText={
                          paymentError.payment_type &&
                          paymentError.payment_type[0]
                        }
                      >
                        <MenuItem value={""}>NONE</MenuItem>
                        <MenuItem value={"Recurring"}>Recurring</MenuItem>
                        <MenuItem value={"Manual"}>Manual</MenuItem>
                      </TextValidator>
                      <p className={classes.label}>PAYMENT GATEWAY TYPE</p>
                      <TextValidator
                        id="payment_gateway_type"
                        fullWidth
                        select
                        variant="outlined"
                        margin="dense"
                        name="payment_gateway_type"
                        value={payment.payment_gateway_type || ""}
                        onChange={onPaymentChange}
                        error={Boolean(paymentError.payment_gateway_type)}
                        helperText={
                          paymentError.payment_gateway_type &&
                          paymentError.payment_gateway_type[0]
                        }
                      >
                        <MenuItem value={""}>NONE</MenuItem>
                        <MenuItem value={"Moneris"}>Moneris</MenuItem>
                        <MenuItem value={"MSI"}>MSI</MenuItem>
                      </TextValidator>
                      <p className={classes.label}>PAYMENT FREQUENCY TYPE</p>
                      <TextValidator
                        id="payment_frequency_type"
                        fullWidth
                        select
                        variant="outlined"
                        margin="dense"
                        name="payment_frequency_type"
                        value={payment.payment_frequency_type || ""}
                        onChange={onPaymentChange}
                        error={Boolean(paymentError.payment_frequency_type)}
                        helperText={
                          paymentError.payment_frequency_type &&
                          paymentError.payment_frequency_type[0]
                        }
                      >
                        <MenuItem value={""}>NONE</MenuItem>
                        <MenuItem value={"Monthly"}>Monthly</MenuItem>
                        <MenuItem value={"Annually"}>Annually</MenuItem>
                      </TextValidator>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {/* <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={menuOpen}
          onClose={handleClose}
          classes={{
            paper: classes.siteList,
          }}
        >
          {(sites.length > 0 &&
            sites.map((siteItem) => {
              return (
                <Tooltip title={siteItem.site_name}>
                  <MenuItem
                    onClick={() => handleMenuClick(siteItem.id)}
                    key={siteItem.id}
                    className={
                      siteItem.id === site.id
                        ? classes.menuSelected
                        : classes.menuItem
                    }
                    style={{ fontSize: "14px" }}
                  >
                    {siteItem.site_name}
                  </MenuItem>
                </Tooltip>
              );
            })) || (
            <MenuItem className={classes.menuItem} onClick={handleClose}>
              Site list is empty
            </MenuItem>
          )}
        </Menu> */}
        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps, null)(CompanyForm);
