import React, { useEffect, useState } from "react";
import UserForm from "./UserForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { formatDateTime } from "../../Common/Utility";
import IconButton from "@material-ui/core/IconButton";
import PhoneIcon from "@material-ui/icons/Phone";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import { makeStyles } from "@material-ui/core/styles";
import PageviewIcon from '@material-ui/icons/Pageview';
import BlockIcon from '@material-ui/icons/Block';
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    height: 30,
    width: 30,
    background: theme.colorScheme,
    color: "white",
    margin: 1,
  }
}));


const TenantUsers = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);

  const url = `tenant_users`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };
  
  const tableColumns = [
    {
      id: "profile_image_src",
      label: "",
      minWidth: 50,
      sortable: false,
    },
    {
      id: "name",
      label: "NAME",
      minWidth: 200,
      sortable: true,
    },
    {
      id: "roles",
      label: "ROLES",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "email",
      label: "EMAIL",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "last_login",
      label: "LAST LOGIN",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "status",
      label: "",
      minWidth: 20,
      sortable: true,
    },
  ];

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var tenantUserList = [];
        data.map((tenantUser) => {
          if(props.user.tenant === tenantUser.tenant){
            tenantUser["status"] = 
            <>
              {tenantUser["is_tenant_admin"] ? (
                <Tooltip title='Tenant Admin'><SupervisorAccountIcon /></Tooltip>
              ) : (
                ""
              )}
              {tenantUser["is_readonly"] = tenantUser["is_readonly"] ? (
                <Tooltip title='Readonly'><PageviewIcon/></Tooltip>
              ) : (
                ""
              )}
              {tenantUser["has_access"] = tenantUser["has_access"] ? (
                ""
              ) : (
                <Tooltip title='No Access'><BlockIcon/></Tooltip>
              )}
            </>;
            tenantUser["name"] =
              tenantUser["first_name"] + " " + tenantUser["last_name"];
            tenantUser["profile_image_src"] = tenantUser["profile_image_src"] ? (
              <img
                width="50px"
                alt={tenantUser.id}
                src={tenantUser["profile_image_src"]}
              />
            ) : (
              ""
            );
            tenantUser["last_login"] = formatDateTime(tenantUser["last_login"]);
            tenantUser["mobile_view"] = tenantUser["roles"];
            tenantUser["mobile_view_button"] = (
              <>
              {tenantUser.mobile &&
                <IconButton
                  disableTouchRipple
                  className={classes.button}
                  edge="start"
                  href={`tel://${tenantUser.mobile}`}
                >
                  <PhoneIcon fontSize="small" />
                </IconButton>
              }
              {tenantUser.email &&            
                <IconButton
                  className={classes.button}
                  href={`mailto:${tenantUser.email}`}
                >
                  <MailOutlineIcon fontSize="small" />
                </IconButton>
              }
              </>
            );
            tenantUserList.push(tenantUser);
          }
        });
        setData(tenantUserList);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    {!props.mobile &&      
      <ObjectList
        {...props}
        moduleName={"Tenant Users"}
        url={url}
        actions={actions}
        permissions={{
          create: ["Manage Users"],
          edit: ["Manage Users"],
          delete: ["Manage Users"],
        }}
        noAdd = {(data.length>=(props.user.user_addons+props.user.readonly_addons)) ? "allow":null}
        objectForm={UserForm}
        tableColumns={tableColumns}
        searchFields={["name"]}
        orderBy={"name"}
        data={data}
        fetchData={fetchData}
        pageHeader={(props.user.company_name || "") + " | Tenant Users"}
        loadData={loadData}
      />
    }
    {props.mobile &&      
      <ObjectListMobile
        {...props}
        moduleName={"Tenant Users"}
        url={url}
        actions={actions}
        permissions={{
          create: ["Manage Users"],
          edit: ["Manage Users"],
          delete: ["Manage Users"],
        }}
        noAdd = {(data.length>=(props.user.user_addons+props.user.readonly_addons)) ? "allow":null}
        objectForm={(data.length>=(props.user.user_addons+props.user.readonly_addons)) ? null : UserForm}
        tableColumns={tableColumns}
        searchFields={["name"]}
        orderBy={"name"}
        data={data}
        fetchData={fetchData}
        pageHeader={(props.user.company_name || "") + " | Tenant Users"}
        loadData={loadData}
      />
    }
    </>
  );
};

export default TenantUsers;
