import { makeStyles } from "@material-ui/core/styles";
import VacationFilter from "./Vacations/VacationFilters";
import VacationOverview from "./Vacations/VacationOverview";
import VacationDetails from "./Vacations/VacationDetails";
import React, { useState } from "react";
import {
  Dialog,
  Divider,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  containerItem: {
    textAlign: "right",
  },
  chipOutlined: {
    borderRadius: 8,
    borderColor: theme.colorScheme,
    marginRight: "2px",
    color: theme.colorScheme,
    "&:hover": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
    "&:focus": {
      backgroundColor: theme.colorScheme,
      color: "white",
    },
  },
  search: {
    display: "flex",
    minWidth: 100,
    height: 32,
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  input: {
    marginLeft: theme.spacing(2),
  },
  iconButton: {
    padding: 5,
  },
  divContent: {
    border: "1px solid #cecece",
    padding: 20,
    height: "calc(100vh/4.8)",
    overflow: "auto",
  },
  textColor: {
    color: theme.colorScheme,
  },
  checkbox: {
    width: "max-content",
  },
	title: {
    color: theme.text,
  },
	subTitle: {
    color: theme.subText,
  },
}));

const Reports = (props) => {
  const classes = useStyles();
  const [reportSelection, setReportSelection] = useState(null);
  const [results, setResults] = useState(null);
  const [details, setDetails] = useState(null);

  const RADIO_VALUES = { vacation: "Vacation" };

  const handleReportSelection = (e) => {
    setReportSelection(e.target.value);
    setResults(null);
  };

  return (
    <div className={classes.root}>
      {details && (
        <Dialog
          fullWidth
          maxWidth="lg"
          open={Boolean(details)}
          onClose={() => setDetails(null)}
        >
          <div style={{ padding: 10 }}>
            {reportSelection === RADIO_VALUES.vacation && (
              <VacationDetails
                {...props}
                details={details}
                handleClose={() => {
                  setDetails(null);
                  if (props.handleClose) {
                    props.handleClose();
                  }
                }}
                onClose={() => setDetails(null)}
              />
            )}
          </div>
        </Dialog>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
              <Typography
                variant="h4"
                color="initial"
                style={{ paddingTop: "15px" }}
								className={classes.title}
              >
                Reports
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={3} md={3} lg={3}>
              <div className={classes.divContent}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <div style={{ fontWeight: 520 }}>
                      <span className={classes.title}>REPORT TYPE</span>
                    </div>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroup
                      aria-label="report_type"
                      value={reportSelection}
                      onChange={handleReportSelection}
                    >
                      <FormControlLabel
                        value={RADIO_VALUES.vacation}
                        control={<Radio />}
                        label={RADIO_VALUES.vacation}
												className={classes.subTitle}
                      />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={9} lg={9}>
              <div className={classes.divContent}>
                {reportSelection === RADIO_VALUES.vacation && (
                  <VacationFilter {...props} setResults={setResults} />
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <div
                className={classes.divContent}
                style={{ height: "calc(100vh/2.4)" }}
              >
                <Grid container spacing={1} alignItems="center">
                  {reportSelection === RADIO_VALUES.vacation && results && (
                    <Grid item xs={12}>
                      <VacationOverview
                        {...props}
                        results={results}
                        setDetails={setDetails}
                      />
                    </Grid>
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Reports;
