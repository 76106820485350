import React from "react";
import { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import CommonConstants from "../../Common/CommonConstants";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import CancelIcon from "@material-ui/icons/Cancel";
import ScreenLoader from "../../Common/Loader";

const IntegrationForm = (props) => {
	const content = props.content;
	const classes = useStyles();
	const errorText = CommonConstants.error;
	const [integrationError, setIntegrationError] = useState("");
	const [gate,setGate] = useState(true);
	const [integration, setIntegration] = useState(props.content.info || {});
	const [isSubmitted, setIsSubmitted] = useState(false);

	useEffect(() => {
		if (props.uninstall) {
			http.Delete(
				`integration/`,
				content.key,
				(data) => {
					props.handleObjectSubmit();
					window.location.reload(false);
				},
				(status, error) => {
					console.log(status, error);
				}
			);
		}
	}, []);

	useEffect(() => {
		if (!props.isEdit) {
			setIntegration({
			...integration,
			["set_default"]: false,
		});
		}
	}, []);

	const onChange = (e) => {
		setIntegration({
			...integration,
			[e.target.name]: e.target.value,
		});
		setIntegrationError({
			...integrationError,
			[e.target.name]: false,
		});
		setIsSubmitted(false);
	};

	const onSubmit = (e) => {
		e.preventDefault();
		setIsSubmitted(true);

		const data = {
			key: content.key,
			data: {
				...integration,
			},
		};

		if (props.isEdit) {
			http.Put(
				`integration/`,
				data,
				(data) => {
					props.handleObjectSubmit();
					setIsSubmitted(false);
				},
				(status, error) => {
					if (status === 400) setIntegrationError(error);
					setIsSubmitted(false);
				}
			);
		} else {
			http.Post(
				`integration/`,
				data,
				(data) => {
					http.Get(
					  "sync_and_merge/",
					  () => {},
					  () => {}
					);
					props.handleObjectSubmit();
					setIsSubmitted(false);
					window.location.reload(false);
				},
				(status, error) => {
					if (status === 400) setIntegrationError(error);
					setIsSubmitted(false);
				}
			);
		}
	};


	function showElement(element) {
		
		if (element.type === "text") {
			return (
				<div>
					<p className={classes.label}>{element.title}</p>
					<Grid item xs={12}>
						<TextValidator
							id={element.key}
							fullWidth
							variant="outlined"
							margin="dense"
							name={element.key}
							value={integration[element.key] || ""}
							onChange={onChange}
							inputProps={{ maxLength: 255 }}
							validators={["required", "trim"]}
							errorMessages={[errorText.requiredError, errorText.blankError]}
						/>
					</Grid>
				</div>
			);
		} else if (element.type === "select") {
			return (
				<div>
					<p className={classes.label}>{element.title}</p>
					<Grid item xs={12}>
						<TextValidator
							id={element.key}
							select
							fullWidth
							variant="outlined"
							margin="dense"
							name={element.key}
							value={integration[element.key]}
							onChange={onChange}
							validators={["required"]}
							errorMessages={[errorText.requiredError]}
						>
							{element.options && element.options.length === 0 ? (
								<MenuItem value={""} selected disabled>
									No options found
								</MenuItem>
							) : (
								element.options.map((option) => (
									<MenuItem key={option.key} value={Boolean(option.value)}>
										{option.name}
									</MenuItem>
								))
							)}
						</TextValidator>
					</Grid>
				</div>
			);
		}
	}

	return (
		<div className={classes.list}>
			<div className={classes.header}>
				<Grid container spacing={2} className={classes.integrationHeader}>
					<Grid item xs={10} className={classes.integrationHeader}>
						<img
							className={classes.integrationLogo}
							src={content.wide_logo}
							alt="Image not found"
						/>
					</Grid>
					<Grid item xs={2} className={classes.gridItem}>
						<IconButton
							className={classes.iconButton}
							onClick={() => props.onClose(false)}
						>
							<CancelIcon />
						</IconButton>
					</Grid>
				</Grid>
			</div>
			<Divider />

			<ValidatorForm onSubmit={onSubmit}>
				{!isSubmitted ? (
					<div className={classes.content}>
						{content.json_data.fields.map((element) => {
							if (content.auth)
								{return <div key={element.key}>{showElement(element)}</div>;}
							else if(!content.auth && (element.key!='set_default')){return <div key={element.key}>{showElement(element)}</div>;}
						})}
					</div>
				) : (
					<ScreenLoader />
				)}

				<div className={classes.divider}>
					<Divider />
				</div>

				<div className={classes.footer}>
					<div className={classes.outlinedButtonContainer}>
						<Button
							variant="outlined"
							margin="dense"
							size="small"
							color="primary"
							className={classes.outlinedButton}
							onClick={() => {
								props.onClose(false);
							}}
						>
							Cancel
						</Button>
						<Button
							className={classes.filledButton}
							variant="outlined"
							margin="dense"
							size="small"
							type="submit"
							disabled={isSubmitted}
						>
							Save
						</Button>
					</div>
				</div>
			</ValidatorForm>
		</div>
	);
};
export default IntegrationForm;
