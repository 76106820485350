
import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";

const CategoryForm = props => {
  const [formFields, setFormFields] = useState();
  const [showOrder, setShowOrder] = useState(false);
  const [cwConfigurationTypes, setCwConfigurationTypes] = useState();
  const [showCwConfigurationTypes, setShowCwConfigurationTypes] = useState(false);
  const [cwConfigurationTypeQuestions, setCwConfigurationTypeQuestions] = useState();
  const [showCwConfigurationTypeQuestions, setShowCwConfigurationTypeQuestions] = useState(false);
  const [showConfigurationItemTypes, setShowConfigurationItemTypes] = useState(false);
  const [configurationItemTypes, setConfigurationItemTypes] = useState();
  const [categoryType, setCategoryType] = useState();
  
  useEffect(() => {
    if(props.objectId){
      http.Get(
        'categories/'+props.objectId,
        (category)=>{
          setCategoryType(category.category_type);
          if(category.cw_configuration_type_id){    
            http.Get(
              'cw_configuration_type_questions/?cw_configuration_type_id='+category.cw_configuration_type_id,
              (data)=>{
                var valueMap = {};
                for (let i = 0; i < data.length; i++) {
                  valueMap[data[i].id] = data[i].question;
                }
                setCwConfigurationTypeQuestions(valueMap);
              },
              ()=>{}
            );
          }
        },
        ()=>{}
      );
    }
    else if(props.categoryType){
      setCategoryType(props.categoryType);
    }

    http.Get(
      'cw_configuration_types/',
      (data)=>{
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].name;
        }
        setCwConfigurationTypes(valueMap);
      },
      ()=>{}
    );

    http.Get(
      'autotask_configuration_item_types/',
      (data)=>{
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].name;
        }
        setConfigurationItemTypes(valueMap);
      },
      ()=>{}
    );

  },[]);

  useEffect(() => {
    setShowOrder(false);
    setShowCwConfigurationTypes(false);
    setShowCwConfigurationTypeQuestions(false);
    setShowConfigurationItemTypes(false);
    if(categoryType === "Employee Type")
    {
      setShowOrder(true);
    }
    else if(categoryType === "Device Type" || categoryType === "Service Type")
    {
      setShowCwConfigurationTypes(true);
      setShowCwConfigurationTypeQuestions(true);
      setShowConfigurationItemTypes(true);
    }
    else if(categoryType === "License Type")
    {
      setShowCwConfigurationTypes(true);
      setShowCwConfigurationTypeQuestions(false);
      setShowConfigurationItemTypes(true);
    }

  },[categoryType]);

  useEffect(() => {
    var fields = [
      { 
        id: "category_name", 
        label: "CATEGORY NAME *", 
        type: "text", 
      },
      {
        id: "category_type",
        label: "CATEGORY TYPE *",
        type: "select",
        onChange: (e,object,setObject,setErrors) => {
          setObject({
            ...object,
            [e.target.name]: e.target.value,
          });
          setCategoryType(e.target.value);
        },
        valueMap:{
          "Account Type":"Account Type",
          "Credential Type":"Credential Type",
          "Device Type":"Device Type",
          "Document Category":"Document Category",
          "Employee Type":"Employee Type",
          "Industry Type":"Industry Type",
          "License Type":"License Type",
          "Service Type":"Service Type",
          "Tag Type":"Tag Type",
        },
      },
    ];

    if(showOrder){
      fields.push(
        { 
          id: "order", 
          label: "ORDER", 
          type: "text", 
        }
      );
      fields.push(
        { 
          id: "is_default", 
          label: "IS DEFAULT", 
          type: "radio", 
        }
      );
    }

    if(showCwConfigurationTypes && cwConfigurationTypes){
      fields.push(
        { 
          id: "cw_configuration_type_id", 
          label: "CW CONFIGURATION TYPE", 
          type: "select", 
          onChange: (e,object,setObject,setErrors) => {
            setObject({
              ...object,
              [e.target.name]: e.target.value,
            }); 
            http.Get(
              'cw_configuration_type_questions/?cw_configuration_type_id='+e.target.value,
              (data)=>{
                var valueMap = {};
                for (let i = 0; i < data.length; i++) {
                  console.log(data[i]);
                  valueMap[data[i].id] = data[i].question;
                }
                setCwConfigurationTypeQuestions(valueMap);
              },
              ()=>{}
            );
          },
          valueMap:cwConfigurationTypes,
        }
      );
      if(showCwConfigurationTypeQuestions && cwConfigurationTypeQuestions){
        fields.push(
          { 
            id: "cw_username_question_id", 
            label: "CW USERNAME", 
            type: "select", 
            valueMap:cwConfigurationTypeQuestions,
          }
        );
  
        fields.push(
          { 
            id: "cw_password_question_id", 
            label: "CW PASSWORD", 
            type: "select", 
            valueMap:cwConfigurationTypeQuestions,
          }
        );

      }
    }

    if(showConfigurationItemTypes && configurationItemTypes){
      fields.push(
        { 
          id: "autotask_configuration_item_type_id", 
          label: "AUTOTASK CONFIGURATION ITEM TYPE", 
          type: "select", 
          onChange: (e,object,setObject,setErrors) => {
            setObject({
              ...object,
              [e.target.name]: e.target.value,
            });
          },
          valueMap:configurationItemTypes,
        }
      );
    }

    setFormFields(fields);

  }, [showOrder, cwConfigurationTypes, showCwConfigurationTypes, cwConfigurationTypeQuestions, showConfigurationItemTypes, configurationItemTypes, showCwConfigurationTypeQuestions, cwConfigurationTypeQuestions]);

  return(
    <div>
    {formFields && <ObjectForm {...props} url={`categories`} formName={"Category"} formFields={formFields} object={{tenant:props.user.tenant,category_type:props.categoryType,is_default:false}}/>}
    </div>
  );
};

export default CategoryForm;
