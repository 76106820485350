import React, { useEffect, useState } from "react";
import TemplateForm from "./TemplateForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "title",
    label: "TITLE",
    minWidth: 200,
    sortable: true,
  },
  {
    id: "template_type_name",
    label: "TYPE NAME",
    minWidth: 200,
    sortable: true,
  },
  { id: "created_by_name", label: "BY", minWidth: 150, sortable: true },
];

const Template = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `templates`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Templates"}
      url={url}
      actions={actions}
      permissions={{ create: [], edit: [], delete: [] }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={TemplateForm}
      orderBy={"title"}
      searchFields={["title"]}
      pageHeader={(props.user.company_name || "") + " | Templates"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Templates"}
      url={url}
      actions={actions}
      permissions={{ create: [], edit: [], delete: [] }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={TemplateForm}
      orderBy={"title"}
      searchFields={["title"]}
      pageHeader={(props.user.company_name || "") + " | Templates"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default Template;
