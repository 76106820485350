import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../../Common/CommonConstants";
import CloneIcon from "@material-ui/icons/FlipRounded";
import useStyles from "../../../Common/Style/FormStyle";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../../Common/Loader";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

const PortalRoleForm = (props) => {
  const classes = useStyles();
  const [role, setRole] = useState({});
  const [roleError, setRoleError] = useState({});
  const [roles, setRoles] = useState([]);
  const [activeRoles, setActiveRoles] = useState([]);
  const [isRoleDuplicate, setIsRoleDuplicate] = useState(false);
  const { requiredError, blankError, uniqueError } = commonConstants.error;
  const [isClone, setIsClone] = useState(false);
  const [clonedRole, setClonedRole] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const portalTabs = [
    {
      id: 1,
      label: 'SITES',
      prefix: 'sites_'
    },
    {
      id: 2,
      label: 'DOCUMENTS',
      prefix: 'docs_'
    },
    {
      id: 3,
      label: 'EMPLOYEES',
      prefix: 'employees_'
    },
    {
      id: 4,
      label: 'SERVERS & DEVICES',
      prefix: 'devices_'
    },
    {
      id: 5,
      label: 'SOFTWARE LICENSES',
      prefix: 'licenses_'
    },
    {
      id: 6,
      label: 'OTHER SERVICES',
      prefix: 'services_'
    },
    {
      id: 7,
      label: 'CUSTOM TABS',
      prefix: 'custom_'
    },
  ];

  useEffect(() => {
    console.log('PortalRoleForm', props);
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `portal-roles/${props.objectId}`,
        (role) => {
          setRole(role);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    }
    http.Get(
      `portal-roles`,
      (roles) => {
        var items = roles.filter((item) => item.status === true);
        setActiveRoles(items);
        setRoles(roles);
      },
      () => {}
    );
  }, []);

  const onChange = (e) => {
    setRole({
      ...role,
      [e.target.name]: e.target.value,
    });
    setRoleError({
      ...roleError,
      [e.target.name]: false,
    });
    if (e.target.name === "role_name") setIsRoleDuplicate(false);
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();

    const isDuplicate = roles.some((item) => {
      console.log(item);
      if (props.edit)
        return (
          item.role_name.toLowerCase() ===
            role.role_name.trim().toLowerCase() && item.id !== role.id
        );
      else
        return (
          item.role_name.toLowerCase() === role.role_name.trim().toLowerCase()
        );
    });
    if (isDuplicate) {
      setIsRoleDuplicate(true);
      return;
    }

    setIsSubmitted(true);

    if (props.edit && !isClone && props.objectId) {
      http.Put(
        `portal-roles/${props.objectId}`,
        role,
        (role) => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setRoleError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `portal-roles`,
        {...role, 'tenant': props.user.tenant},
        (role) => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setRoleError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
  };

  const handleCloneClick = (e) => {
    setClonedRole({
      ...role,
    });
    setRole({
      ...role,
      tenant: props.user.tenant,
      clone_role: role.id,
    });
    setIsClone(true);
  };

  const handlePermissionCheckChange = (prefix, permission) => (event) => {
    setRole({
      ...role,
      [prefix + permission]: event.target.checked,
    });
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" className={classes.title}>
              {isClone
                ? "Clone Portal Role: " + clonedRole.role_name
                : props.edit
                ? "Edit Portal Role"
                : "Add New Portal Role"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              {" "}
              <p className={classes.label}>ROLE NAME *</p>
              {!isClone && (
                <TextValidator
                  id="role_name"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="role_name"
                  value={role.role_name || ""}
                  onChange={onChange}
                  validators={["required", "trim"]}
                  errorMessages={[requiredError, blankError]}
                  inputProps={{ maxLength: 255 }}
                  error={
                    (isRoleDuplicate && role.role_name) ||
                    Boolean(roleError.role_name)
                  }
                  helperText={
                    (isRoleDuplicate && role.role_name && uniqueError) ||
                    (roleError.role_name && roleError.role_name[0])
                  }
                />
              )}
              {isClone && (
                <TextValidator
                  id="role_name"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="role_name"
                  value={role.role_name || ""}
                  onChange={onChange}
                  validators={["required", "trim"]}
                  errorMessages={[requiredError, blankError]}
                  inputProps={{ maxLength: 255 }}
                  error={
                    (isRoleDuplicate && role.role_name) ||
                    Boolean(roleError.role_name)
                  }
                  helperText={
                    (isRoleDuplicate && role.role_name && uniqueError) ||
                    (roleError.role_name && roleError.role_name[0])
                  }
                />
              )}
              <p className={classes.label}>ROLE DESCRIPTION</p>
              <TextValidator
                id="description"
                fullWidth
                variant="outlined"
                margin="dense"
                name="description"
                value={role.description || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(roleError.description)}
                helperText={roleError.description && roleError.description[0]}
              />
              <p className={classes.label}>STATUS</p>
              <TextValidator
                id="status"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="status"
                value={role.status !== undefined ? Boolean(role.status) : true}
                onChange={onChange}
                error={Boolean(roleError.status)}
                helperText={roleError.status && roleError.status[0]}
              >
                <MenuItem key={1} value={true}>
                  Active
                </MenuItem>
                <MenuItem key={2} value={false}>
                  Disabled
                </MenuItem>
              </TextValidator>
							&nbsp;
              {(!role.clone_role || role.clone_role === "") &&
                <Table className={classes.tables} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCellHeader}>PORTAL TAB</TableCell>
                      <TableCell className={classes.tableCellHeader}>CREATE</TableCell>
                      <TableCell className={classes.tableCellHeader}>READ</TableCell>
                      <TableCell className={classes.tableCellHeader}>MODIFY</TableCell>
                      <TableCell className={classes.tableCellHeader}>DELETE</TableCell>
                      <TableCell className={classes.tableCellHeader}>SEE HIDDEN</TableCell>
                    </TableRow>
                  </TableHead>
                  {portalTabs.map((row) => {
                    return (
                      <TableRow key={row.id}>
                        <TableCell>
                          {row.label}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            name={row.prefix + 'create'}
                            checked={role[row.prefix + 'create']}
                            onChange={handlePermissionCheckChange(row.prefix, 'create')}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            name={row.prefix + 'read'}
                            checked={role[row.prefix + 'read']}
                            onChange={handlePermissionCheckChange(row.prefix, 'read')}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            name={row.prefix + 'modify'}
                            checked={role[row.prefix + 'modify']}
                            onChange={handlePermissionCheckChange(row.prefix, 'modify')}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            name={row.prefix + 'delete'}
                            checked={role[row.prefix + 'delete']}
                            onChange={handlePermissionCheckChange(row.prefix, 'delete')}
                          />
                        </TableCell>
                        <TableCell>
                          {row.id === 3 && 
                            <Checkbox
                              name={row.prefix + 'hidden'}
                              checked={role[row.prefix + 'hidden']}
                              onChange={handlePermissionCheckChange(row.prefix, 'hidden')}
                            />
                          }
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </Table>
              }
              {props.edit && !isClone && (
                <React.Fragment>
                  <p></p>
                  <Button
                    variant="contained"
                    fullWidth
                    className={classes.cloneButton}
                    startIcon={<CloneIcon />}
                    value={role.clone_role}
                    name="clone_role"
                    onClick={handleCloneClick}
                    type="button"
                  >
                    Duplicate this role
                  </Button>
                </React.Fragment>
              )}
              {!props.edit && !isClone && (
                <div>
                  <p className={classes.label}>CLONE ROLE</p>
                  <TextValidator
                    id="clone_role"
                    fullWidth
                    select
                    variant="outlined"
                    margin="dense"
                    name="clone_role"
                    value={role.clone_role || ""}
                    onChange={onChange}
                    error={Boolean(roleError.clone_role)}
                    helperText={roleError.clone_role && roleError.clone_role[0]}
                  >
                    {activeRoles && activeRoles.length > 0 && (
                      <MenuItem key={-1} value={""}>
                        None
                      </MenuItem>
                    )}
                    {activeRoles && activeRoles.length === 0 ? (
                      <MenuItem value={""} selected disabled>
                        No roles found
                      </MenuItem>
                    ) : (
                      activeRoles.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.role_name}
                        </MenuItem>
                      ))
                    )}
                  </TextValidator>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={classes.divider}>
          <Divider />
        </div>

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className={classes.filledButton}
              variant="outlined"
              margin="dense"
              size="small"
              color="secondary"
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default PortalRoleForm;
