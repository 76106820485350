import React, { useEffect, useState } from "react";
import ServiceForm from "./ServiceForm";
import { connect } from "react-redux";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import http from "../../Common/RestAPIHandler";
import { onWebsiteClick, onCopy } from "../../Common/TableUtility";
import SnackbarComponent from "../../Common/Snackbar";
import { Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import OTP from "../../Common/OTP";

const useStyles = makeStyles((theme) => ({
  iconColorScheme: {
    cursor: "pointer",
	backgroundColor: "transparent !important",
    color: theme.linkColorFaded,
    "&:hover": {
		color: theme.linkColor
	},
    width: 15,
    verticalAlign: "bottom",
  },
  errorIconColorScheme: {
    color: "#f44336",
    width: 15,
  },
  passwordColorScheme: {
    cursor: "pointer",
    // color: theme.colorScheme,
  },
}));

const tableColumns = [
  {
    id: "service_name",
    label: "SERVICE NAME",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "description",
    label: "DESCRIPTION",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "copyUsername",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "username",
    label: "USERNAME",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "copyPassword",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "staredPassword",
    label: "PASSWORD",
    minWidth: 100,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "copyWebsite",
    label: "",
    minWidth: 10,
    sortable: false,
    disableTooltip: true,
  },
  {
    id: "website",
    label: "WEBSITE",
    minWidth: 150,
    sortable: true,
    onClick: onWebsiteClick,
    style: commonConstants.cellCursor,
    colored: true,
  },
  { 
    id: "notes",
    label: "NOTES",
    minWidth: 150,
    sortable: false,
  },
  {
    id: "otp",
    label: " ",
    minWidth: 100,
    disableTooltip: true,
    colored: true,
  },
];

const Services = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState();

  const url = `tenant_services`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.map((item) => {
          if (item["username"]){
          item["copyUsername"] = (
            <FilterNoneIcon
              fontSize="small"
              className={classes.iconColorScheme}
              onClick={(props, row, columnId) => {
                onCopy(props, item, "username");
                setSnackBarMessage("Username Copied");
              }}
            />
          );}

          
          if (item["password"]){
          
            item["copyPassword"] = (
              <FilterNoneIcon
                fontSize="small"
                className={classes.iconColorScheme}
                onClick={(props, row, columnId) => {
                  onCopy(props, item, "password");
                  setSnackBarMessage("Password Copied");
                }}
              />
            );
              
              item["staredPassword"] = (
            <>
              <span
                className={classes.passwordColorScheme}
                onClick={(e) => {
                  if (e.target.innerHTML === "············")
                    e.target.innerHTML = String(item["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
                  else e.target.innerHTML = "············";
                }}
              >
                ············
              </span>
              &nbsp;
              {item["flagged_pass"] ? (
                <Tooltip
                  interactive
                  arrow={true}
                  placement="top-start"
                  title="Unsafe Password!"
                >
                  <ErrorOutlineIcon className={classes.errorIconColorScheme} />
                </Tooltip>
              ) : (
                <></>
              )}
            </>
          );}
          else{
            item["staredPassword"] = ""
          }  
          // item["staredPassword"] = (
          //   <>
          //     <span
          //       className={classes.passwordColorScheme}
          //       onClick={(e) => {
          //         if (e.target.innerHTML === "············")
          //           e.target.innerHTML = String(item["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
          //         else e.target.innerHTML = "············";
          //       }}
          //     >
          //       ············
          //     </span>
          //     &nbsp;
          //     {item["flagged_pass"] ? (
          //       <Tooltip
          //         interactive
          //         arrow={true}
          //         placement="top-start"
          //         title="Unsafe Password!"
          //       >
          //         <ErrorOutlineIcon className={classes.errorIconColorScheme} />
          //       </Tooltip>
          //     ) : (
          //       <></>
          //     )}
          //   </>
          // );
          if (item["website"]){
          item["copyWebsite"] = (
            <FilterNoneIcon
              fontSize="small"
              className={classes.iconColorScheme}
              onClick={(props, row, columnId) => {
                onCopy(props, item, "website");
                setSnackBarMessage("Website Copied");
              }}
            />
          );}

          item["otp"] = <OTP {...props} otpKey={item["otp"]} />;
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <div>
      <SnackbarComponent
        message={snackBarMessage}
        open={snackBarMessage}
        handleSnackBarClose={() => {
          setSnackBarMessage(null);
        }}
        severity={"success"}
      ></SnackbarComponent>
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={"Tenant Services"}
          url={url}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          objectForm={props.user.max_service > -1 && data.length >= props.user.max_service ? null : ServiceForm}
          orderBy={"service_name"}
          searchFields={["service_name"]}
          pageHeader={(props.user.company_name || "") + " | Tenant Services"}
          loadData={loadData}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Tenant Services"}
          url={url}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          objectForm={props.user.max_service > -1 && data.length >= props.user.max_service ? null : ServiceForm}
          orderBy={"service_name"}
          searchFields={["service_name"]}
          pageHeader={(props.user.company_name || "") + " | Tenant Services"}
          loadData={loadData}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Services);
