import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import http from "../../Common/RestAPIHandler";
import Drawer from "@material-ui/core/Drawer";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Tooltip from "@material-ui/core/Tooltip";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ScreenLoader from "../../Common/Loader";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import {Divider} from '@material-ui/core'
import useStyles from "../Style/style";

const UnlockRequests = (props) => {
  const classes = useStyles();
  const [unlockRequests, setUnlockRequests] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [collapse, setCollapse] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();
  const [startWidth, setStartWidth] = useState();
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      "company_document_tenant_user_mappings/",
      (unlockRequests) => {
        setUnlockRequests(unlockRequests);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  const onDragStart = (e) => {
    setStartX(e.screenX);
    setStartWidth(width);
  };

  const onDrag = (e) => {
    setEndX(e.screenX);
    setWidth(startWidth + startX - endX);
  };

  const handleArrowClick = () => {
    setCollapse(!collapse);
    props.setDoc(collapse);
  };

  const handleSetOpen = (open) => {
    setOpen(open);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={6}>
              <span className={classes.title}>DOCUMENT UNLOCK REQUESTS</span>
            </Grid>
            <Grid item xs={6} className={classes.icons}>
              {(collapse && (
                <ArrowDropDownIcon
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )) || (
                <ArrowDropUpIcon
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )}
            </Grid>
          </Grid>
          <Divider classes={{root: classes.dividers}} component="hr" orientation="horizontal" />
        </Grid>

        {loadData ? (
          <ScreenLoader />
        ) : (
          <React.Fragment>
            {!collapse && (
              <Grid item xs={12} className={classes.contentGrid}>
                <Grid container spacing={1}>
                  {unlockRequests.map((unlockRequest) => {
                    return (
                      <Grid item xs={12} key={unlockRequest.id}>
                        <Paper elevation={0} className={classes.paper}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={5}>
                              {unlockRequest.document_title}
                            </Grid>
                            <Grid item xs={5}>
                              {unlockRequest.tenant_user_email}
                            </Grid>
                            <Grid item xs={2}>     
                              {!unlockRequest.is_locked &&
                                <Tooltip title="Click to Delete Approval">
                                  <ThumbUpIcon
                                    className={classes.button}
                                    onClick={(e) =>{	
                                      unlockRequest.is_locked = true;						
                                      http.Put(
                                        `company_document_tenant_user_mappings/${unlockRequest.id}/`,
                                        unlockRequest,
                                        data => {
                                          fetchData();
                                        },
                                        () => {										
                                        }
                                      );
                                    }}
                                  />
                                </Tooltip>
                              }   
                              {unlockRequest.is_locked &&
                                <Tooltip title="Click to Approve Request">
                                  <ThumbDownIcon
                                    className={classes.button}
                                    onClick={(e) =>{	
                                      unlockRequest.is_locked = false;						
                                      http.Put(
                                        `company_document_tenant_user_mappings/${unlockRequest.id}/`,
                                        unlockRequest,
                                        data => {
                                          fetchData();
                                        },
                                        () => {										
                                        }
                                      );
                                    }}
                                  />
                                </Tooltip>
                              }  
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        )}
      </Grid>

      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          handleSetOpen(false);
        }}
        disableEnforceFocus
        classes={{
          paper: classes.drawerForm,
        }}
      >
        <div style={{ width: width }}>
          <table>
            <tbody>
              <tr>
                <td
                  draggable
                  style={{ cursor: "ew-resize" }}
                  onDragStart={onDragStart}
                  onDrag={onDrag}
                >
                  <DragIndicatorIcon />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>
    </div>
  );
};
export default UnlockRequests;
