import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {
  Divider,
  Typography,
  Paper,
  Grid,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Tooltip,
} from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";
import { ConvertDateToLocale } from "../../Common/Utility";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

import DomainForm from "./DomainForm";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CreateIcon from "@material-ui/icons/Create";

const DomainDetails = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  var { domainId } = props.match.params;
  const [domain, setDomain] = useState({});
  const [subDomains, setSubDomains] = useState([]);
  const [dataMappings, setDataMappings] = useState([]);
  const [loading, setLoading] = useState(true);
  const today = new Date();
  const expirationDate = new Date(
    today.getTime() + localStorage.getItem("notification_days") * 86400000
  );

  useEffect(() => {
    if (props.objectId) {
      domainId = props.objectId;
    }
    http.Get(
      `company_domains/${domainId}`,
      (domain) => {
        setDomain(domain);

        http.Get(
          `data_mappings?from_id=${domain.id}`,
          (data) => {
            setDataMappings(data);
            http.Get(
              `sub_domains?domain=${domain.id}`,
              (subDatas) => {
                subDatas.map((subDomain) => {
                  if (
                    subDomain.ssl_expiration_date &&
                    ConvertDateToLocale(subDomain.ssl_expiration_date) < today
                  ) {
                    subDomain["icon_ssl"] = (
                      <Tooltip title="SSL has expired.">
                        <ClearIcon />
                      </Tooltip>
                    );
                  } else if (
                    subDomain.ssl_expiration_date &&
                    ConvertDateToLocale(subDomain.ssl_expiration_date) <
                      expirationDate
                  ) {
                    subDomain["icon_ssl"] = (
                      <Tooltip title="SSL will expire soon.">
                        <WarningIcon />
                      </Tooltip>
                    );
                  }
                });
                setSubDomains(subDatas);
                setLoading(false);
              },
              () => {}
            );
          },
          () => {}
        );
      },
      () => {}
    );
  }, []);

  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();

  const onDragStart = (e) => {
    setStartX(e.screenX);
  };

  const onDragEnd = (e) => {
    setEndX(e.screenX);
  };

  useEffect(() => {
    if(startX && endX){
      setWidth(width + startX - endX);
    }
  }, [startX, endX]);
  
  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setWidth(vw * 0.8);
  }, [window.innerWidth]);

  const handleObjectSubmit = () => {
    window.location.reload(false);
  };

  return (
    <>
      {loading && <ScreenLoader />}
      {!loading && (
        <Grid container spacing={1} className={classes.paper}>
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              {domain.domain_name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <div style={{ paddingTop: 10, float: "right" }}>
              <IconButton
                className={classes.iconButtonColored}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  } else {
                    props.history.push(
                      process.env.PUBLIC_URL + `/company/${id}/domains`
                    );
                  }
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={10}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>DOMAIN NAME</span>
                  </Grid>
                  <Grid item xs={8}>
                    {domain.domain_name}
                    
                    <IconButton
                        className={classes.iconButtonColored}
                        style={{float:"right"}}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        <CreateIcon />
                    </IconButton>
                    <Drawer
                        anchor="right"
                        open={open}
                        onClose={() => setOpen(false)}
                        disableEnforceFocus
                        classes={{
                            paper: classes.drawerForm,
                        }}
                    >
                        <div style={{ width: width }}>
                            <table>
                            <tbody>
                                <tr>
                                <td
                                    draggable
                                    style={{ cursor: "ew-resize" }}
                                    onDragStart={onDragStart}
                                    onDragEnd={onDragEnd}
                                >
                                    <DragIndicatorIcon />
                                </td>
                                <td width="100%">
                                    <DomainForm
                                        {...props}
                                        closeOpen={(open) => setOpen(open)}
                                        objectId={props.objectId}
                                        handleObjectSubmit={handleObjectSubmit}
                                    />
                                </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </Drawer>

                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>REGISTRAR</span>
                  </Grid>
                  <Grid item xs={8}>
                    {domain.registrar}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>EXPIRATION DATE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {domain.expiration_date}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>LAST UPDATED</span>
                  </Grid>
                  <Grid item xs={8}>
                    {domain.last_updated}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>CREATION DATE</span>
                  </Grid>
                  <Grid item xs={8}>
                    {domain.creation_date}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>DNS</span>
                  </Grid>
                  <Grid item xs={8}>
                    {domain.dns && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: domain.dns.split("\n").join("<br/>"),
                        }}
                      />
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <span style={{ fontWeight: "bold" }}>
                      SSL EXPIRATION DATE
                    </span>
                  </Grid>
                  <Grid item xs={8}>
                    {domain.ssl_expiration_date}
                  </Grid>
                  <Grid item xs={12}>
                    {/* <span style={{fontWeight:"bold"}}>SUB DOMAINS</span> */}
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Table>
                      <TableHead>
                        <TableCell className={classes.tableCellHeader}>
                          SUB DOMAIN
                        </TableCell>
                        <TableCell className={classes.tableCellHeader}>
                          SSL EXPIRATION DATE
                        </TableCell>
                      </TableHead>
                      {subDomains.map((subDomain) => {
                        return (
                          <TableRow className={classes.tableRow}>
                            <TableCell className={classes.tableCell}>
                              {subDomain.sub_domain_name}
                            </TableCell>
                            <TableCell className={classes.tableCell}>
                              {subDomain.icon_ssl}{" "}
                              {subDomain.ssl_expiration_date}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Divider />
          </Grid>
          <Grid item xs={2}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <p style={{ fontWeight: "bold" }}>Related Entries:</p>
                <DataMappings
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
              </div>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DomainDetails;
