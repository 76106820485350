import React, { useState, useEffect, useRef } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ScreenLoader from "../../Common/Loader";
import Popover from "@material-ui/core/Popover";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import KeyGen from "../../Common/KeyGen";
import QrReader from "react-qr-reader";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DataMappings from "../DataMappings";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";


export var service_status = 0;
export function setS(prop){
  service_status = prop;
}

const ServiceForm = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [service, setService] = useState({ company_id: props.match.params.id });
  const [serviceError, setServiceError] = useState({});
  const [sites, setSites] = useState([]);
  const [isWebsiteInvalid, setIsWebsiteInvalid] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPasswordShow, setIsPasswordShow] = useState(false);
  const [loadData, setLoadData] = useState(false);
  const [categories, setCategories] = useState([]);
  const [turnonCamera, setTurnonCamera] = useState(false);
  const [showQrReader, setShowQrReader] = useState(false);
  const qrReader = useRef(null);
  const { requiredError, blankError, urlError, websitePlaceholder } =
    commonConstants.error;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dataMappings, setDataMappings] = useState([]);

  const handleClickShowKeyGen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseShowKeyGen = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (props.objectId) {
      setLoadData(true);
      http.Get(
        `companies/${id}/services/${props.objectId}`,
        (service) => {
          setService(service);
          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );

      http.Get(
        `data_mappings?from_id=${props.objectId}`,
        (data) => {
          setDataMappings(data);
        },
        () => {}
      );
    }
    http.Get(
      `companies/${id}/sites`,
      (sites) => setSites(sites),
      () => {}
    );

    http.Get(
      `categories?tenant=${props.companyTenant?props.companyTenant.id:null}&category_type=Service Type`,
      (data) => {
        setCategories(data);
      },
      () => {}
    );
  }, []);

  const onChange = (e) => {
    setService({
      ...service,
      [e.target.name]: e.target.value,
    });
    setServiceError({
      ...serviceError,
      [e.target.name]: false,
    });
    setIsWebsiteInvalid(false);
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    setLoadData(true);
    e.preventDefault();

    setIsSubmitted(true);
    // const send = () => {
    if (props.objectId) {
      http.Put(
        `companies/${id}/services/${props.objectId}`,
        service,
        (service) => {
          http.Delete(
            "data_mappings",
            { from_id: props.objectId },
            () => {
              var promises = dataMappings.map((dataMapping) => {
                dataMapping.from_id = props.objectId;
                return http.Post(
                  "data_mappings",
                  dataMapping,
                  (data) => {
                    console.log(data);
                  },
                  (status, error) => {
                    console.log(status);
                    console.log(error);
                  }
                );
              });
              Promise.all(promises).then((results) => {
                props.handleObjectSubmit();
                setLoadData(false);
              });
            },
            () => {
              props.handleObjectSubmit();
              setLoadData(false);
            }
          );
        },
        (status, error) => {
          if (status === 400) setServiceError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `companies/${id}/services`,
        service,
        (service) => {
          var promises = dataMappings.map((dataMapping) => {
            dataMapping.from_id = service.id;
            return http.Post(
              "data_mappings",
              dataMapping,
              (data) => {
                console.log(data);
              },
              (status, error) => {
                console.log(status);
                console.log(error);
              }
            );
          });
          Promise.all(promises).then((results) => {
            props.handleObjectSubmit();
            setLoadData(false);
          });
        },
        (status, error) => {
          if (status === 400) setServiceError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
    // };

    // // If been presented with the 'unsafe password error' once, allow user to POST again
    // validPassword(service).then(({ res, error }) => {
    // 	if (res === true) {
    // 		console.log("res supposed to be true", res);
    // 		send();
    // 	} else if (error) {
    // 		console.log("res supposed to be false", res);
    // 		// Flag password as unsafe for UI feedback
    // 		service["flagged_pass"] = true;
    // 		console.log(service);
    // 		send();
    // 	}
    // });
  };

  const handleClickShowPassword = () => {
    setIsPasswordShow(!isPasswordShow);
  };

  return (
    <div className={classes.list}>
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" component="h6">
              {props.objectId ? "Edit Service" : "Add New Service"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>SERVICE NAME *</p>
              <TextValidator
                id="service_name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="service_name"
                value={service.service_name || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(serviceError.service_name)}
                helperText={
                  serviceError.service_name && serviceError.service_name[0]
                }
              />
              <p className={classes.label}>SERVICE TYPE *</p>
              <TextValidator
                id="category"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="category"
                value={service.category || ""}
                onChange={onChange}
                validators={["required"]}
                errorMessages={[requiredError]}
                error={Boolean(serviceError.category)}
                helperText={serviceError.category && serviceError.category[0]}
              >
                {categories && categories.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No category found
                  </MenuItem>
                ) : (
                  categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.category_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>
              <p className={classes.label}>DESCRIPTION</p>
              <TextValidator
                id="description"
                fullWidth
                variant="outlined"
                margin="dense"
                name="description"
                value={service.description || ""}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                error={Boolean(serviceError.description)}
                helperText={
                  serviceError.description && serviceError.description[0]
                }
              />
              <p className={classes.label}>WEBSITE</p>
              <TextValidator
                id="website"
                fullWidth
                variant="outlined"
                margin="dense"
                name="website"
                value={service.website || ""}
                placeholder={websitePlaceholder}
                onChange={onChange}
                inputProps={{ maxLength: 255 }}
                error={
                  (isWebsiteInvalid && service.website) ||
                  Boolean(serviceError.website)
                }
                helperText={
                  (isWebsiteInvalid && service.website && urlError) ||
                  (serviceError.website && serviceError.website[0])
                }
              />
              <p className={classes.label}>USERNAME</p>
              <TextValidator
                id="new-name"
                autoComplete="new-name"
                fullWidth
                variant="outlined"
                margin="dense"
                name="new-name"
                value={service.username || ""}
                onChange={(e) => {
                  setService({
                    ...service,
                    ["username"]: e.target.value,
                  });
                  setServiceError({
                    ...serviceError,
                    ["username"]: false,
                  });
                  setIsWebsiteInvalid(false);
                  setIsSubmitted(false);
                }}
                inputProps={{ maxLength: 100 }}
                error={Boolean(serviceError.username)}
                helperText={serviceError.username && serviceError.username[0]}
              />
              <p className={classes.label}>PASSWORD</p>
              <TextValidator
                id="new-pass"
                autoComplete="new-pass"
                fullWidth
                variant="outlined"
                margin="dense"
                name="new-pass"
                type={isPasswordShow ? "text" : "password"}
                value={service.password || ""}
                onChange={(e) => {
                  setService({
                    ...service,
                    ["password"]: e.target.value,
                  });
                  setServiceError({
                    ...serviceError,
                    ["password"]: false,
                  });
                  setIsWebsiteInvalid(false);
                  setIsSubmitted(false);
                }}
                inputProps={{ maxLength: 100 }}
                error={Boolean(serviceError.password)}
                helperText={serviceError.password && serviceError.password[0]}
                InputProps={{
                  style: { fontFamily: "Raleway" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                      >
                        {isPasswordShow ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff style={{ color: "#5d5e6a" }} />
                        )}
                      </IconButton>
                      <IconButton
                        aria-label="KeyGen"
                        onClick={handleClickShowKeyGen}
                      >
                        <FingerprintIcon />
                      </IconButton>
                      <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleCloseShowKeyGen}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <KeyGen
                          setPassword={(p) => {
                            setService({
                              ...service,
                              ["password"]: p,
                            });
                            handleCloseShowKeyGen();
                          }}
                        />
                      </Popover>
                    </InputAdornment>
                  ),
                }}
              />
              <p className={classes.label}>NOTES</p>
              <TextValidator
                id="notes"
                fullWidth
                variant="outlined"
                margin="dense"
                multiline
                rows={3}
                name="notes"
                value={service.notes || ""}
                onChange={onChange}
                error={Boolean(serviceError.notes)}
                helperText={serviceError.notes && serviceError.notes[0]}
              />
              <p className={classes.label}>OTP KEY</p>
              <TextValidator
                id="otp"
                fullWidth
                variant="outlined"
                margin="dense"
                name="otp"
                value={service.otp || ""}
                onChange={onChange}
                error={Boolean(serviceError.otp)}
                helperText={serviceError.otp && serviceError.otp[0]}
              />
              {turnonCamera && (
                <QrReader
                  delay={300}
                  onError={(err) => {
                    console.error(err);
                  }}
                  onScan={(data) => {
                    if (data && data.includes("secret=")) {
                      setTurnonCamera(false);
                      setService({
                        ...service,
                        ["otp"]: data
                          .split("secret=")[1]
                          .split("?")[0]
                          .split("&")[0],
                      });
                    }
                  }}
                  style={{ width: 200 }}
                />
              )}
              {!turnonCamera && (
                <IconButton>
                  <CameraAltIcon
                    className={classes.download}
                    onClick={() => {
                      setTurnonCamera(true);
                    }}
                  />
                </IconButton>
              )}
              {!showQrReader && (
                <IconButton>
                  <CloudUploadIcon
                    className={classes.download}
                    onClick={() => {
                      setShowQrReader(true);
                      qrReader.current.openImageDialog();
                    }}
                  />
                </IconButton>
              )}
              <div
                className={classes.download}
                style={{ display: showQrReader ? "block" : "none" }}
                onClick={() => {
                  qrReader.current.openImageDialog();
                }}
              >
                <QrReader
                  ref={qrReader}
                  legacyMode={true}
                  onError={(err) => {
                    console.error(err);
                  }}
                  onScan={(data) => {
                    if (data && data.includes("secret=")) {
                      setService({
                        ...service,
                        ["otp"]: data
                          .split("secret=")[1]
                          .split("?")[0]
                          .split("&")[0],
                      });
                    } else {
                      setService({
                        ...service,
                        ["otp"]: "No OTP Key Found",
                      });
                    }
                  }}
                  style={{ width: 200 }}
                />
              </div>
              <p className={classes.label}>LOCATION</p>
              <TextValidator
                id="location"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="location_id"
                value={service.location_id || ""}
                onChange={onChange}
                error={Boolean(serviceError.location_id)}
                helperText={
                  serviceError.location_id && serviceError.location_id[0]
                }
              >
                {sites && sites.length > 0 && (
                  <MenuItem key={-1} value={""}>
                    NONE
                  </MenuItem>
                )}
                {sites && sites.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No locations found
                  </MenuItem>
                ) : (
                  sites.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.site_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>

              <p className={classes.label}>Related Entries</p>
              <DataMappings
                {...props}
                fromId={props.objectId}
                fromModel={"Service"}
                dataMappings={dataMappings}
                setDataMappings={setDataMappings}
              />
            </div>
          )}
        </div>

        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              color="primary"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              className={classes.filledButton}
              variant="outlined"
              margin="dense"
              size="small"
              color="secondary"
              type="submit"
              disabled={isSubmitted}
              onClick={() => props.objectId ?service_status=0:service_status=1}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default ServiceForm;
