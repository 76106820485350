import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import DocumentForm from "./DocumentForm";
import { formatDateTime } from "../../Common/Utility";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';

const Documents = (props) => {
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("All");
  const [showData, setShowData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [categories, setCategories] = useState();
  const url = `tenant_documents`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "title",
      label: "TITLE",
      minWidth: 150,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile ? null : (props, item, column_id)=>{
        props.history.push(
          process.env.PUBLIC_URL + `documents/${item.id}/details`
        );
      }
    },
    { 
      id: "download", 
      label: "", 
      minWidth: 10, 
      colored: true, 
      style: commonConstants.cellCursor 
    },
    {
      id: "last_modified_time",
      label: "LAST MODIFIED",
      minWidth: 175,
      sortable: true,
      onClick: (props, item, column_id) => {
        props.history.push(
          process.env.PUBLIC_URL + `documents/${item.id}/details`
        );
      },
    },
    {
      id: "created_by_name",
      label: "BY",
      minWidth: 150,
      sortable: true,
    },
  ];

  useEffect(() => {
    http.Get(
      `categories?category_type=Document Category`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setCategories(valueMap);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    if (categories) {
      fetchData();
    }
  }, [categories]);

  useEffect(() => {
    var dataList = [];
    data.map((doc) => {
      if (category === "All" || doc.category === category) {
        dataList.push(doc);
      }
    });
    setShowData(dataList);
  }, [data, category]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach((item) => {
          item.last_modified_time = formatDateTime(item.last_modified_time);
          item.download = 
          <SaveAltIcon 
            onClick={() => {        
              var divToPDF = window.document.getElementById(item.id);
              html2canvas(divToPDF)
                .then((canvas) => {
                  var imgData = canvas.toDataURL('image/png');
                  var imgWidth = 210; 
                  var pageHeight = 292;
                  var imgHeight = canvas.height * imgWidth / canvas.width;
                  var heightLeft = imgHeight;
                  var doc = new jsPDF('p', 'mm');
                  var position = 0;
                  doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                  heightLeft -= pageHeight;
                  
                  while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                  }
                  doc.save(item.id+".pdf");
                })
              ;
            }}/>;
        });
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {data && data.map((meeting)=>{
        return <div id={meeting.id} style={{position:"absolute",left:"-8000px",width:"780px",padding:"30px"}} dangerouslySetInnerHTML={{ __html: meeting.content }}/>
      })}
      {!props.mobile && data && (
        <ObjectList
          {...props}
          moduleName={
            <Grid container>
              <Grid item>Resources </Grid>
              <Grid item>&nbsp;&nbsp;&nbsp;&nbsp;</Grid>
              <Grid item style={{ fontSize: "12px", marginLeft: "20px" }}>
                <Select
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  <MenuItem value="All">All Categories</MenuItem>
                  {categories &&
                    Object.entries(categories).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>
          }
          url={url}
          templateUrl={"public_templates?template_type_name=Document"}
          actions={actions}
          objectForm={props.user.max_resource > -1 && data.length >= props.user.max_resource ? null : DocumentForm}
          categories={categories}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          tableColumns={tableColumns}
          data={showData}
          fetchData={fetchData}
          orderBy={"title"}
          searchFields={["title"]}
          pageHeader={(props.user.company_name || "") + " | Resources"}
          loadData={loadData}
        />
      )}
      {props.mobile && data && (
        <ObjectListMobile
          {...props}
          moduleName={"Resources"}
          mobileMenu={
            <Grid item xs style={{ fontSize: "12px" }}>
              <Select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <MenuItem value="All">All Categories</MenuItem>
                {categories &&
                  Object.entries(categories).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          }
          url={url}
          templateUrl={"public_templates?template_type_name=Document"}
          actions={actions}
          objectForm={ props.user.max_resource > -1 && data.length >= props.user.max_resource ? null : DocumentForm}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          tableColumns={tableColumns}
          data={showData}
          fetchData={fetchData}
          orderBy={"title"}
          searchFields={["title"]}
          pageHeader={(props.user.company_name || "") + " | Resources"}
          loadData={loadData}
        />
      )}
    </div>
  );
};

export default Documents;
