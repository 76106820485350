import React from "react";
import ObjectForm from "../../../Common/ObjectForm";

const JobPostingForm = (props) => {
  const formFields = [
    {
      id: "title",
      label: "TITLE",
      type: "text",
    },
    {
      id: "content",
      label: "CONTENT",
      type: "ckeditor",
    },
  ];

  return (
    <ObjectForm
      {...props}
      objectId={props.objectId}
      url={`hr_job_postings`}
      formName={"Job Posting"}
      formFields={formFields}
      object={{ tenant:props.user.tenant,content: props.template.data || "" }}
    />
  );
};

export default JobPostingForm;
