import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import http from "../../Common/RestAPIHandler";
import {
  TextValidator,
  ValidatorForm,
  SelectValidator,
} from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import ClassicEditor from "@ckeditor-linkpoint/ckeditor5-build-classic";
import commonConstants from "../../Common/CommonConstants";
import useStyles from "../../Common/Style/FormStyle";
import commonConstraints from "../../Common/CommonConstants";
import Select from "@material-ui/core/Select";
import ReactHtmlParser from "react-html-parser";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import ScreenLoader from "../../Common/Loader";
import FileViewer from "../../Common/FileViewer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { formatDateTime } from "../../Common/Utility";
import { Input, InputLabel } from "@material-ui/core";
import MiniSpinLoader from "../../Common/MiniSpinLoader";

const DocumentForm = (props) => {
  const classes = useStyles();
  const [spin, setSpin] = useState(false);
  const [errors, setErrors] = useState({});
  const [tenantDocument, setTenantDocument] = useState({
    tenant: props.user.tenant,
  });
  const [documentError, setDocumentError] = useState({});
  const [data, setData] = useState(props.template.data || "");
  const [ckeditor, setCkEditor] = useState();
  const { requiredError, blankError } = commonConstants.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [categories, setCategories] = useState([]);
  const [files, setFiles] = useState([]);
  const [versions, setVersions] = useState({});
  const [documents, setDocuments] = useState({});
  const [documentContents, setDocumentContents] = useState();
  const [relatedDocument, setRelatedDocument] = useState();
  const [companyMappings, setCompanyMappings] = useState();
  const [companies, setCompanies] = useState();
  const [availableCompanies, setAvailableCompanies] = useState();
  const [loadData, setLoadData] = useState();
  const [objectId, setObjectId] = useState(props.objectId);
  const [filePath, setFilePath] = useState();
  var tenantUsers = [];
  var tagusers = [];

  useEffect(() => {
    if (objectId) {
      setLoadData(true);
      http.Get(
        `tenant_documents/${objectId}`,
        (tenantDocument) => {
          let data = tenantDocument.content;
          if (!data) data = "";
          delete tenantDocument.content;
          setData(data);
          setTenantDocument(tenantDocument);
          setLoadData(false);

          http.Get(
            "tenant_users",
            (userData) => {
              const items = userData.map((item) => {
                const obj = {};
                Object.keys(item).forEach((key) => {
                  obj[key] = item[key];
                });
                obj.user_id = item.id;
                obj.name = item.first_name + " " + item.last_name;
                obj.username = "@" + item.email.split("@")[0];
                obj.id = obj.username;
                return obj;
              });
              tenantUsers = items;
              setEditor(data);
            },
            () => {}
          );
        },
        () => {
          setLoadData(false);
        }
      );

      http.Get(
        "tenant_document_versions?document_id=" + objectId,
        (data) => {
          var valueMap = {};
          for (let i = 0; i < data.length; i++) {
            data[i].serialized_data = JSON.parse(data[i].serialized_data);
            valueMap[data[i].serialized_data[0].fields.content] =
              formatDateTime(
                data[i].serialized_data[0].fields.last_modified_time
              );
          }
          setVersions(valueMap);
        },
        () => {}
      );

      getFiles();
    } else {
      http.Get(
        "tenant_users",
        (data) => {
          const items = data.map((item) => {
            const obj = {};
            Object.keys(item).forEach((key) => {
              obj[key] = item[key];
            });
            obj.user_id = item.id;
            obj.name = item.first_name + " " + item.last_name;
            obj.username = "@" + item.email.split("@")[0];
            obj.id = obj.username;
            return obj;
          });
          tenantUsers = items;
          setEditor(props.template.data || "");
        },
        () => {}
      );
    }

    http.Get(
      `tenant_documents`,
      (data) => {
        var valueMap = {};
        var contentMap = {};
        for (let i = 0; i < data.length; i++) {
          if (data[i].id !== objectId) {
            valueMap[data[i].id] = data[i].title;
            contentMap[data[i].id] = data[i].content;
          }
        }
        setDocuments(valueMap);
        setDocumentContents(contentMap);
      },
      () => {}
    );

    http.Get(
      `companies`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].company_name;
        }
        setCompanies(valueMap);
      },
      () => {}
    );

    http.Get(
      `categories?category_type=Document Category`,
      (data) => {
        setCategories(data);
      },
      () => {}
    );

    localStorage.setItem("companysForTenantDocument", "");
  }, [objectId]);

  useEffect(() => {
    if (companies) {
      getCompanyMappings();
    }
  }, [companies]);

  useEffect(() => {
    if (companies && companyMappings) {
      var availableList = { ...companies };
      for (let i = 0; i < companyMappings.length; i++) {
        if (availableList[companyMappings[i]["value"]]) {
          delete availableList[companyMappings[i]["value"]];
        }
      }
      setAvailableCompanies(availableList);
    }
  }, [companies, companyMappings]);

  const getCompanyMappings = () => {
    if (objectId) {
      http.Get(
        `document_company_mappings?document=${objectId}`,
        (data) => {
          var companyList = [];
          for (let i = 0; i < data.length; i++) {
            data[i]["value"] = data[i]["company"];
            data[i]["label"] = companies[data[i]["company"]];
            data[i]["onDelete"] = () => {
              http.Delete(
                "document_company_mappings" + data[i]["id"] + "/",
                {},
                (data) => {
                  getCompanyMappings();
                },
                (status, error) => {}
              );
            };
            companyList.push(data[i]);
          }
          setCompanyMappings(companyList);
        },
        () => {}
      );
    } else {
      var data = localStorage.getItem("companysForTenantDocument");
      if (data) {
        var relatedCompanys = data.split(",");
        var relatedCompanyList = [];
        relatedCompanys.map((relatedCompany) => {
          if (relatedCompany) {
            var data = {};
            data["value"] = relatedCompany;
            data["label"] = companies[relatedCompany];
            data["onDelete"] = (e) => {
              var relatedCompanys = localStorage.getItem(
                "companysForTenantDocument"
              );
              if (relatedCompanys) {
                localStorage.setItem(
                  "companysForTenantDocument",
                  relatedCompanys.replaceAll(relatedCompany + ",", "")
                );
                getCompanyMappings();
              }
              var subDatas = tenantDocument.subDatas;
              if (subDatas) {
                var newSubDatas = subDatas.filter((subData) => {
                  return subData.data.company !== relatedCompany;
                });
                setTenantDocument({
                  ...tenantDocument,
                  subDatas: newSubDatas,
                });
              }
            };
            relatedCompanyList.push(data);
          }
        });
        setCompanyMappings(relatedCompanyList);
      } else {
        localStorage.setItem("companysForTenantDocument", "");
        setCompanyMappings([]);
      }
    }
  };

  const getFiles = () => {
    if (objectId) {
      http.Get(
        `tenant_document_files?tenant_document=${objectId}`,
        (data) => {
          var fileList = [];
          for (let i = 0; i < data.length; i++) {
            data[i]["deleteFile"] = (fileId) => {
              http.Delete(
                `tenant_document_files/${fileId}`,
                {},
                (data) => {
                  getFiles();
                },
                () => {}
              );
            };
            fileList.push(data[i]);
          }
          setFiles(fileList);
        },
        () => {}
      );
    } else {
      setFiles([]);
    }
  };

  const setEditor = (data) => {
    ClassicEditor.create(document.querySelector("#ckeditor"), {
      extraPlugins: [MentionCustomization],
      mention: {
        feeds: [
          {
            marker: "@",
            feed: getFeedItems,
            itemRenderer: customItemRenderer,
          },
        ],
      },
      ckfinder: {
        uploadUrl: commonConstraints.serverName + "ckeditor_file",
      },
    })
      .then((editor) => {
        setCkEditor(editor);
        editor.setData(data);
        editor.editing.view.change((writer) => {
          writer.setStyle(
            "height",
            "calc(100vh/4)",
            editor.editing.view.document.getRoot()
          );
        });
        editor.model.document.on("change:data", () => {
          setData(editor.getData());
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  function MentionCustomization(editor) {
    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "a",
        key: "data-mention",
        classes: "mention",
        disabled: true,
        attributes: {
          "data-user-id": true,
          "data-user-email": true,
          href: "#",
        },
      },
      model: {
        key: "mention",
        value: (viewItem) => {
          const mentionAttribute = editor.plugins
            .get("Mention")
            .toMentionAttribute(viewItem, {
              user_id: viewItem.getAttribute("data-user-id"),
              email: viewItem.getAttribute("data-user-email"),
            });

          return mentionAttribute;
        },
      },
      converterPriority: "high",
    });

    editor.conversion.for("downcast").attributeToElement({
      model: "mention",
      view: (modelAttributeValue, viewWriter) => {
        if (!modelAttributeValue) {
          return;
        }

        return viewWriter.createAttributeElement("a", {
          class: "mention",
          "data-mention": modelAttributeValue.username,
          "data-user-id": modelAttributeValue.user_id,
          "data-user-email": modelAttributeValue.email,
          href: "#",
        });
      },
      converterPriority: "high",
    });
  }

  function getFeedItems(queryText) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const itemsToDisplay = tenantUsers.filter(isItemMatching).slice(0, 5);
        resolve(itemsToDisplay);
      }, 100);
    });

    function isItemMatching(item) {
      var searchString = queryText ? queryText.toLowerCase() : "";
      return (
        item.email.toLowerCase().includes(searchString) ||
        item.id.toLowerCase().includes(searchString) ||
        item.name.toLowerCase().includes(searchString)
      );
    }
  }

  function customItemRenderer(item) {
    const itemElement = document.createElement("div");
    const avatarElememt = document.createElement("span");
    const avatar = document.createElement("img");
    const profileElement = document.createElement("span");
    const fullNameElement = document.createElement("span");
    const userNameElement = document.createElement("span");

    itemElement.classList.add(classes.mentionItem);
    avatarElememt.classList.add(classes.mentionItemAvatar);
    avatar.classList.add(classes.mentionAvatar);

    if (item.profile_image) {
      avatar.src = `${item.profile_image.split("?")[0]}`;
    } else {
      avatar.src = "";
    }

    profileElement.classList.add(classes.mentionItemProfile);

    userNameElement.classList.add(classes.mentionItemUsername);
    userNameElement.textContent = item.username;

    fullNameElement.classList.add(classes.mentionItemFullname);
    fullNameElement.textContent = item.name;

    avatarElememt.appendChild(avatar);
    profileElement.appendChild(fullNameElement);
    profileElement.appendChild(userNameElement);
    itemElement.appendChild(avatarElememt);
    itemElement.appendChild(profileElement);

    return itemElement;
  }

  function transform(node, index) {
    if (
      node.type === "tag" &&
      node.name === "a" &&
      node.attribs.class === "mention"
    ) {
      const user_id = node.attribs["data-user-id"];
      tagusers.push({ id: user_id });
    }
  }

  const uploadFile = (e) => {
    if (e.target.files && e.target.files.length === 1) {
      setSpin(true);
      var formData = new FormData();
      formData.append("name", e.target.files[0].name);
      formData.append("file", e.target.files[0], e.target.files[0].name);
      if (objectId) {
        formData.append("tenant_document", tenantDocument.id);
        http.Post(
          "tenant_document_files",
          formData,
          (data) => {
            getFiles();
            setSpin(false);
          },
          () => {
            setSpin(false);
          }
        );
      } else {
        formData.append("tenant_document", "");
        var subDatas = tenantDocument.subDatas;
        if (!subDatas) {
          subDatas = [];
        }
        files.push({
          name: e.target.files[0].name,
          onDelete: (fileName) => {
            var newFiles = files.filter((file) => {
              return file.name !== fileName;
            });
            setFiles(newFiles);
            var newSubDatas = subDatas.filter((subData) => {
              return subData.data.get("name") !== fileName;
            });
            setTenantDocument({
              ...tenantDocument,
              subDatas: newSubDatas,
            });
          },
        });
        setFiles(files);
        subDatas.push({
          url: "tenant_document_files",
          data: formData,
          id: "tenant_document",
        });
        setTenantDocument({
          ...tenantDocument,
          subDatas: subDatas,
        });
        setSpin(false);
      }
    }
  };

  const onChange = (e) => {
    if (e.target.name === "version") ckeditor.setData(e.target.value);

    if (e.target.name === "related_documents")
      setTenantDocument({
        ...tenantDocument,
        related_documents: tenantDocument.related_documents
          ? tenantDocument.related_documents + "," + e.target.value
          : e.target.value,
      });
    else
      setTenantDocument({
        ...tenantDocument,
        [e.target.name]: e.target.value == -1 ? null : e.target.value,
      });
    setDocumentError({
      ...documentError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const deleteRelatedDocument = (id) => {
    let related_documents = tenantDocument.related_documents;
    related_documents = related_documents
      .replaceAll(id + ",", "")
      .replaceAll("," + id, "")
      .replaceAll(id, "");
    setTenantDocument({
      ...tenantDocument,
      related_documents: related_documents,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    ReactHtmlParser(data, { transform });
    const unique_tag_ids = Array.from(new Set(tagusers.map((x) => x.id))).map(
      (id) => {
        return { id: id };
      }
    );

    var replacedContent = data;
    replacedContent = replacedContent
      .replaceAll('style="text-align:right;"', "style-text-align-right")
      .replaceAll('style="text-align:center;"', "style-text-align-center")
      .replaceAll('style="text-align:left;"', "");

    const documentData = {
      ...tenantDocument,
      content: replacedContent,
      tags: unique_tag_ids,
    };

    setIsSubmitted(true);

    if (objectId) {
      http.Put(
        `tenant_documents/${objectId}`,
        documentData,
        (tenantDocument) => {
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setDocumentError(error);
          setIsSubmitted(false);
        }
      );
    } else {
      http.Post(
        `tenant_documents`,
        documentData,
        (tenantDocument) => {
          if (documentData.subDatas) {
            documentData.subDatas.map((subData) => {
              if (subData.data instanceof FormData) {
                subData.data.append(subData.id, tenantDocument.id);
              } else {
                subData.data[subData.id] = tenantDocument.id;
              }
              http.Post(
                subData.url,
                subData.data,
                (data) => {
                  console.log(data);
                },
                (status, error) => {
                  console.log(status);
                  console.log(error);
                }
              );
            });
          }
          props.handleObjectSubmit();
        },
        (status, error) => {
          if (status === 400) setDocumentError(error);
          setIsSubmitted(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      {filePath && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={true}
          onClose={() => {
            setFilePath(null);
          }}
        >
          <FileViewer
            fileType={filePath.fileType.toLowerCase()}
            filePath={filePath.filePath}
            onError={() => {
              setFilePath(null);
            }}
          />
        </Dialog>
      )}
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={10}>
            <Typography
              variant="h6"
              component="h6"
              classes={{ h6: classes.title }}
            >
              {objectId ? "Edit Document" : "Add New Document"}
            </Typography>
          </Grid>
          <Grid item xs={2} className={classes.gridItem}>
            <IconButton
              className={classes.iconButton}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <ValidatorForm onSubmit={onSubmit}>
        <div className={classes.content}>
          {loadData ? (
            <ScreenLoader />
          ) : (
            <div>
              <p className={classes.label}>TITLE *</p>
              <TextValidator
                id="title"
                fullWidth
                variant="outlined"
                margin="dense"
                name="title"
                value={tenantDocument.title || ""}
                onChange={onChange}
                inputProps={{ maxLength: 300 }}
                validators={["required", "trim"]}
                errorMessages={[requiredError, blankError]}
                error={Boolean(documentError.title)}
                helperText={documentError.title && documentError.title[0]}
              />

              <p className={classes.label}>DOCUMENT TYPE</p>
              <TextValidator
                id="category"
                fullWidth
                select
                variant="outlined"
                margin="dense"
                name="category"
                value={tenantDocument.category || ""}
                onChange={onChange}
              >
                {categories && categories.length > 0 && (
                  <MenuItem key={-1} value={""}>
                    NONE
                  </MenuItem>
                )}
                {categories && categories.length === 0 ? (
                  <MenuItem value={""} selected disabled>
                    No document types found
                  </MenuItem>
                ) : (
                  categories.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.category_name}
                    </MenuItem>
                  ))
                )}
              </TextValidator>

              <p className={classes.label}>CONTENT</p>
              <div className={classes.editor}>
                <textarea id="ckeditor" name="data" />
              </div>
              <div>
                {/*
								 // ------ Here ------ \\ 
										- Need component from ObjectForm
								*/}
                <p className={classes.label}>FILES</p>
                <Input
                  id="files"
                  type="file"
                  name="files"
                  startAdornment={
                    <InputLabel for="files" className={classes.w100}>
                      Click to upload...
                    </InputLabel>
                  }
									endAdornment={spin && spin ? <MiniSpinLoader /> : ""}
                  className={classes.fileUpload}
                  // className={
                  //   errors[field.id]
                  //     ? `${classes.fileUpload} ${classes.error}`
                  //     : classes.fileUpload
                  // }
                  aria-describedby="my-upload"
                  inputProps={{ style: { visibility: "hidden" } }}
                  onChange={uploadFile}
                  // onChange={(e) => {
                  //   field.onChange
                  //     ? field.onChange(e, object, setObject, setErrors, setSpin)
                  //     : onChange(e);
                  // }}
                />
                {files.map((file) => {
                  if (file.file)
                    return (
                      <p key={file.id}>
                        <a className={classes.link}
                          href="#"
                          onClick={() => {
                            if (file.file_src) {
                              setFilePath({
                                filePath: file.file_src,
                                fileType: file.file
                                  .split("?")[0]
                                  .split(".")
                                  .pop(),
                              });
                            } else {
                              alert("File Not Found!");
                            }
                          }}
                        >
                          {file.name}
                        </a>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <a
                          href="#"
                          onClick={() => {
                            file.deleteFile(file.id);
                          }}
                        >
                          <CancelIcon />
                        </a>
                      </p>
                    );
                  else
                    return (
                      <p key={file.id}>
                        {file.name}
                        <Button
                          onClick={() => {
                            file.onDelete(file.name);
                          }}
                        >
                          <CancelIcon />
                        </Button>
                      </p>
                    );
                })}
              </div>
              <div>
                <p className={classes.label}>RELATED DOCUMENTS</p>
                <SelectValidator
                  id="related_documents"
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  name="related_documents"
                  value={tenantDocument.related_documents || ""}
                  onChange={onChange}
                  onClick={(e, value) => {
                    setRelatedDocument(value);
                  }}
                >
                  {Object.entries(documents).map(([key, value]) => {
                    if (
                      !tenantDocument.related_documents ||
                      !tenantDocument.related_documents.includes(key)
                    ) {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    }
                  })}
                </SelectValidator>
                {tenantDocument.related_documents &&
                  tenantDocument.related_documents.split(",").map((value) => {
                    return (
                      documents[value] && (
                        <div>
                          <span style={{ marginRight: 5 }}>
                            <a
                              href={"#"}
                              onClick={(e) => {
                                setRelatedDocument(value);
                              }}
                            >
                              {documents[value]}
                            </a>
                          </span>
                          <span>
                            <a
                              href="#"
                              onClick={(e) => {
                                deleteRelatedDocument(value);
                              }}
                            >
                              <CancelIcon style={{ fontSize: 16 }} />
                            </a>
                          </span>
                        </div>
                      )
                    );
                  })}
              </div>
              {availableCompanies && (
                <div>
                  <p className={classes.label}>TAG COMPANY</p>
                  <Select
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value=""
                    onChange={(e) => {
                      if (objectId) {
                        http.Post(
                          "document_company_mappings",
                          { company: e.target.value, document: objectId },
                          (data) => {
                            getCompanyMappings();
                          },
                          (status, error) => {}
                        );
                      } else {
                        var subDatas = tenantDocument.subDatas;
                        if (!subDatas) {
                          subDatas = [];
                        }
                        subDatas.push({
                          url: "document_company_mappings",
                          data: { company: e.target.value },
                          id: "document",
                        });
                        setTenantDocument({
                          ...tenantDocument,
                          subDatas: subDatas,
                        });

                        var data = localStorage.getItem(
                          "companysForTenantDocument"
                        );
                        if (!data) {
                          data = "";
                        }
                        localStorage.setItem(
                          "companysForTenantDocument",
                          data + e.target.value + ","
                        );
                        getCompanyMappings();
                      }
                    }}
                  >
                    {Object.entries(availableCompanies).map(([key, value]) => {
                      return (
                        <MenuItem key={key} value={key}>
                          {value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {companyMappings &&
                    companyMappings.map((value) => {
                      return (
                        <p>
                          {" "}
                          {value["label"]}
                          <Button
                            onClick={(e) => {
                              value.onDelete(e);
                            }}
                          >
                            <CancelIcon />
                          </Button>
                        </p>
                      );
                    })}
                </div>
              )}
              

              {relatedDocument && (
                <Dialog
                  open={true}
                  onClose={() => {
                    setRelatedDocument(null);
                  }}
                >
                  <div
                    style={{
                      padding: "20px",
                      minWidth: "600px",
                      minHeight: "400px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: documentContents[relatedDocument],
                    }}
                  />
                </Dialog>
              )}
            </div>
          )}
        </div>

        <Divider />

        <div className={classes.footer}>
          <div className={classes.outlinedButtonContainer}>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.outlinedButton}
              onClick={() => props.closeOpen(false)}
            >
              Cancel
            </Button>
            <Button
              margin="dense"
              size="small"
              className={classes.filledButton}
              type="submit"
              disabled={isSubmitted}
            >
              Save
            </Button>
          </div>
        </div>
      </ValidatorForm>
    </div>
  );
};

export default DocumentForm;
