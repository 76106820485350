import ContactPhoneIcon from "@material-ui/icons/ContactPhoneOutlined";
import PeopleAltIcon from "@material-ui/icons/PeopleAltOutlined";
import StorageIcon from "@material-ui/icons/StorageOutlined";
import DescriptionIcon from "@material-ui/icons/DescriptionOutlined";
import BusinessIcon from "@material-ui/icons/BusinessOutlined";
import CardMembershipIcon from "@material-ui/icons/CardMembershipOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import HomeIcon from '@material-ui/icons/Home';
import ArchiveIcon from '@material-ui/icons/Archive';
import PaymentIcon from '@material-ui/icons/Payment';
import SyncIcon from '@material-ui/icons/Sync';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import PhotoIcon from '@material-ui/icons/Photo';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import WorkIcon from '@material-ui/icons/Work';
import SettingsIcon from '@material-ui/icons/Settings';
import StorefrontIcon from "@material-ui/icons/Storefront";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ShareIcon from '@material-ui/icons/Share';

export const menus = {
  companies: [
    {
      id: 1,
      name: "Company Details",
      link: process.env.PUBLIC_URL + "/company/id",
      icon: HomeIcon,
      permissions: ["View Data"],
    },
    {
      id: 2,
      name: "Sites",
      link: process.env.PUBLIC_URL + "/company/id/sites",
      icon: BusinessIcon,
      permissions: ["View Data"],
    },
    {
      id: 3,
      name: "Documents",
      link: process.env.PUBLIC_URL + "/company/id/documents",
      icon: DescriptionIcon,
      permissions: ["View Data"],
    },
    {
      id: 4,
      name: "Employees",
      link: process.env.PUBLIC_URL + "/company/id/employees",
      icon: PeopleAltIcon,
      permissions: ["View Data"],
    },
    {
      id: 5,
      name: "Servers & Devices",
      link: process.env.PUBLIC_URL + "/company/id/servers_devices",
      icon: StorageIcon,
      permissions: ["View Data"],
    },
    {
      id: 6,
      name: "Software Licenses",
      link: process.env.PUBLIC_URL + "/company/id/software_licenses",
      icon: CardMembershipIcon,
      permissions: ["View Data"],
    },
    {
      id: 7,
      name: "Other Services",
      link: process.env.PUBLIC_URL + "/company/id/other_services",
      icon: ContactPhoneIcon,
      permissions: ["View Data"],
    },
    {
      id: 8,
      name: "Domains",
      link: process.env.PUBLIC_URL + "/company/id/domains",
      icon: LanguageIcon,
      permissions: ["View Data"],
    },
    {
      id: 9,
      name: "Archives",
      link: process.env.PUBLIC_URL + "/company/id/archives",
      icon: ArchiveIcon,
      permissions: ["Company Archive Access"],
    },
    {
      id: 10,
      name: "Import Vault",
      link: process.env.PUBLIC_URL + "/company/id/configurations",
      icon: ImportExportIcon,
      permissions: ["View Data"],
    },
    {
      id: 11,
      name: "Gallery",
      link: process.env.PUBLIC_URL + "/company/id/files",
      icon: PhotoIcon,
      permissions: ["View Data"],
    },
    {
      id: 12,
      name: "Password Vault",
      link: process.env.PUBLIC_URL + "/company/id/passwords",
      icon: VpnKeyIcon,
      permissions: ["View Data"],
    },
  ],
  settings: [
    {
      id: 1,
      name: "Company Details",
      icon: BusinessIcon,
      items: [
        {
          id: 1,
          name: "General",
          link: process.env.PUBLIC_URL + "/settings/profile",
        },
        {
          id: 2,
          name: "Addresses",
          link: process.env.PUBLIC_URL + "/settings/address",
        },
      ],
      permissions: [],
    },
    {
      id: 2,
      name: "Billing",
      icon: PaymentIcon,
      items: [
        {
          id: 1,
          name: "Invoices",
          link: process.env.PUBLIC_URL + "/settings/invoices",
        },
        {
          id: 2,
          name: "Subscription",
          link: process.env.PUBLIC_URL + "/settings/subscription",
        },
      ],
      permissions: [],
    },
    {
      id: 3,
      name: "Users and Control",
      icon: PeopleAltIcon,
      items: [
        {
          id: 1,
          name: "Users",
          link: process.env.PUBLIC_URL + "/settings/users",
        },
        {
          id: 2,
          name: "Security Control",
          link: process.env.PUBLIC_URL + "/settings/user/roles",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 4,
      name: "Sharing",
      icon: ShareIcon,
      items: [
        {
          id: 1,
          name: "To Tenants",
          link: process.env.PUBLIC_URL + "/settings/to_tenants",
        },
        {
          id: 2,
          name: "From Tenants",
          link: process.env.PUBLIC_URL + "/settings/from_tenants",
        },
        {
          id: 3,
          name: "Shared Companies",
          link: process.env.PUBLIC_URL + "/settings/sharings",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 5,
      name: "Customization",
      icon: DescriptionIcon,
      items: [
        {
          id: 1,
          name: "Categories",
          link: process.env.PUBLIC_URL + "/settings/categories",
        },
        {
          id: 2,
          name: "Templates",
          link: process.env.PUBLIC_URL + "/settings/templates",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 6,
      name: "Developer Space",
      icon: StorageIcon,
      items: [
        {
          id: 1,
          name: "Extensions",
          link: process.env.PUBLIC_URL + "/settings/extensions",
        },
        {
          id: 2,
          name: "Customized Data",
          link: process.env.PUBLIC_URL + "/settings/json_schemas",
        },
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 7,
      name: "Data Administration",
      icon: SyncIcon,
      items: [
        {
          id: 1,
          name: "AutoTask Sync",
          link: process.env.PUBLIC_URL + "/settings/syncAutoTask",
          integration: "autotask"
        },
        {
          id: 2,
          name: "ConnectWise Sync",
          link: process.env.PUBLIC_URL + "/settings/sync",
          integration: "connect_wise"
        },
        {
          id: 3,
          name: "Ninja Sync",
          link: process.env.PUBLIC_URL + "/settings/syncNinja",
          integration: "ninja"
        },
        {
          id: 4,
          name: "Syncro Sync",
          link: process.env.PUBLIC_URL + "/settings/syncSyncro",
          integration: "syncro"
        },
        {
          id: 5,
          name: "Merge Company",
          link: process.env.PUBLIC_URL + "/settings/merges",
          integration: "has_integration"
        }
      ],
      permissions: ["Manage Users"],
    },
    {
      id: 8,
      name: "Portal Management",
      icon: PeopleAltIcon,
      items: [
        {
          id: 1,
          name: "Users",
          link: process.env.PUBLIC_URL + "/settings/portal-users",
        },
        {
          id: 2,
          name: "Security Control",
          link: process.env.PUBLIC_URL + "/settings/portal-user/roles",
        },
      ],
      permissions: ["Manage Portal Users"],
    },
    {
      id: 9,
      name: "Archives",
      link: process.env.PUBLIC_URL + "/settings/archives",
      icon: ArchiveIcon,
      permissions: ["Manage Users"],
    },
  ],
  tenants: [
    {
      id: 1,
      name: "Companies",
      link: process.env.PUBLIC_URL + "/company",
      icon: BusinessIcon,
      permissions: [],
    },
    {
      id: 2,
      name: "Resources",
      link: process.env.PUBLIC_URL + "/documents",
      icon: DescriptionIcon,
      permissions: [],
    },
    {
      id: 3,
      name: "Services",
      link: process.env.PUBLIC_URL + "/services",
      icon: ContactPhoneIcon,
      permissions: [],
    },
    {
      id: 4,
      name: "Vendors",
      link: process.env.PUBLIC_URL + "/support",
      icon: StorefrontIcon,
      permissions: [],
    },
    {
      id: 5,
      name: "Meetings",
      link: process.env.PUBLIC_URL + "/meeting",
      icon: MeetingRoomIcon,
      permissions: [],
    },
    {
      id: 6,
      name: "Accounting",
      link: process.env.PUBLIC_URL + "/accounting",
      icon: ReceiptIcon,
      permissions: [],
    },
  ],
  hr: [
    {
      id: 1,
      name: "Dashboard",
      link: process.env.PUBLIC_URL + "/hr",
      icon: HomeIcon,
      permissions: ["HR"],
    },
    {
      id: 2,
      name: "Employees",
      link: process.env.PUBLIC_URL + "/hr/employees",
      icon: PeopleAltIcon,
      permissions: ["View Employee Profile"],
    },
    {
      id: 3,
      name: "Job Postings",
      link: process.env.PUBLIC_URL + "/hr/jobPostings",
      icon: WorkIcon,
      permissions: ["View Job Postings"],
    },
    {
      id: 4,
      name: "Reports",
      link: process.env.PUBLIC_URL + "/hr/reports",
      icon: DescriptionIcon,
      permissions: ["Report Access"],
    },
    {
      id: 5,
      name: "Settings",
      icon: SettingsIcon,
      items: [
        {
          id: 1,
          name: "General",
          link: process.env.PUBLIC_URL + "/hr/settings/general",
        },
        {
          id: 2,
          name: "Vacation Profiles",
          link: process.env.PUBLIC_URL + "/hr/settings/vacation-profiles",
        },
        {
          id: 3,
          name: "Job Templates",
          link: process.env.PUBLIC_URL + "/hr/settings/jobPostingTemplates",
          permissions: ["View Job Postings"],
        },
      ],
      permissions: ["Settings Access"],
    },
  ],
  blank: [],
};

