import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Logo from "../../Images/logo.png";
import useStyles from "./style";
import http from "../Common/RestAPIHandler";
import auth from "../../services/authService";
import { setUser } from "../../redux-tools/actions/userActions";
import { connect } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Alert from "@material-ui/lab/Alert";
import commonConstants from "../Common/CommonConstants";

const PasswordLogin = (props) => {
  const user_email = localStorage.getItem("user_email");
  const classes = useStyles();
  const [user, setUser] = useState({"email":user_email});
  const [invalid, setInvalid] = useState(false);
  const [locked, setLocked] = useState(false);
  const errorText = commonConstants.error;
  const [lockoutTime, setLockoutTime] = useState("");
  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidTenant, setInvalidTenant] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const resetAll = () => {
    setInvalid(false);
    setLocked(false);
    setInvalidUser(false);
    setInvalidPassword(false);
    setInvalidTenant(false);
    setIsSubmitted(false);
  };

  const onInputChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    resetAll();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetAll();
    setIsSubmitted(true);
    http.Post(
      "login",
      user,
      (response) => {
        if(response.redirect){
          return props.history.replace(process.env.PUBLIC_URL + response.redirect);
        }
        auth.saveToken(response.access_token);
        if (response.theme == "Light") {
          localStorage.setItem("isDarkEnabled", false);
        } else {
          localStorage.setItem("isDarkEnabled", true);
        }
        localStorage.setItem(
          "colorScheme",
          JSON.stringify(response.colour_scheme || "Orange")
        );
        localStorage.setItem("date_format", response.date_format);
        localStorage.setItem("date_time_format", response.date_time_format);
        localStorage.setItem("notification_days", response.notification_days);
        if(response.authy_verified){
          props.setUser(response);
          props.history.replace(process.env.PUBLIC_URL + "/dashboard");
        }
        else{
          props.setUser(response);
          props.history.replace(process.env.PUBLIC_URL + "/twoFA");
        }
      },
      (status, error) => {
        if (status === 404) {
          setInvalidUser(true);
        } else if (status === 400) {
          if (error.invalid_email_password || error.invalid_tenant) {
            http.Post(
              "user_session/lock/?user_type=Tenant User",
              user,
              () => {},
              () => {}
            );
            error.invalid_email_password && setInvalidPassword(true);
            error.invalid_tenant && setInvalidTenant(true);
          } else {
            setInvalid(true);
          }
        } else if (status === 423) {
          let date = new Date(error.lockout_to);
          let now = new Date();
          setLockoutTime(Math.floor(((date-now)/1000)/60));
          setLocked(true);
        } else {
          setInvalid(true);
        }
        setIsSubmitted(false);
      }
    );
  };

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <ValidatorForm onSubmit={onSubmit}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h6" color="inherit" noWrap>
                    <img
                      src={Logo}
                      alt="Not Available"
                      className={classes.logo}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h5"
                    color="textSecondary"
                    classes={{ h4: classes.title }}
                  >
                    Login
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    id="outlined-email-input"
                    label="E-mail"
                    type="text"
                    name="email"
                    variant="outlined"
                    fullWidth
                    value={user.email || ""}
                    onChange={onInputChange}
                    validators={["required", "trim", "isEmail"]}
                    errorMessages={[
                      errorText.requiredError,
                      errorText.blankError,
                      errorText.invalidEmailError,
                    ]}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    id="outlined-password-input"
                    label="Password"
                    type="password"
                    name="password"
                    variant="outlined"
                    className={classes.passwordField}
                    fullWidth
                    inputProps={{ style: { fontWeight: "bold" } }}
                    value={user.password || ""}
                    onChange={onInputChange}
                    validators={["required"]}
                    errorMessages={[errorText.requiredError]}
                  />
                </Grid>

                {(invalidTenant || invalidPassword || invalidUser) && (
                  <Grid item xs={12}>
                    <Alert severity="error" variant="filled">
                      Username or password incorrect!
                    </Alert>
                  </Grid>
                )}

                {locked && (
                  <Grid item xs={12}>
                    <Alert severity="error" variant="filled">
                      Your account is temporarily locked for {lockoutTime} minutes due to multiple failed login attempts.
                    </Alert>
                  </Grid>
                )}

                {invalid && (
                  <Grid item xs={12}>
                    <Alert severity="error" variant="filled">
                      An error has occurred, please try again later.
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Link
                        component={RouterLink}
                        to={process.env.PUBLIC_URL + "/forgetPassword?userType=Tenant User"}
                        color="textPrimary"
                        className={classes.link}
                      >
                        Forgot password?
                      </Link>
                    </Grid>
                    <Grid item xs={6} className={classes.rightElement}>
                      <Button
                        type="submit"
                        className={classes.button}
                        variant="outlined"
                        disabled={isSubmitted}
                      >
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  );
};
export default connect(null, { setUser })(PasswordLogin);
