
import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";

const DomainForm = props => {
  const { id } = props.match.params;
  const [formFields, setFormFields] = useState();

  useEffect(() => {    
    setFormFields([
      { 
        id: "domain_name", 
        label: "DOMAIN NAME *", 
        type: "text",
        onBlur: (e, object, setObject, setErrors) => {
          setObject({
            ...object,
            [e.target.name]: e.target.value
          });
          http.Get(
            `domain_lookup?domain_name=${e.target.value}`,
            data => {
              if(data['WHOIS'] && data['WHOIS']['domain_name']){
                  var newObject = {'company':id};
                if(Array.isArray(data['WHOIS']['domain_name'])){
                    newObject['domain_name'] = data['WHOIS']['domain_name'][1]
                }else{
                    newObject['domain_name'] = data['WHOIS']['domain_name']
                }
                if(Array.isArray(data['WHOIS']['registrar'])){
                    newObject['registrar'] = data['WHOIS']['registrar'][0]
                }else{
                    newObject['registrar'] = data['WHOIS']['registrar']
                }
                try{
                  if(Array.isArray(data['WHOIS']['expiration_date'])){
                    newObject['expiration_date'] = data['WHOIS']['expiration_date'][0].split("T")[0]
                  }else{
                    newObject['expiration_date'] = data['WHOIS']['expiration_date'].split("T")[0]
                  }
                }catch(error){ 
                  console.log(error)  
                }
                try{
                  if(Array.isArray(data['WHOIS']['creation_date'])){
                    newObject['creation_date'] = data['WHOIS']['creation_date'][0].split("T")[0]
                  }else{
                    newObject['creation_date'] = data['WHOIS']['creation_date'].split("T")[0]
                  }
                }catch(error){ 
                  console.log(error)  
                }               
                try{
                  if(Array.isArray(data['WHOIS']['updated_date'])){
                    newObject['last_updated'] = data['WHOIS']['updated_date'][0].split("T")[0]
                  }else{
                    newObject['last_updated'] = data['WHOIS']['updated_date'].split("T")[0]
                  }
                }catch(error){ 
                  console.log(error)  
                }
                if(Array.isArray(data['DNS'])){
                    newObject['dns'] = data['DNS'][0]
                }else{
                    newObject['dns'] = data['DNS']
                }
                if(data['ssl_expiration_date']){
                    newObject['ssl_expiration_date'] = data['ssl_expiration_date'].split("T")[0]
                }
                setObject(newObject);
              }
            },
            () => {}
          );
        }
      },
      { 
        id: "registrar", 
        label: "REGISTRAR", 
        type: "text", 
      },
      { 
        id: "expiration_date", 
        label: "EXPIRATION DATE", 
        type: "date", 
      },
      { 
        id: "last_updated", 
        label: "LAST UPDATED", 
        type: "date", 
      },
      { 
        id: "creation_date", 
        label: "CREATION DATE", 
        type: "date", 
      },
      { 
        id: "dns", 
        label: "DNS", 
        type: "textarea", 
      },
      { 
        id: "ssl_expiration_date", 
        label: "SSL EXPIRATION DATE", 
        type: "date", 
      },
      {
        id: "dataMappings",
        label: "RELATED ENTRIES",
        type: "dataMapping",
        model: "Document",
        objectId: props.objectId,
      },
    ]);

    }, []);


  return(
    <div>{formFields && <ObjectForm {...props} url={`company_domains`} formName={"Domain"} formFields={formFields}  object={{company:id}}/>}</div>
  );
};

export default DomainForm;
