import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Typography from "@material-ui/core/Typography";
import MyComponent from "../../Common/RichTextEditor";
import {
  MuiPickersUtilsProvider,
  DatePicker,
  TimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ConvertDateToLocale } from "../../Common/Utility";
import http from "../../Common/RestAPIHandler";
import useStyles from "../../Common/Style/FormStyle";
import commonConstraints from "../../Common/CommonConstants";
import ClassicEditor from "@ckeditor-linkpoint/ckeditor5-build-classic";
import CKEditor from "@ckeditor/ckeditor5-react";
import ReactHtmlParser from "react-html-parser";
import ScreenLoader from "../../Common/Loader";
import FileViewer from "../../Common/FileViewer";
import { Dialog } from "@material-ui/core";

const MeetingForm = (props) => {
  const classes = useStyles();
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [isInvalidDate, setIsInvalidDate] = useState(false);
  const [tenantusers, setTenantUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [guests, setGuests] = useState();
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState();
  const [loadData, setLoadData] = useState(false);
  const [meeting, setMeeting] = useState({
    title: "",
    start_time: new Date(),
    end_time: new Date(),
    location: "",
    creator: props.user.id,
    tenant: props.user.tenant,
    users: [],
  });
  const [meetingError, setMeetingError] = useState({});
  const { requiredError, blankError } = commonConstraints.error;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [content, setContent] = useState(props.template.data || "");
  const [filePath, setFilePath] = useState();
  var tagusers = [];

  const [files, setFiles] = useState();
  const getFiles = () => {
    if (props.objectId) {
      http.Get(
        `meeting_files?meeting=${props.objectId}`,
        (data) => {
          var fileList = [];
          for (let i = 0; i < data.length; i++) {
            data[i]["onDelete"] = (fileId) => {
              http.Delete(
                `meeting_files/${fileId}`,
                {},
                (data) => {
                  getFiles();
                },
                () => {}
              );
            };
            fileList.push(data[i]);
          }
          setFiles(fileList);
        },
        () => {}
      );
    } else {
      setFiles([]);
    }
  };

  useEffect(() => {
    if (props.edit && props.objectId) {
      setLoadData(true);
      http.Get(
        `meetings/${props.objectId}`,
        (data) => {
          var content = data.content;
          if (!content) content = "";
          delete data.content;
          setMeeting(data);
          setStartTime(ConvertDateToLocale(data.start_time));
          setEndTime(ConvertDateToLocale(data.end_time));
          setContent(content);

          http.Get(
            "tenant_users",
            (usersData) => {
              const items = usersData.filter(
                (item) => item.id !== data.creator
              );
              const guestUsers = items.filter((u) =>
                data.users.some((g) => u.id === g.id)
              );
              const tenantUsers = usersData.map((item) => {
                const obj = {};
                Object.keys(item).forEach((key) => {
                  obj[key] = item[key];
                });
                obj.user_id = item.id;
                obj.name = item.first_name + " " + item.last_name;
                obj.username = "@" + item.email.split("@")[0];
                obj.id = obj.username;
                return obj;
              });
              setTenantUsers(tenantUsers);
              setUsers(items);
              setGuests(guestUsers);
            },
            () => {}
          );

          http.Get(
            "roles",
            (rolesData) => {
              setRoles(rolesData);
              const selectedRoles = rolesData.filter((r) =>
                data.roles.some((g) => r.id === g.id)
              );
              setSelectedRoles(selectedRoles);
            },
            () => {}
          );

          setLoadData(false);
        },
        () => {
          setLoadData(false);
        }
      );
    } else {
      http.Get(
        "tenant_users",
        (data) => {
          const items = data.filter((item) => item.id !== props.user.id);
          const tenantUsers = data.map((item) => {
            const obj = {};
            Object.keys(item).forEach((key) => {
              obj[key] = item[key];
            });
            obj.user_id = item.id;
            obj.name = item.first_name + " " + item.last_name;
            obj.username = "@" + item.email.split("@")[0];
            obj.id = obj.username;
            return obj;
          });
          setTenantUsers(tenantUsers);
          setUsers(items);
          setGuests([]);
        },
        () => {}
      );

      http.Get(
        "roles",
        (rolesData) => {
          setRoles(rolesData);
          http.Get(
            "user_roles?user_id=" + props.user.id,
            (userRoles) => {
              const selectedRoles = rolesData.filter((r) =>
                userRoles.some((g) => r.id === g.role_id)
              );
              setMeeting({
                ...meeting,
                roles: selectedRoles,
              });
              setSelectedRoles(selectedRoles);
            },
            () => {}
          );
        },
        () => {}
      );
    }

    getFiles();
  }, []);

  const handleChange = (prop) => (event) => {
    setIsInvalidDate(false);
    setMeeting({ ...meeting, [prop]: event.target.value });
    setMeetingError({ ...meetingError, [prop]: false });
    setIsSubmitted(false);
  };

  const editorConfiguration = {
    extraPlugins: [MentionCustomization],
    mention: {
      feeds: [
        {
          marker: "@",
          feed: getFeedItems,
          itemRenderer: customItemRenderer,
        },
      ],
    },
  };

  const onEditorInit = (editor) => {
    editor.editing.view.change((writer) => {
      writer.setStyle(
        "height",
        "calc(100vh/4)",
        editor.editing.view.document.getRoot()
      );
    });
  };

  const onEditorChange = (event, editor) => {
    setContent(editor.getData());
  };

  function MentionCustomization(editor) {
    editor.conversion.for("upcast").elementToAttribute({
      view: {
        name: "a",
        key: "data-mention",
        classes: "mention",
        disabled: true,
        attributes: {
          "data-user-id": true,
          "data-user-email": true,
          href: "#",
        },
      },
      model: {
        key: "mention",
        value: (viewItem) => {
          const mentionAttribute = editor.plugins
            .get("Mention")
            .toMentionAttribute(viewItem, {
              user_id: viewItem.getAttribute("data-user-id"),
              email: viewItem.getAttribute("data-user-email"),
            });

          return mentionAttribute;
        },
      },
      converterPriority: "high",
    });

    editor.conversion.for("downcast").attributeToElement({
      model: "mention",
      view: (modelAttributeValue, viewWriter) => {
        if (!modelAttributeValue) {
          return;
        }

        return viewWriter.createAttributeElement("a", {
          class: "mention",
          "data-mention": modelAttributeValue.username,
          "data-user-id": modelAttributeValue.user_id,
          "data-user-email": modelAttributeValue.email,
          href: "#",
        });
      },
      converterPriority: "high",
    });
  }

  function getFeedItems(queryText) {
    return new Promise((resolve) => {
      setTimeout(() => {
        const itemsToDisplay = tenantusers.filter(isItemMatching).slice(0, 5);
        resolve(itemsToDisplay);
      }, 100);
    });

    function isItemMatching(item) {
      var searchString = queryText ? queryText.toLowerCase() : "";
      return (
        item.email.toLowerCase().includes(searchString) ||
        item.id.toLowerCase().includes(searchString) ||
        item.name.toLowerCase().includes(searchString)
      );
    }
  }

  function customItemRenderer(item) {
    const itemElement = document.createElement("div");
    const avatarElememt = document.createElement("span");
    const avatar = document.createElement("img");
    const profileElement = document.createElement("span");
    const fullNameElement = document.createElement("span");
    const userNameElement = document.createElement("span");

    itemElement.classList.add(classes.mentionItem);
    avatarElememt.classList.add(classes.mentionItemAvatar);
    avatar.classList.add(classes.mentionAvatar);

    if (item.profile_image_src) {
      avatar.src = item.profile_image_src;
    } else {
      avatar.src = "";
    }

    profileElement.classList.add(classes.mentionItemProfile);

    userNameElement.classList.add(classes.mentionItemUsername);
    userNameElement.textContent = item.username;

    fullNameElement.classList.add(classes.mentionItemFullname);
    fullNameElement.textContent = item.name;

    avatarElememt.appendChild(avatar);
    itemElement.appendChild(avatarElememt);
    profileElement.appendChild(fullNameElement);
    profileElement.appendChild(userNameElement);
    itemElement.appendChild(profileElement);

    return itemElement;
  }

  function transform(node, index) {
    if (
      node.type === "tag" &&
      node.name === "a" &&
      node.attribs.class === "mention"
    ) {
      const user_id = node.attribs["data-user-id"];
      tagusers.push({ id: user_id });
    }
  }

  const onSubmit = (e) => {
    // setLoadData(true);
    e.preventDefault();

    setIsInvalidDate(false);
    if (startTime >= endTime) {
      setIsInvalidDate(true);
      return;
    }
    setIsInvalidDate(false);

    ReactHtmlParser(content, { transform });
    const unique_tag_ids = Array.from(new Set(tagusers.map((x) => x.id))).map(
      (id) => {
        return { id: id };
      }
    );

    var replacedContent = content;
    replacedContent = replacedContent
      .replaceAll('style="text-align:right;"',"style-text-align-right")
      .replaceAll('style="text-align:center;"',"style-text-align-center")
      .replaceAll('style="text-align:left;"', "");

    var data = {
      ...meeting,
      start_time: startTime,
      end_time: endTime,
      users: guests,
      content: replacedContent,
      tags: unique_tag_ids,
    };
		console.log(data);
    setIsSubmitted(true);
    if (props.edit && props.objectId) {
      http.Put(
        `meetings/${props.objectId}`,
        data,
        (meeting) => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setMeetingError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    } else {
      http.Post(
        `meetings`,
        data,
        (meeting) => {
          props.handleObjectSubmit();
          setLoadData(false);
        },
        (status, error) => {
          if (status === 400) setMeetingError(error);
          setIsSubmitted(false);
          setLoadData(false);
        }
      );
    }
  };

  return (
    <div className={classes.list}>
      {filePath && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={true}
          onClose={() => {
            setFilePath(null);
          }}
        >
          <div style={{ padding: "20px" }}>
            {(filePath.fileType.toLowerCase() === "doc" ||
              filePath.fileType.toLowerCase() === "docx" ||
              filePath.fileType.toLowerCase() === "pdf") && (
              <FileViewer
                fileType={filePath.fileType.toLowerCase()}
                filePath={filePath.filePath}
                onError={() => {
                  setFilePath(null);
                }}
              />
            )}
            {filePath.fileType.toLowerCase() !== "doc" &&
              filePath.fileType.toLowerCase() !== "docx" &&
              filePath.fileType.toLowerCase() !== "pdf" && (
                <img src={filePath.filePath} style={{ maxWidth: "100%" }} />
              )}
          </div>
        </Dialog>
      )}
      <div className={classes.header}>
        <Grid container spacing={3} alignItems="center" justify="center">
          <Grid item xs={10}>
            <Typography variant="h6" classes={{ h6: classes.title }}>
              {props.edit ? "Edit Meeting" : "Add New Meeting"}
            </Typography>
          </Grid>

          <Grid item xs={2} className={classes.cross}>
            <IconButton
              style={{ backgroundColor: "transparent" }}
              onClick={() => props.closeOpen(false)}
            >
              <HighlightOffIcon className={classes.iconButtonColored} />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      <Divider />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <ValidatorForm onSubmit={onSubmit}>
          <div className={classes.content}>
            {loadData ? (
              <ScreenLoader />
            ) : (
              <div>
                <p className={classes.label}>TITLE *</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="name"
                    fullWidth
                    variant="outlined"
                    name="name"
                    value={meeting.name || ""}
                    onChange={handleChange("name")}
                    margin="dense"
                    validators={["required", "trim"]}
                    errorMessages={[requiredError, blankError]}
                    error={Boolean(meetingError.name)}
                    helperText={meetingError.name && meetingError.name[0]}
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>

                <p className={classes.label}>MEETING TIME</p>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={2} className={classes.title}>
                    Start
                  </Grid>
                  <Grid item xs={5}>
                    <DatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="MMM dd, yyyy"
                      fullWidth
                      color="inherit"
                      margin="dense"
                      value={startTime}
                      onChange={(value) => {
                        setStartTime(value);
                        setIsInvalidDate(false);
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TimePicker
                      autoOk
                      ampm
                      variant="inline"
                      inputVariant="outlined"
                      format="hh:mm a"
                      fullWidth
                      margin="dense"
                      value={startTime}
                      onChange={(value) => {
                        setStartTime(value);
                        setIsInvalidDate(false);
                      }}
                    />
                  </Grid>
                </Grid>

                <Grid container alignItems="center" spacing={1}>
                  <Grid item xs={2} className={classes.title}>
                    End
                  </Grid>
                  <Grid item xs={5}>
                    <DatePicker
                      autoOk
                      disableToolbar
                      variant="inline"
                      inputVariant="outlined"
                      format="MMM dd, yyyy"
                      fullWidth
                      margin="dense"
                      value={endTime}
                      onChange={(value) => {
                        setEndTime(value);
                        setIsInvalidDate(false);
                      }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TimePicker
                      autoOk
                      ampm
                      variant="inline"
                      inputVariant="outlined"
                      format="hh:mm a"
                      fullWidth
                      margin="dense"
                      value={endTime}
                      onChange={(value) => {
                        setEndTime(value);
                        setIsInvalidDate(false);
                      }}
                    />
                  </Grid>
                </Grid>

                {isInvalidDate && (
                  <Grid item container spacing={1}>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={9}>
                      <p style={{ color: "red" }}>Invalid Meeting time.</p>
                    </Grid>
                  </Grid>
                )}

                <p className={classes.label}>DESCRIPTION</p>
                <Grid item xs={12} className={classes.editor}>
                  {/* <CKEditor
                    editor={ClassicEditor}
                    data={content}
                    config={editorConfiguration}
                    onInit={onEditorInit}
                    onChange={onEditorChange}
                  /> */}
                    <MyComponent
                      {...props}
                      default = {content} 
                      onChange={(data)=>{setContent(data);}}/>
                </Grid>

                <p className={classes.label}>GUESTS</p>
                <Grid item xs={12}>
                  {guests && (
                    <Autocomplete
                      id="tags-outlined-guests"
                      multiple
                      filterSelectedOptions
                      classes={{
                        option: classes.option,
                        popper: classes.popper,
                      }}
                      options={users}
                      defaultValue={guests}
                      getOptionLabel={(option) =>
                        option.first_name + " " + option.last_name
                      }
                      renderOption={(option) => (
                        <React.Fragment>
                          <span>
                            {option.profile_image_src ? (
                              <Avatar
                                className={classes.smallAvatar}
                                alt={option.first_name}
                                src={option.profile_image_src}
                              />
                            ) : (
                              <AccountCircleIcon
                                fontSize="large"
                                color="action"
                              />
                            )}
                          </span>
                          <span>
                            <div style={{ fontWeight: 400 }}>
                              {option.first_name + " " + option.last_name}
                            </div>
                            <div style={{ fontWeight: 350, fontSize: 13 }}>
                              {option.email}
                            </div>
                          </span>
                        </React.Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          margin="dense"
                          placeholder={guests.length === 0 && "Emails"}
                        />
                      )}
                      onChange={(event, value) => {
                        setGuests(value);
                        setIsInvalidDate(false);
                      }}
                    />
                  )}
                </Grid>

                <p className={classes.label}>ROLES</p>
                <Grid item xs={12}>
                  {selectedRoles && (
                    <Autocomplete
                      id="tags-outlined-guests"
                      multiple
                      filterSelectedOptions
                      classes={{
                        option: classes.option,
                        popper: classes.popper,
                      }}
                      options={roles}
                      defaultValue={selectedRoles}
                      getOptionLabel={(option) => option.role_name}
                      renderOption={(option) => <span>{option.role_name}</span>}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          fullWidth
                          margin="dense"
                        />
                      )}
                      onChange={(event, value) => {
                        setMeeting({
                          ...meeting,
                          roles: value,
                        });
                        setIsInvalidDate(false);
                      }}
                    />
                  )}
                </Grid>

                <p className={classes.label}>LOCATION *</p>
                <Grid item xs={12}>
                  <TextValidator
                    id="location"
                    fullWidth
                    variant="outlined"
                    name="location"
                    margin="dense"
                    value={meeting.location || ""}
                    inputProps={{ maxLength: 25 }}
                    onChange={handleChange("location")}
                    validators={["required", "trim"]}
                    errorMessages={[requiredError, blankError]}
                    error={Boolean(meetingError.location)}
                    helperText={
                      meetingError.location && meetingError.location[0]
                    }
                  />
                </Grid>

                <p className={classes.label}>Files</p>
								 {/* // ----- Here ------ */}
                <Grid item xs={12}>
                  <input
                    className={classes.fileUpload}
                    type="file"
                    name="files"
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length === 1) {
                        var formData = new FormData();
                        formData.append("name", e.target.files[0].name);
                        formData.append(
                          "file",
                          e.target.files[0],
                          e.target.files[0].name
                        );
                        formData.append("meeting", props.objectId);
                        http.Post(
                          "meeting_files",
                          formData,
                          (data) => {
                            getFiles();
                          },
                          (status, error) => {}
                        );
                      }
                    }}
                  />
                  {files &&
                    files.map((file) => (
                      <p key={file.id}>
                        <a className={classes.link}
                          href="#"
                          onClick={() => {
                            if (file.file_src) {
                              setFilePath({
                                filePath: file.file_src,
                                fileType: file.file
                                  .split("?")[0]
                                  .split(".")
                                  .pop(),
                              });
                            } else {
                              alert("File Not Found!");
                            }
                          }}
                        >
                          {file.name}
                        </a>
                        <Button
                          onClick={() => {
                            file.onDelete(file.id);
                          }}
                        >
                          <CancelIcon />
                        </Button>
                      </p>
                    ))}
                </Grid>
              </div>
            )}
          </div>

          <Divider />

          <div className={classes.footer}>
            <div className={classes.outlinedButtonContainer}>
              <Button
                variant="outlined"
                margin="dense"
                size="small"
                color="primary"
                className={classes.outlinedButton}
                onClick={() => props.closeOpen(false)}
              >
                Cancel
              </Button>
              <Button
                variant="outlined"
                margin="dense"
                size="small"
                className={classes.filledButton}
                type="submit"
                disabled={isSubmitted}
              >
                Save
              </Button>
            </div>
          </div>
        </ValidatorForm>
      </MuiPickersUtilsProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.user,
  };
};

export default connect(mapStateToProps)(MeetingForm);
