import React, { useState, useEffect } from "react";
import DocumentForm from "./DocumentForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import { connect } from "react-redux";
import commonConstants from "../../Common/CommonConstants";
import { formatDateTime } from "../../Common/Utility";
import Grid from "@material-ui/core/Grid";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import LockIcon from "@material-ui/icons/Lock";
import { hasPermission } from "../../Common/permission";
import { Dialog, Button, Input } from "@material-ui/core";
import useStyles from "../../Common/Style/FormStyle";
import SnackbarComponent from "../../Common/Snackbar";
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import FolderIcon from '@material-ui/icons/Folder';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';

export var bulk_status = 0;
export function setBS(prop){
  bulk_status = prop;
}

const Documents = (props) => {
  const selectedSite = localStorage.getItem("Site");
  const classes = useStyles();
  const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("All");
  const [allData, setAllData] = useState([]);
  const [showData, setShowData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [categories, setCategories] = useState();
  const [site, setSite] = useState(selectedSite);
  const [multipleUpload, setMultipleUpload] = useState();
  const [files, setFiles] = useState();
  const [totalDocs, setTotalDocs] = useState(0);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [folders, setFolders] = useState([]);
  const [subFolders, setSubFolders] = useState([]);
  const [folder, setFolder] = useState("All");
  const [showFolders, setShowFolders] = useState(false);
  const [newFolder, setNewFolder] = useState();

  
  
  const url = `company_documents?company=${id}`;
  const actions = {
    delete:
      props.companyPermission && props.companyPermission === "readAndWrite",
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
    additionalActions: hasPermission(props, ["Lock Documents"])
      ? [
          {
            label: "Bulk Upload",
            onClick:
              (totalDocs < props.companyTenant.max_company_document||props.companyTenant.max_company_document===-1)
                ? () => {
                    setMultipleUpload(true);
                  }
                : () => {
                    setSnackBarOpen(true);
                  },
          },
          {
            label: "Lock",
            onClick: (ids) => {
              http.Put(
                `company_documents`,
                { ids: ids, is_locked: true },
                (data) => {
                  fetchData();
                },
                () => {}
              );
            },
          },
          {
            label: "Unlock",
            onClick: (ids) => {
              http.Put(
                `company_documents`,
                { ids: ids, is_locked: false },
                (data) => {
                  fetchData();
                },
                () => {}
              );
            },
          },
        ]
      : [
          {
            label: "Bulk Upload",
            onClick:
              (totalDocs < props.companyTenant.max_company_document||props.companyTenant.max_company_document===-1)
                ? () => {
                    setMultipleUpload(true);
                  }
                : () => {
                    setSnackBarOpen(true);
                  },
          },
        ],
  };

  const tableColumns = [
    {
      id: "icon",
      label: "",
      minWidth: 20,
      sortable: false,
      colored: true,
    },
    {
      id: "title",
      label: "TITLE",
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: props.mobile
        ? null
        : (props, document, column_id) => {
            if(document.id){
              props.history.push(
                process.env.PUBLIC_URL +
                  `/company/${id}/documents/${document.id}/details`
              );
            }
            else{
              let docFolder = folders.find((f)=>(f.id===document.folder));
              if(docFolder){
                setFolder(docFolder);
              }
              else{
                setFolder("All");
              }
            }
          },
    },
    {
      id: "download",
      label: "",
      minWidth: 10,
      colored: true,
      style: commonConstants.cellCursor,
    },
    {
      id: "site_name",
      label: "SITE",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "last_modified_time",
      label: "LAST MODIFIED",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "last_modified_by_name",
      label: "BY",
      minWidth: 150,
      sortable: true,
    },
  ];

  useEffect(() => {
    http.Get(
      `categories?category_type=Document Category`,
      (data) => {
        var valueMap = {};
        for (let i = 0; i < data.length; i++) {
          valueMap[data[i].id] = data[i].category_name;
        }
        setCategories(valueMap);
      },
      () => {}
    );

    http.Get(
      `company_document_folders/?company=${id}`,
      (foldersData) => {
        setFolders(foldersData);
      },
      () => {}
    );

  }, []);

  useEffect(() => {
    if (categories) {
      fetchData();
    }
  }, [categories]);

  useEffect(() => {
    setLoadData(true);
    let folderList = [];
    if(folder === "All") {
      folders.map((f)=>{
        if(!f.parent){                
          folderList.push(
            {
              "icon":<FolderIcon style={{verticalAlign: "text-bottom"}}/>,
              "title":" " + f.name,
              "folder":f.id,
              "readonly":true,
              "onClick":()=>{setFolder(f);}
            });
        }
      });
    }
    else{
      folders.map((f)=>{
        if(f.parent===folder.id){                
          folderList.push(
            {
              "icon":<FolderIcon style={{verticalAlign: "text-bottom"}}/>,
              "title":" " + f.name,
              "folder":f.id,
              "readonly":true,
              "onClick":()=>{setFolder(f);}
            });
        }
      });
    }
    setSubFolders(folderList);
    let dataList = [];
    let allList = [];
    if(!props.mobile){
      dataList = [...folderList];
    }
    data.map((doc) => {
      if (site === "All" || doc["site"] == site) {
        if (category === "All" || doc.category === category) {
          allList.push(doc);
          if(folder === "All" && !doc.folder) {
            dataList.push(doc);
          }
          else if(folder.id===doc.folder){
            dataList.push(doc);
          }
        }
      }
    });
    setAllData(allList)
    setShowData(dataList);
    setLoadData(false);
  }, [data, category, site, folder, folders]);

  const fetchData = () => {
    setLoadData(true);
    const documents = [];
    http.Get(
      url,
      (data) => {
        setTotalDocs(data.length);
        data.map((item) => {
          item["last_modified_time"] = formatDateTime(
            item["last_modified_time"]
          );
          item["icon"] = item["is_locked"] ? <LockIcon /> : "";
          item.download = (
            <SaveAltIcon
              onClick={() => {
                var divToPDF = window.document.getElementById(item.id);
                html2canvas(divToPDF).then((canvas) => {
                  var imgData = canvas.toDataURL("image/png");
                  var imgWidth = 210;
                  var pageHeight = 292;
                  var imgHeight = (canvas.height * imgWidth) / canvas.width;
                  var heightLeft = imgHeight;
                  var doc = new jsPDF("p", "mm");
                  var position = 0;
                  doc.addImage(
                    imgData,
                    "PNG",
                    0,
                    position,
                    imgWidth,
                    imgHeight
                  );
                  heightLeft -= pageHeight;

                  while (heightLeft >= 0) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(
                      imgData,
                      "PNG",
                      0,
                      position,
                      imgWidth,
                      imgHeight
                    );
                    heightLeft -= pageHeight;
                  }
                  doc.save(item.id + ".pdf");
                });
              }}
            />
          );
          documents.push(item);
        });
        setData(documents);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      <SnackbarComponent
        message={commonConstants.error.packageLimitReached}
        open={snackBarOpen}
        handleSnackBarClose={() => {
          setSnackBarOpen(false);
        }}
        severity={"error"}
      />
      {data &&
        data.map((doc) => {
          return (
            <div
              id={doc.id}
              style={{
                position: "absolute",
                left: "-8000px",
                width: "780px",
                padding: "30px",
              }}
              dangerouslySetInnerHTML={{ __html: doc.content }}
            />
          );
        })}
      {multipleUpload && (
        <Dialog
          open={true}
          onClose={() => {
            setMultipleUpload(null);
          }}
        >
          <div style={{ padding: 10 }}>
            <h3>Bulk Upload</h3>
            <Input
              inputProps={{
                multiple: true,
                accept: ".pdf, .doc, .docx, .xlsx, .csv",
              }}
              type="file"
              onChange={(e) => {
                setFiles(e.target.files);
              }}
            />
            <br />
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.filledButton}
              onClick={() => {
                if (
                  files &&
                  files.length > 0 &&
                  (totalDocs + files.length <=
                    props.companyTenant.max_company_document||props.companyTenant.max_company_document===-1)
                ){
                  setLoadData(true);
                  setMultipleUpload(false);
                  if (files && files.length > 0) {
                    var formData = new FormData();
                    formData.append("company_id", id);
                    var i;
                    for (i = 0; i < files.length; i++) {
                      formData.append(files[i].name, files[i], files[i].name);
                    }
                    http.Post(
                      "company_documents_upload",
                      formData,
                      (data) => {
                        setMultipleUpload(false);
                        fetchData();
                        setLoadData(false);
                        setBS(1);
                      },
                      (status, error) => {
                        setLoadData(false);
                      }
                    );
                  } else {
                    setLoadData(false);
                    setMultipleUpload(false);
                  }
                } else {
                  setSnackBarOpen(true);
                  setFiles(null);
                  setMultipleUpload(false);
                }
              }}
            >
              Upload
            </Button>
            <Button
              variant="outlined"
              margin="dense"
              size="small"
              className={classes.outlinedButton}
              onClick={() => {
                setMultipleUpload(false);
                setFiles(null);
              }}
            >
              Cancel
            </Button>
          </div>
        </Dialog>
      )}
      {showFolders && (
        <Dialog
          autoFocus
          open={true}
          onClose={() => {
            setShowFolders(false);
          }}
        >
          <div style={{ padding: 10, width:300 }}>   
            <h3>Folders</h3>
            <Grid container>
              <Grid item xs={10}>
                <input value={newFolder && newFolder["top"] && newFolder["top"]["name"] || ""} onChange={(e)=>{setNewFolder({"top":{"name":e.target.value, "company":id}});}}/>
              </Grid>
              <Grid item xs={2}>
                <AddIcon
                  className={classes.iconButtonColored} 
                  style={{float:"right", }}
                  onClick={()=>{
                    if(newFolder && newFolder["top"] && newFolder["top"]["name"]){
                      http.Post(
                        "company_document_folders/",
                        newFolder["top"],
                        (data) => {
                          http.Get(
                            `company_document_folders/?company=${id}`,
                            (foldersData) => {
                              setFolders(foldersData);
                              setNewFolder({...newFolder, "top":null});
                            },
                            () => {}
                          );
                        },
                        (status, error) => {
                        }
                      );
                    }
                  }}
                />
              </Grid>
            {folders && folders.filter((f)=>(!f.parent)).map((f) => (
              <>
              <Grid item xs={10}>
                {f.name}
              </Grid>
              <Grid item xs={2}>
                <DeleteIcon 
                  className={classes.iconButtonColored} 
                  style={{float:"right", }}
                  onClick={()=>{
                    http.Delete(
                      `company_document_folders/${f.id}/`,
                      {},
                      (data) => {
                        http.Get(
                          `company_document_folders/?company=${id}`,
                          (foldersData) => {
                            setFolders(foldersData);
                          },
                          () => {}
                        );
                      },
                      (status, error) => {
                      }
                    );
                  }}
                />
              </Grid>
              {folders.filter((f1)=>(f1.parent===f.id)).map((f1)=>(
                <>
                <Grid item xs={10}>
                  {"    -    -    " + f1.name}
                </Grid>
                <Grid item xs={2}>
                  <DeleteIcon 
                    className={classes.iconButtonColored}
                    style={{float:"right", }}
                    onClick={()=>{
                      http.Delete(
                        `company_document_folders/${f1.id}/`,
                        {},
                        (data) => {
                          http.Get(
                            `company_document_folders/?company=${id}`,
                            (foldersData) => {
                              setFolders(foldersData);
                            },
                            () => {}
                          );
                        },
                        (status, error) => {
                        }
                      );
                    }}
                  />
                </Grid>
                </>
              ))}
              <Grid item xs={10}>
                {"   -   -   "} <input value={newFolder && newFolder[f.id] && newFolder[f.id]["name"] || ""} onChange={(e)=>{setNewFolder({[f.id]:{"name":e.target.value, "company":id, "parent":f.id}});}}/>
              </Grid>
              <Grid item xs={2}>
                <AddIcon
                  className={classes.iconButtonColored} 
                  style={{float:"right", }}
                  onClick={()=>{
                    if(newFolder && newFolder[f.id] && newFolder[f.id]["name"]){
                      http.Post(
                        "company_document_folders/",
                        newFolder[f.id],
                        (data) => {
                          http.Get(
                            `company_document_folders/?company=${id}`,
                            (foldersData) => {
                              setFolders(foldersData);
                              setNewFolder({...newFolder, [f.id]:null});
                            },
                            () => {}
                          );
                        },
                        (status, error) => {
                        }
                      );
                    }
                  }}
                />
              </Grid>
              </>
            ))}
            </Grid>
          </div>
        </Dialog>
      )}
      {!props.mobile && data && (
        <ObjectList
          {...props}
          moduleName={
            <Grid container wrap='nowrap' style={{ display: "flex",  direction:"row",justifyContent:"space-between",alignItems:"center",width:"100%"}}>
              <Grid item xs>
                Documents{" "}
              </Grid>
              <Grid item xs style={{ fontSize: "16px", }}>
                <CreateNewFolderIcon 
                  className={classes.iconButtonColored} 
                  style={{verticalAlign: "text-bottom"}}
                  onClick={()=>{
                    setShowFolders(true);
                  }}
                />
                &nbsp;&nbsp;&nbsp;&nbsp;                
                <span onClick={()=>{setFolder("All");}} className={classes.iconButtonColored}>Root</span>
                {folder && folder!=="All" && folder.parent &&
                  <> &nbsp;&gt;&nbsp;
                    <span onClick={()=>{
                      let docFolder = folders.find((f)=>(f.id===folder.parent));
                      setFolder(docFolder);
                    }} className={classes.iconButtonColored}>{folder.parent_name}</span>
                    &nbsp;&gt;&nbsp;
                    <span onClick={()=>{
                      let docFolder = folders.find((f)=>(f.id===folder.id));
                      setFolder(docFolder);
                    }} className={classes.iconButtonColored}>{folder.name}</span>
                  </>
                }
                {folder && folder!=="All" && !folder.parent &&
                  <> &nbsp;&gt;&nbsp;
                    <span onClick={()=>{
                      let docFolder = folders.find((f)=>(f.id===folder.id));
                      setFolder(docFolder);
                    }} className={classes.iconButtonColored}>{folder.name}</span>
                  </>
                }
                
              </Grid>
              <Grid item style={{ fontSize: "12px", marginLeft: "20px" }}>
                <Select
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                  }}
                >
                  <MenuItem value="All">All Categories</MenuItem>
                  {categories &&
                    Object.entries(categories).map(([key, value]) => (
                      <MenuItem key={key} value={key}>
                        {value}
                      </MenuItem>
                    ))}
                </Select>
              </Grid>
            </Grid>

          }
          url={url}
          templateUrl={"public_templates?template_type_name=Document"}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_document > -1 &&
              totalDocs >= props.companyTenant.max_company_document)
              ? null
              : DocumentForm
          }
          tableColumns={tableColumns}
          data={showData}
          allData={allData}
          fetchData={fetchData}
          categories={categories}
          orderBy={"title"}
          searchFields={["title", "last_modified_by_name", "site_name"]}
          pageHeader={(props.company.company_name || "") + " | Documents"}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
      {props.mobile && data && (
        <ObjectListMobile
          {...props}
          moduleName={
            <div>
              Documents
              <div style={{fontSize:"14px"}}>
                <CreateNewFolderIcon 
                    className={classes.iconButtonColored} 
                    style={{verticalAlign: "text-bottom"}}
                    onClick={()=>{
                      setShowFolders(true);
                    }}
                  />
                  &nbsp;&nbsp;&nbsp;&nbsp;                
                  <span onClick={()=>{setFolder("All");}} className={classes.iconButtonColored}>root</span>
                  {folder && folder!=="All" && folder.parent &&
                    <> &nbsp;&gt;&nbsp;
                      <span onClick={()=>{
                        let docFolder = folders.find((f)=>(f.id===folder.parent));
                        setFolder(docFolder);
                      }} className={classes.iconButtonColored}>{folder.parent_name}</span>
                      &nbsp;&gt;&nbsp;
                      <span onClick={()=>{
                        let docFolder = folders.find((f)=>(f.id===folder.id));
                        setFolder(docFolder);
                      }} className={classes.iconButtonColored}>{folder.name}</span>
                    </>
                  }
                  {folder && folder!=="All" && !folder.parent &&
                    <> &nbsp;&gt;&nbsp;
                      <span onClick={()=>{
                        let docFolder = folders.find((f)=>(f.id===folder.id));
                        setFolder(docFolder);
                      }} className={classes.iconButtonColored}>{folder.name}</span>
                    </>
                  }
              </div>
            </div>
          }
          mobileMenu={
            <Grid item xs style={{ fontSize: "12px" ,alignContent: 'center'}}>
              <Select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <MenuItem value="All">All Categories</MenuItem>
                {categories &&
                  Object.entries(categories).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          }
          subFolders={subFolders}
          url={url}
          templateUrl={"public_templates?template_type_name=Document"}
          actions={actions}
          permissions={{
            create: ["Create/Update Data"],
            edit: ["Create/Update Data"],
            delete: ["Remove Data"],
          }}
          objectForm={
            (props.companyPermission &&
              props.companyPermission === "readonly") ||
            (props.companyTenant.max_company_document > -1 &&
              totalDocs >= props.companyTenant.max_company_document)
              ? null
              : DocumentForm
          }
          tableColumns={tableColumns}
          data={showData}
          fetchData={fetchData}
          orderBy={"title"}
          searchFields={["title", "last_modified_by_name", "site_name"]}
          pageHeader={(props.company.company_name || "") + " | Documents"}
          loadData={loadData}
          excludeAddButton={
            props.companyPermission && props.companyPermission === "readonly"
          }
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Documents);
