import React from "react";
import MuiTableCell from "@material-ui/core/TableCell";
import { withStyles } from "@material-ui/core/styles";
import ScreenLoader from "../../../../Common/Loader";
import {
  Divider,
  Table,
  Chip,
  TableHead,
  TableRow,
  TableBody,
  Grid,
  Typography,
  Paper,
  IconButton,
} from "@material-ui/core";
import CancelIcon from "@material-ui/icons/Cancel";
import useStyles from "../Style/style";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const TotalEmployeesOnVacation = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={11}>
              <span className={classes.title}>TOTAL EMPLOYEES ON VACATION</span>
            </Grid>
          </Grid>
          <Divider
            classes={{ root: classes.dividers }}
            component="hr"
            orientation="horizontal"
          />
        </Grid>
        {props.vacationCount !== null ? (
          <Grid item xs={12} className={classes.tableGrid}>
            <Table
              stickyHeader
              aria-label="simple table"
              className={classes.table}
            >
              <TableBody>
                <TableRow id="tableRow" className={classes.tableRow}>
                  <TableCell
                    className={`${classes.ellipsis} ${classes.tableCell}`}
                    component="td"
                    scope="row"
                  >
                    {props.vacationCount}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    component="td"
                    scope="row"
                  >
                    <Chip
                      label="EMPLOYEE DETAILS"
                      clickable
                      className={classes.chip}
                      color="primary"
                      onClick={() => props.setShowEmployees(true)}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        ) : (
          <ScreenLoader />
        )}
      </Grid>
    </div>
  );
};

export const EmployeeVacationModal = (props) => {
  const classes = useStyles();

  return (
    <>
      {!props.hrEmployees && <ScreenLoader />}
      {props.hrEmployees && (
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography
              variant="h6"
              component="h6"
							className={classes.title}
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              Employee Vacation Details
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <div style={{ paddingTop: 10, float: "right" }}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  }
                }}
              >
                <CancelIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Table className={{ root: classes.table }}>
                      <TableHead>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableHeader}`}>
                          EMPLOYEE
                        </TableCell>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableHeader}`}>
                          DEPARTMENT
                        </TableCell>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableHeader}`}>
                          LOCATION
                        </TableCell>
                        <TableCell className={`${classes.tableCellHeader} ${classes.tableHeader}`}>
                          VACATION RANGE
                        </TableCell>
                      </TableHead>
                      {props.hrEmployees.map((employee) => {
                        if (employee.curr_vacation) {
                          return (
                            <TableRow className={classes.tableRow}>
                              <TableCell className={classes.tableCell}>
                                {employee.first_name + " " + employee.last_name}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {employee.department}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {employee.location_str}
                              </TableCell>
                              <TableCell className={classes.tableCell}>
                                {employee.curr_vacation.start_date +
                                  " - " +
                                  employee.curr_vacation.end_date}
                              </TableCell>
                            </TableRow>
                          );
                        }
                      })}
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Divider />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default TotalEmployeesOnVacation;
