import React, { useEffect, useState } from "react";
import PortalRoleForm from "./PortalRoleForm";
import ObjectList from "../../../Common/ObjectList";
import ObjectListMobile from "../../../Common/ObjectListMobile";
import http from "../../../Common/RestAPIHandler";

const tableColumns = [
  {
    id: "role_name",
    label: "ROLE NAME",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "description",
    label: "ROLE DESCRIPTION",
    minWidth: 220,
    sortable: true,
  },
  {
    id: "status",
    label: "STATUS",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "user_count",
    label: "USER COUNT",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "created_by_name",
    label: "CREATED BY",
    minWidth: 150,
    sortable: true,
  },
];

const PortalRoleList = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `portal-roles`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach(
          (item) => (
            (item.rp_disabled = !item.status),
            (item.status = item.status ? "Active" : "Disabled")
            )
        );
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };
  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Portal Security Control"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Portal Users"],
        edit: ["Manage Portal Users"],
        delete: ["Manage Portal Users"],
      }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={PortalRoleForm}
      orderBy={"role_name"}
      searchFields={["role_name"]}
      pageHeader={(props.user.company_name || "") + " | Portal Management"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Portal Security Control"}
      url={url}
      actions={actions}
      permissions={{
        create: ["Manage Portal Users"],
        edit: ["Manage Portal Users"],
        delete: ["Manage Portal Users"],
      }}
      tableColumns={tableColumns}
      data={data}
      fetchData={fetchData}
      objectForm={PortalRoleForm}
      orderBy={"role_name"}
      searchFields={["role_name"]}
      pageHeader={(props.user.company_name || "") + " | Portal Management"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default PortalRoleList;
