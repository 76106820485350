import React, { useState, useEffect } from "react";
import http from "../../Common/RestAPIHandler";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import { onCopy } from "../../Common/TableUtility";
import CopyIcon from "@material-ui/icons/FileCopy";
import AddIcon from "@material-ui/icons/Add";
import Input from "@material-ui/core/Input";
import DeleteIcon from "@material-ui/icons/Delete";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ScreenLoader from "../../Common/Loader";
import CreateIcon from "@material-ui/icons/Create";
import SaveIcon from "@material-ui/icons/Save";
import ListIcon from "@material-ui/icons/List";
import Popover from "@material-ui/core/Popover";
import FingerprintIcon from "@material-ui/icons/Fingerprint";
import KeyGen from "../../Common/KeyGen";
import ObjectVersions from "../../Common/ObjectVersions";
import { FormControlLabel, Radio } from "@material-ui/core";
import {
	Grid,
	Divider,
	Dialog,
	Chip,
	CardContent,
	Card,
	Typography,
	Collapse,
	TextField,
	IconButton,
	Menu,
	Tooltip,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import HistoryIcon from "@material-ui/icons/History";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { validPassword } from "../../Common/Utility";

const useStyles = makeStyles((theme) => ({
	root: {
		width: "-webkit-fill-available",
		borderRadius: 8,
	},
	blankChip: {
		borderRadius: 8,
		width: "-webkit-fill-available",
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
		"&:focus": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
	},
	bkIcon: {
		background: "black",
		color: "white",
	},
	chip: {
		borderRadius: 8,
		color: "white",
		backgroundColor: theme.colorScheme,
		width: "-webkit-fill-available",
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
		"&:focus": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
	},
	chipOutlined: {
		borderRadius: 8,
		borderColor: theme.colorScheme,
		marginRight: "2px",
		color: theme.colorScheme,
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
		"&:focus": {
			backgroundColor: theme.colorScheme,
			color: "white",
		},
	},
	copy: {
		cursor: "pointer",
		color: theme.colorScheme,
	},
	cursor: {
		cursor: "pointer",
		color: theme.colorScheme,
		marginRight: "10px",
	},
	endIcons: {
		cursor: "pointer",
		color: theme.colorScheme,
		display: "flex",
		justifyContent: "space-between",
	},
	error: {
		color: "red",
		margin: 0
	},
	errorBoundary: {
		display: "None"
	},
	errorIconColorScheme: {
		color: "#f44336",
		width: 15,
	},
	FprintIconColorScheme: {
		cursor: "pointer",
		color: theme.linkColorFaded,
		"&:hover": {
			color: theme.linkColor,
		},
		float: "right",
	},
	iconColorScheme: {
		cursor: "pointer",
		// color: "#a4a4a4",
		color: theme.linkColorFaded,
		"&:hover": {
			color: theme.linkColor
		},
		width: 15,
		verticalAlign: "bottom",
	},
	wordWrap: {
		whiteSpace: "normal",
		wordWrap: "break-word",
		maxWidth: "200px",
	},
	checkboxColor: {
		color: theme.colorScheme + "!important",
	},
	button: {
		borderRadius: 25,
		backgroundColor: theme.colorScheme,
		color: "white",
		textTransform: "capitalize",
		"&:hover": {
			backgroundColor: theme.colorScheme,
			color: "white",
			border: "1px solid white",
		},
	},
	passwordHide: {
		cursor: "pointer",
		// color: theme.colorScheme,
		fontSize: "1.0rem",
		marginLeft: 5,
	},
	tableCell: {
		// paddingTop: 12,
		minWidth: "195px !important",
		color: theme.subText
	},
	tableRow: {
		backgroundColor: theme.notification
	},
}));

const DeviceCredential = (props) => {
	const classes = useStyles();
	const categories = props.categories;
	const [account, setAccount] = useState(props.account);
	const [snackBarOpen, setSnackBarOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [errors, setErrors] = useState(false);
	const [edit, setEdit] = useState(false);
	const [loading, setLoading] = useState(false);

	const [collapse, setCollapse] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [anchorElEditMenu, setAnchorElEditMenu] = useState(null);

	const handleClickEditMenu = (event) => {
		setAnchorElEditMenu(event.currentTarget);
	};

	const handleCloseEditMenu = () => {
		setAnchorElEditMenu(null);
	};

	const openEditMenu = Boolean(anchorElEditMenu);

	const onChange = (e) => {
		if ("checkbox" === e.target.type || "radio" === e.target.type) {
			setAccount({
				...account,
				[e.target.name]: !account[e.target.name],
			});
		} else
			setAccount({
				...account,
				[e.target.name]: e.target.value,
			});
	};

	const handlePut = (e) => {
		setEdit(false);
		setLoading(true);
		http.Put(
			`device/credential/${account.id}/`,
			account,
			(data) => {
				data["versions"] = (
					<HistoryIcon
						className={classes.cursor}
						onClick={(e) => {
							props.setVersionsAnchorEl(e.currentTarget);
							props.setVersions(null);
							http.Get(
								`versions?object_id=${data.id}&model_name=devicecredential`,
								(versions) => {
									props.setVersions(versions);
								},
								() => {
									props.setVersions(null);
								}
							);
						}}
					/>
				);
				setAccount(data);
				setErrors(false);
				setLoading(false);
			},
			(status, error) => {
				setErrors(error);
				setEdit(true);
				setLoading(false);
			}
		);
	};

	const deleteAccount = (accountId) => {
		setLoading(true);
		http.Delete(
			`device/credential/`,
			{ ids: [accountId] },
			() => {
				props.fetchData();
				setLoading(false);
			},
			() => {
				setLoading(false);
			}
		);
	};

	return (
		<React.Fragment>
			{loading && (
				<TableRow>
					<TableCell colSpan={8}>
						<ScreenLoader />
					</TableCell>
				</TableRow>
			)}
			<Snackbar
				open={snackBarOpen}
				autoHideDuration={3000}
				onClose={() => {
					setSnackBarOpen(false);
				}}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
			>
				<Alert
					onClose={() => {
						setSnackBarOpen(false);
					}}
					severity="success"
					elevation={6}
					variant="filled"
				>
					{message}
				</Alert>
			</Snackbar>
			{!props.mobile && !loading && edit && (
				<TableRow key={account.id} className={classes.tableRow} >
					<TableCell className={classes.tableCell} >
						<Select
							id={"category"}
							fullWidth
							margin="dense"
							name={"category"}
							value={account.category || "placeholder"}
							onChange={onChange}
							style={{
								border: "0px",
								outline: "none",
							}}
						>
							<MenuItem disabled value="placeholder">
								<span style={{ color: "#a2a2a2" }}>CREDENTIAL TYPE</span>
							</MenuItem>
							{Object.entries(categories).map(([key, value]) => (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							))}
						</Select>
						<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.category}</p>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							variant="outlined"
							margin="dense"
							onChange={onChange}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="text"
							name="description"
							style={{
								border: "0px",
								borderBottom: "1px solid black",
								outline: "none",
							}}
							value={account.description}
						/>
						<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.description}</p>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<CopyIcon
							className={classes.iconColorScheme}
							fontSize="small"
							onClick={() => {
								onCopy(props, account, "username");
								setMessage("Username Copied");
								setSnackBarOpen(true);
							}}
						/>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							variant="outlined"
							margin="dense"
							onChange={(e) => {
								setAccount({
									...account,
									["username"]: e.target.value,
								});
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="text"
							name="new-name"
							autoComplete="new-name"
							style={{
								border: "0px",
								borderBottom: "1px solid black",
								outline: "none",
							}}
							value={account.username}
						/>
						<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.username}</p>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<CopyIcon
							className={classes.iconColorScheme}
							fontSize="small"
							onClick={() => {
								onCopy(props, account, "password");
								setMessage("Password Copied");
								setSnackBarOpen(true);
							}}
						/>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<Input
							fullWidth
							variant="outlined"
							margin="dense"
							onChange={(e) => {
								setAccount({
									...account,
									["password"]: e.target.value,
								});
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									handlePut();
								}
							}}
							type="password"
							name="new-pass"
							autoComplete="new-pass"
							style={{
								border: "0px",
								borderBottom: "1px solid black",
								outline: "none",
								fontFamily: "Raleway",
							}}
							value={account.password}
						/>
						<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.password}</p>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<FormControlLabel
							control={
								<Radio
									name="is_default"
									checked={account.is_default ? true : false}
									onClick={onChange}
									color="primary"
								/>
							}
							label="DEFAULT"
							labelPlacement="end"
						/>
					</TableCell>
					<TableCell>
						<SaveIcon onClick={handlePut} className={classes.cursor} />
					</TableCell>
				</TableRow>
			)}
			{!props.mobile && !loading && !edit && (
				<TableRow key={account.id} className={classes.tableRow}>
					<TableCell className={classes.tableCell}>{account.category_name}</TableCell>
					<TableCell className={classes.tableCell}>{account.description}</TableCell>
					<TableCell>
						<FilterNoneIcon
							fontSize="small"
							className={classes.iconColorScheme}
							onClick={() => {
								onCopy(props, account, "username");
								setMessage("Username Copied");
								setSnackBarOpen(true);
							}}
						/>
					</TableCell>
					<TableCell className={classes.tableCell}>{account.username}</TableCell>
					<TableCell>
						<FilterNoneIcon
							fontSize="small"
							className={classes.iconColorScheme}
							onClick={() => {
								onCopy(props, account, "password");
								setMessage("Password Copied");
								setSnackBarOpen(true);
							}}
						/>
					</TableCell>
					<TableCell className={classes.tableCell}>
						<span
							className={classes.passwordHide}
							onClick={(e) => {
								if (e.target.innerHTML === "············")
									e.target.innerHTML = String(account["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
								else e.target.innerHTML = "············";
							}}
						>
							············
						</span>
						&nbsp;
						{account["flagged_pass"] ? (
							<Tooltip
								interactive
								arrow={true}
								placement="top-start"
								title="Unsafe Password!"
							>
								<ErrorOutlineIcon className={classes.errorIconColorScheme} />
							</Tooltip>
						) : (
							<></>
						)}
					</TableCell>
					<TableCell style={{ textAlign: "center" }} className={classes.tableCell}>
						{account.is_default && <span>DEFAULT</span>}
					</TableCell>
					<TableCell>
						<Tooltip
							arrow={true}
							title="Options"
							placement="top-start"
							enterDelay={200}
						>
							<IconButton size="small" onClick={handleClickEditMenu}>
								<ListIcon className={classes.cursor} />
							</IconButton>
						</Tooltip>
						<Menu
							id="end-icons"
							open={openEditMenu}
							anchorEl={anchorElEditMenu}
							onClick={handleCloseEditMenu}
						>
							<Tooltip arrow={true} title="History" placement="left-start">
								<MenuItem onClick={handleCloseEditMenu}>
									{account.versions}
								</MenuItem>
							</Tooltip>
							<Tooltip arrow={true} title="Edit" placement="left-start">
								<MenuItem onClick={handleCloseEditMenu}>
									<CreateIcon
										onClick={(e) => {
											setEdit(true);
										}}
										className={classes.cursor}
									/>
								</MenuItem>
							</Tooltip>
							<Tooltip arrow={true} title="Delete" placement="left-start">
								<MenuItem onClick={handleCloseEditMenu}>
									<DeleteIcon
										onClick={(e) => {
											deleteAccount(account.id);
										}}
										className={classes.cursor}
									/>
								</MenuItem>
							</Tooltip>
						</Menu>
					</TableCell>
				</TableRow>
			)}
			{props.mobile && !loading && edit && (
				<Dialog
					id={"hightop"}
					open={edit}
					onClose={() => setEdit(false)}
					disablePortal
					// style={{ width: 350, margin: 0 }}
				>
					{/* Need to redo the error handling <p> tags effecting styling */}
					<Card style={{ width: 300 }}>
						<CardContent>
							<Grid container spacing={1} direction="column">
								<Grid item xs={8}>
									<Select
										id={"category"}
										fullWidth
										name={"category"}
										value={account.category || "placeholder"}
										onChange={onChange}
										style={{
											border: "0px",
											outline: "none",
										}}
										placeholder="Account Type"
									>
										<MenuItem disabled value="placeholder">
											<span style={{ color: "#a2a2a2" }}>CREDENTIAL TYPE</span>
										</MenuItem>
										{Object.entries(categories).map(([key, value]) => (
											<MenuItem key={key} value={key}>
												{value}
											</MenuItem>
										))}
									</Select>
									<p className={classes.error}>{errors.category}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										id="description"
										fullWidth
										variant="outlined"
										margin="dense"
										name="description"
										value={account.description || ""}
										onChange={onChange}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder="DESCRIPTION"
									/>
									<p className={classes.error}>{errors.description}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										variant="outlined"
										margin="dense"
										name="new-name"
										autoComplete="new-name"
										value={account.username || ""}
										onChange={(e) => {
											setAccount({
												...account,
												["username"]: e.target.value,
											});
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder="USERNAME *"
									/>
									<p className={classes.error}>{errors.username}</p>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										variant="outlined"
										margin="dense"
										name="new-pass"
										autoComplete="new-pass"
										value={account.password || ""}
										onChange={(e) => {
											setAccount({
												...account,
												["password"]: e.target.value,
											});
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter") {
												handlePut();
											}
										}}
										placeholder="PASSWORD *"
										style={{ fontFamily: "Raleway" }}
									/>
									<p className={classes.error}>{errors.password}</p>
								</Grid>
								<Grid container spacing={1}>
									<Grid item xs={6}>
										<Chip
											variant="outlined"
											onClick={() => setEdit(false)}
											label="Cancel"
											className={classes.blankChip}
										/>
									</Grid>
									<Grid item xs={6}>
										<Chip
											label="Save"
											onClick={handlePut}
											className={classes.chip}
										/>
									</Grid>
								</Grid>
							</Grid>
						</CardContent>
					</Card>
				</Dialog>
			)}
			{props.mobile && !loading && !edit && (
				<Grid container spacing={1} className={classes.root}>
					<Grid item xs={12}>
						<Card style={{ borderRadius: 10 }}>
							<CardContent style={{ display: "flex", height: 80 }}>
								<Grid container item xs={11} spacing={0}>
									<Grid item xs={12}>
										<Typography variant="overline">TYPE</Typography>
									</Grid>
									<Grid item xs={12}>
										<Typography variant="body1">
											{account.description ? account.description : " "}
											{/* {account.category_name ? account.category_name : " "} */}
										</Typography>
									</Grid>
								</Grid>
								<Grid container item xs={1} spacing={0}>
									<Grid item xs={12} style={{ alignSelf: "center" }}>
										<IconButton size="small" className={classes.bkIcon}>
											{collapse ? (
												<KeyboardArrowUpIcon
													onClick={() => setCollapse((prev) => !prev)}
												/>
											) : (
												<KeyboardArrowDownIcon
													onClick={() => setCollapse((prev) => !prev)}
												/>
											)}
										</IconButton>
									</Grid>
								</Grid>
							</CardContent>
							<Collapse key={account.id} in={collapse} timeout="auto">
								<Grid
									container
									spacing={1}
									direction="column"
									style={{ margin: 10 }}
								>
									<Grid item xs={12}>
										<Grid container spacing={0}>
											<Grid item xs={10}>
												<Typography variant="overline">username</Typography>
												<Typography variant="body2">
													{account.username ? account.username : " "}
												</Typography>
											</Grid>
											<Grid item xs={2} style={{ alignSelf: "flex-end" }}>
												<CopyIcon
													className={classes.iconColor}
													fontSize="small"
													onClick={() => {
														onCopy(props, account, "username");
														setMessage("Username Copied");
														setSnackBarOpen(true);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={0}>
											<Grid item xs={10}>
												<Typography variant="overline">password</Typography>
												<Typography variant="body2">
													<span
														className={classes.cursor}
														onClick={(e) => {
															if (e.target.innerHTML === "············")
																e.target.innerHTML = String(account["password"]).replaceAll("<","&lt;").replaceAll(">","&gt;");
															else e.target.innerHTML = "············";
														}}
													>
														{" "}
														············
													</span>
												</Typography>
											</Grid>
											<Grid item xs={2} style={{ alignSelf: "flex-end" }}>
												<CopyIcon
													className={classes.iconColor}
													fontSize="small"
													onClick={() => {
														onCopy(props, account, "password");
														setMessage("Password Copied");
														setSnackBarOpen(true);
													}}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
								<Divider />
								<Grid
									container
									spacing={1}
									direction="row-reverse"
									style={{ height: 50 }}
								>
									<Grid item xs className={classes.endIcons}>
										<DeleteIcon
											fontSize="small"
											onClick={(e) => {
												deleteAccount(account.id);
											}}
											className={classes.cursor}
										/>
										<CreateIcon
											fontSize="small"
											onClick={() => {
												setEdit(true);
											}}
											className={classes.cursor}
										/>
									</Grid>
								</Grid>
							</Collapse>
						</Card>
					</Grid>
				</Grid>
			)}
		</React.Fragment>
	);
};

const DeviceCredentials = (props) => {
	const classes = useStyles();
	const categories = props.categories;
	const [errors, setErrors] = useState(false);
	const [deviceAccounts, setDeviceAccounts] = useState([]);
	const [account, setAccount] = useState({});
	const [loading, setLoading] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [versionsAnchorEl, setVersionsAnchorEl] = useState(null);
	const [versions, setVersions] = useState([]);
	const [openModal, setOpenModal] = useState(false);

	const handleClickShowKeyGen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseShowKeyGen = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const fetchData = () => {
		setLoading("Loading");
		setDeviceAccounts([]);
		http.Get(
			`device/credential/?device=${props.deviceId}`,
			(data) => {
				data.map((item) => {
					item["versions"] = (
						<HistoryIcon
							className={classes.cursor}
							onClick={(e) => {
								setVersionsAnchorEl(e.currentTarget);
								setVersions(null);
								http.Get(
									`versions?object_id=${item.id}&model_name=devicecredential`,
									(versions) => {
										setVersions(versions);
									},
									() => {
										setVersions(null);
									}
								);
							}}
						/>
					);
				});
				setDeviceAccounts(data);
				setLoading(null);
			},
			() => {
				setLoading(null);
			}
		);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const onSubmit = (e) => {
		setLoading(true);
		account.device = props.deviceId;
		const send = () => {
			http.Post(
				`device/credential/`,
				account,
				(account) => {
					fetchData();
					setAccount({});
					setErrors({});
					if (account.is_default) {
						props.fetchData();
					}
					setLoading(false);
				},
				(status, error) => {
					setErrors(error);
					setLoading(false);
				}
			);
		};

		// If been presented with the 'unsafe password error' once, allow user to POST again
		validPassword(account).then(({ res, error }) => {
			if (res === true) {
				console.log("res supposed to be true", res);
				account["flagged_pass"] = false;
				send();
			} else if (error) {
				console.log("res supposed to be false", res);
				// Flag password as unsafe for UI feedback
				account["flagged_pass"] = true;
				send();
			}
		});
	};

	const onChange = (e) => {
		if ("checkbox" === e.target.type || "radio" === e.target.type) {
			setAccount({
				...account,
				[e.target.name]: !account[e.target.name],
			});
		} else
			setAccount({
				...account,
				[e.target.name]: e.target.value,
			});
	};

	return (
		<div>
			{loading && <ScreenLoader />}

			{versionsAnchorEl && (
				<ObjectVersions
					{...props}
					versions={versions}
					fetchData={fetchData}
					anchorEl={versionsAnchorEl}
					setAnchorEl={setVersionsAnchorEl}
				/>
			)}

			{/* Credential view on Desktop */}
			{!props.mobile && !loading && (
				<Table aria-label="simple table" style={{ backgroundColor: "white" }}>
					<TableBody>
						{deviceAccounts.map((account) => {
							return (
								<DeviceCredential
									{...props}
									categories={categories}
									account={account}
									fetchData={fetchData}
									setVersionsAnchorEl={setVersionsAnchorEl}
									setVersions={setVersions}
									fetchDeviceData={props.fetchData}
								/>
							);
						})}
						<TableRow className={classes.tableRow} >
							<TableCell className={classes.tableCell} >
								<Select
									id={"category"}
									fullWidth
									name={"category"}
									value={account.category || "placeholder"}
									onChange={onChange}
									style={{
										border: "0px",
										outline: "none",
									}}
								>
									<MenuItem disabled value="placeholder">
										<span style={{ color: "#a2a2a2" }}>CREDENTIAL TYPE</span>
									</MenuItem>
									{Object.entries(categories).map(([key, value]) => (
										<MenuItem key={key} value={key}>
											{value}
										</MenuItem>
									))}
								</Select>
								<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.category}</p>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Input
									id="description"
									fullWidth
									variant="outlined"
									margin="dense"
									name="description"
									value={account.description || ""}
									onChange={onChange}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											onSubmit();
										}
									}}
									placeholder="DESCRIPTION"
								/>
								<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.description}</p>
							</TableCell>
							<TableCell>
								<div></div>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Input
									fullWidth
									variant="outlined"
									margin="dense"
									name="new-name"
									autoComplete="new-name"
									value={account.username || ""}
									onChange={(e) => {
										setAccount({
											...account,
											["username"]: e.target.value,
										});
									}}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											onSubmit();
										}
									}}
									placeholder="USERNAME *"
								/>
								<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.username}</p>
							</TableCell>
							<TableCell >
								<Tooltip
									arrow={true}
									title="Generate Password"
									placement="bottom-start"
								>
									<FingerprintIcon
										className={classes.FprintIconColorScheme}
										onClick={handleClickShowKeyGen}
									/>
								</Tooltip>
								<Popover
									open={open}
									anchorEl={anchorEl}
									onClose={handleCloseShowKeyGen}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "center",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "center",
									}}
								>
									<KeyGen
										setPassword={(p) => {
											setAccount({
												...account,
												["password"]: p,
											});
											handleCloseShowKeyGen();
										}}
									/>
								</Popover>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<Input
									fullWidth
									variant="outlined"
									margin="dense"
									name="new-pass"
									autoComplete="new-pass"
									value={account.password || ""}
									onChange={(e) => {
										setAccount({
											...account,
											["password"]: e.target.value,
										});
									}}
									onKeyPress={(e) => {
										if (e.key === "Enter") {
											onSubmit();
										}
									}}
									placeholder="PASSWORD *"
									style={{ fontFamily: "Raleway" }}
								/>
								<p className={errors ? `${classes.error}` : `${classes.error} ${classes.errorBoundary}`}>{errors.password}</p>
							</TableCell>
							<TableCell className={classes.tableCell}>
								<FormControlLabel
									control={
										<Radio
											name="is_default"
											checked={account.is_default ? true : false}
											onClick={onChange}
											color="primary"
										/>
									}
									label="DEFAULT"
									labelPlacement="end"
								/>
							</TableCell>
							<TableCell>
								<Tooltip arrow={true} title="Add New" placement="top-start">
									<IconButton size="small" onClick={onSubmit}>
										<AddIcon className={classes.cursor} />
									</IconButton>
								</Tooltip>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			)}

			{/* Credential view on mobile */}
			{props.mobile && !loading && (
				<>
					<Grid
						container
						spacing={1}
						style={{ justifyContent: "center", paddingTop: 8 }}
					>
						<Grid item xs={12}>
							<Chip
								label="ADD NEW CREDENTIAL"
								variant="outlined"
								style={{
									width: "-webkit-fill-available",
									height: 45,
									fontSize: "initial",
								}}
								onClick={() => setOpenModal(true)}
								className={classes.chipOutlined}
							/>
						</Grid>
						<br />
						{deviceAccounts.map((account) => {
							if (account.employee === props.employeeId) {
								return (
									<Grid item xs={12}>
										<DeviceCredential
											{...props}
											categories={categories}
											account={account}
											fetchData={fetchData}
										/>
									</Grid>
								);
							}
						})}
					</Grid>

					<Dialog
						id={"hightop"}
						open={openModal}
						onClose={() => {
							setOpenModal(false);
						}}
						disablePortal
					>
						{/* Need to redo the error handling <p> tags effecting styling */}
						<Card style={{ width: 300 }}>
							<CardContent>
								<Grid container spacing={0} direction="column">
									<Grid item xs={8}>
										<Select
											id={"category"}
											fullWidth
											name={"category"}
											value={account.category || "placeholder"}
											onChange={onChange}
											style={{
												border: "0px",
												borderBottom: "1px solid black",
												outline: "none",
											}}
										>
											<MenuItem disabled value="placeholder">
												<span style={{ color: "#a2a2a2" }}>
													CREDENTIAL TYPE
												</span>
											</MenuItem>
											{Object.entries(categories).map(([key, value]) => (
												<MenuItem key={key} value={key}>
													{value}
												</MenuItem>
											))}
										</Select>
										<p className={classes.error}>{errors.category}</p>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="description"
											fullWidth
											variant="outlined"
											margin="dense"
											name="description"
											value={account.description || ""}
											onChange={onChange}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											placeholder="DESCRIPTION"
										/>
										<p className={classes.error}>{errors.description}</p>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											variant="outlined"
											margin="dense"
											name="new-name"
											autoComplete="new-name"
											value={account.username || ""}
											onChange={(e) => {
												setAccount({
													...account,
													["username"]: e.target.value,
												});
											}}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											placeholder="USERNAME *"
										/>
										<p className={classes.error}>{errors.username}</p>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											variant="outlined"
											margin="dense"
											name="new-pass"
											autoComplete="new-pass"
											value={account.password || ""}
											InputProps={{
												endAdornment: (
													<>
														<Divider orientation="vertical" />
														<FingerprintIcon
															className={classes.FprintIconColorScheme}
															onClick={handleClickShowKeyGen}
														/>
													</>
												),
											}}
											onChange={(e) => {
												setAccount({
													...account,
													["password"]: e.target.value,
												});
											}}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											placeholder="PASSWORD *"
											style={{ fontFamily: "Raleway" }}
										/>
										<p className={classes.error}>{errors.password}</p>
									</Grid>
									<Grid item xs={12}>
										<Popover
											open={open}
											anchorEl={anchorEl}
											onClose={handleCloseShowKeyGen}
											anchorOrigin={{
												vertical: "bottom",
												horizontal: "center",
											}}
											transformOrigin={{
												vertical: "top",
												horizontal: "center",
											}}
										>
											<KeyGen
												setPassword={(p) => {
													setAccount({
														...account,
														["password"]: p,
													});
													handleCloseShowKeyGen();
												}}
											/>
										</Popover>
									</Grid>
									<Grid item xs={12}>
										<TextField
											id="website"
											fullWidth
											variant="outlined"
											margin="dense"
											name="website"
											value={account.website || ""}
											onChange={onChange}
											onKeyPress={(e) => {
												if (e.key === "Enter") {
													onSubmit();
												}
											}}
											placeholder="WEBSITE"
										/>
										<p className={classes.error}>{errors.website}</p>
									</Grid>
									<Grid container spacing={1} style={{ marginTop: 0 }}>
										<Grid item xs={6}>
											<Chip
												variant="outlined"
												onClick={() => setOpenModal(false)}
												label="Cancel"
												className={classes.blankChip}
											/>
										</Grid>
										<Grid item xs={6}>
											<Chip
												label="Save"
												onClick={onSubmit}
												className={classes.chip}
											/>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Dialog>
				</>
			)}
		</div>
	);
};

export default DeviceCredentials;
