import React, { useState, useEffect, useCallback } from "react";
import { authenticator } from "otplib";
import { format } from "date-fns-tz";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Grid, Button, Snackbar } from "@material-ui/core";
import { isDarkEnabled, getColorSchemeLight } from "./Utility";
import { makeStyles } from "@material-ui/core/styles";
import { onCopy } from "./TableUtility";
import Alert from "@material-ui/lab/Alert";
import FilterNoneIcon from "@material-ui/icons/FilterNone";
const useStyles = makeStyles((theme) => ({
  text: {
	  cursor: "pointer",
    color: theme.subText,
    "&:hover": {
      color: theme.text,
    },
  },
  button: {
    borderColor: theme.buttonClickableBorderAlt,
    "&:hover": {
      borderColor: theme.buttonClickableBorder,
    },
    color: theme.buttonClickableText,
  },
	icon: {
	  cursor: "pointer",
    backgroundColor: "transparent !important",
    color: theme.linkColorFaded,
    "&:hover": {
      color: theme.linkColor,
    },
    width: 15,
    verticalAlign: "bottom",
	},
}));

const OTP = (props) => {
  const classes = useStyles();
  const otpKey = props.otpKey;
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState();
  const [otpSeconds, setOtpSeconds] = useState(0);
  const [message, setMessage] = useState();  
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  var interval;

  useEffect(() => {
    if (showOtp && otpKey) {
      setOtp(authenticator.generate(otpKey.replaceAll(" ", "")));
      setOtpSeconds((59 - parseInt(format(new Date(), "ss"))) % 30);
      interval = setInterval(() => {
        setOtp(authenticator.generate(otpKey.replaceAll(" ", "")));
        setOtpSeconds((59 - parseInt(format(new Date(), "ss"))) % 30);
      }, 1000);
    } else {
      clearInterval(interval);
    }
  }, [showOtp, otpKey]);

  const div = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return (
    <div ref={div}>
      <Snackbar
          open={Boolean(message)}
          autoHideDuration={3000}
          onClose={() => {
              setMessage(null);
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
          <Alert
              onClose={() => {
                  setMessage(null);
              }}
              severity="success"
              elevation={6}
              variant="filled"
          >
              {message}
          </Alert>
      </Snackbar>
      {!showOtp && otpKey && (
        <Button
          className={classes.button}
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => {
            setShowOtp(true);
          }}
        >
          OTP
        </Button>
      )}
      {showOtp && otpKey && (
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <div
              className={classes.text}
              style={{ width: 80, minWidth: 80 }}
              onClick={() => {
                onCopy(props, otp);
                setMessage("OTP Copied");
              }}
            >
            {width && width > 300 &&
              <FilterNoneIcon
                className={classes.icon}
                fontSize="small"
                onClick={() => {
                  onCopy(props, otp);
                  setMessage("OTP Copied");
                }}
              />
            }&nbsp;&nbsp;
              {otp}
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ width: 20, minWidth: 20 }}>
              <CircularProgressbar
                value={otpSeconds}
                maxValue={30}
                strokeWidth={20}
                styles={{
                  path: {
                    stroke: getColorSchemeLight(),
                    strokeLinecap: "butt",
                  },
                  trail: {
                    stroke: isDarkEnabled() ? "none" : "#d6d6d6",
                  },
                }}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default OTP;
