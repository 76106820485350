import React, { useEffect, useState } from "react";
import http from "../../Common/RestAPIHandler";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import TimeOffRequestForm from "./TimeOffRequestForm";
import {
  Chip,
  Collapse,
  Drawer,
  Divider,
  Grid,
  Table,
  TableHead,
  TableBody,
  TableFooter,
  TableRow,
  TableContainer,
  TableCell,
  Paper,
  Typography,
  IconButton,
} from "@material-ui/core";
import ScreenLoader from "../../Common/Loader";
import CancelIcon from "@material-ui/icons/Cancel";
import useStyles from "../Style/style";

const TimeOffAvailability = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [loadData, setLoadData] = useState(false);

  const [hrEmployee, setHrEmployee] = useState();
  const vacationTypes = ["Vacation", "Sick", "Personal", "Moving"];
  const [vacationsRemaining, setVacationsRemaining] = useState({
    [vacationTypes[0]]: 0,
    [vacationTypes[1]]: 0,
    [vacationTypes[2]]: 0,
    [vacationTypes[3]]: 0,
  });

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `hr_employees/?tenant_user=${props.user.id}`,
      (data) => {
        if (data.length === 0) {
          setLoadData(false);
          return;
        }
        const employee = data[0];
        setHrEmployee(employee);
        if (employee.vacation_profile_id) {
          http.Get(
            `hr_vacation_profiles/${employee.vacation_profile_id}`,
            (profile) => {
              const currVacationsRemaining = {
                Vacation: profile.max_vacations * 8,
                Sick: profile.max_sick_days * 8,
                Personal: profile.max_personal_days * 8,
                Moving: profile.max_moving_days * 8,
              };
              http.Get(
                `hr_employee_vacations?hr_employee=${employee.id}`,
                (vacations) => {
                  const today = new Date();
                  for (const v of vacations) {
                    if (
                      v.available_hours_used &&
                      v.request_status === "ACCEPTED" &&
                      v.start_date.split("-")[0] == today.getFullYear() &&
                      vacationTypes.some((type) => type === v.vacation_type)
                    ) {
                      currVacationsRemaining[v.vacation_type] -= v.hours;
                    }
                  }
                  setVacationsRemaining(currVacationsRemaining);
                },
                () => {
                  setLoadData(false);
                }
              );
            },
            () => {
              setLoadData(false);
            }
          );
        }
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  const handleArrowClick = () => {
    setCollapse(!collapse);
    props.setTimeOff(collapse);
  };

  const handleSetOpen = (open) => {
    setOpen(open);
  };

  const handleAddClick = () => {
    if (hrEmployee) setOpen(true);
    else {
      props.setSnackBarMessage(
        `HR profile not created yet. Cannot make request.`
      );
      props.setSnackBarOpen(true);
      props.setSeverity("error");
    }
  };

  const handleSubmit = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={11}>
              <span className={classes.title}>TIME OFF AVAILABILITY</span>
            </Grid>
            <Grid item xs={1} className={classes.icons}>
              {(collapse && (
                <ArrowDropDownIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )) || (
                <ArrowDropUpIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )}
            </Grid>
          </Grid>
          <Divider
            classes={{ root: classes.dividers }}
            component="hr"
            orientation="horizontal"
          />
        </Grid>

        {loadData ? (
          <ScreenLoader />
        ) : (
          <Collapse in={!collapse}>
            <Grid item xs={12} className={classes.tableGrid}>
              <TableContainer
                component={Paper}
                classes={{ root: classes.tableContainer }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        VACATION DAYS
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        SICK DAYS
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        PERSONAL DAYS
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className={`${classes.tableCell} ${classes.ellipsis}`}
                        component="th"
                        scope="row"
                      >
                        {vacationsRemaining["Vacation"] / 8}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCell} ${classes.ellipsis}`}
                        component="th"
                        scope="row"
                      >
                        {vacationsRemaining["Sick"] / 8}
                      </TableCell>
                      <TableCell
                        className={`${classes.tableCell} ${classes.ellipsis}`}
                        component="th"
                        scope="row"
                      >
                        {vacationsRemaining["Personal"] / 8}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        align="right"
                        component="th"
                        scope="row"
                      >
                        <Chip
                          label="HISTORY"
                          clickable
                          className={classes.chip}
                          color="primary"
                          onClick={() => props.setShowTimeOffHistory(true)}
                        />
                        <Chip
                          label="REQUEST FORM"
                          clickable
                          className={classes.chip}
                          color="primary"
                          onClick={handleAddClick}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Grid>
          </Collapse>
        )}
      </Grid>

      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <TimeOffRequestForm
          closeOpen={handleSetOpen}
          handleSubmit={handleSubmit}
          hrEmployee={hrEmployee}
          classes={{ paper: classes.drawerForm }}
          setSnackBarMessage={props.setSnackBarMessage}
          setSnackBarOpen={props.setSnackBarOpen}
          setSeverity={props.setSeverity}
        />
      </Drawer>
    </div>
  );
};

export const TimeOffHistoryModal = (props) => {
  const classes = useStyles();
  const [timeOffHistory, setTimeOffHistory] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const today = new Date();

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `hr_employees/?tenant_user=${props.user.id}`,
      (data) => {
        if (data.length === 0) {
          setLoadData(false);
          return;
        }
        const employee = data[0];
        http.Get(
          `hr_employee_vacations?hr_employee=${employee.id}`,
          (vacations) => {
            vacations.filter(
              (v) => v.start_date.split("-")[0] === today.getFullYear()
            );
            vacations.map((v) => {
              if (v.vacation_type === "Other")
                v.vacation_type += ` (${v.reason})`;
            });
            setTimeOffHistory(vacations);
          },
          () => {
            setLoadData(false);
          }
        );
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  return (
    <div>
      {loadData ? (
        <ScreenLoader />
      ) : (
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <Typography
              variant="h6"
              component="h6"
              className={classes.title}
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              Time Off History
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <div style={{ paddingTop: 10, float: "right" }}>
              <IconButton
                className={classes.iconButton}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  }
                }}
              >
                <CancelIcon />
              </IconButton>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Paper>
              <div
                style={{
                  maxHeight: "calc(100vh - 280px)",
                  minHeight: "calc(100vh - 280px)",
                  overflowY: "auto",
                  overflowX: "auto",
                  marginLeft: 10,
                  padding: 10,
                }}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Table className={{ root: classes.table }}>
                      <TableHead>
                        <TableCell
                          className={`${classes.tableCellHeader} ${classes.tableHeader}`}
                        >
                          START DATE
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCellHeader} ${classes.tableHeader}`}
                        >
                          END DATE
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCellHeader} ${classes.tableHeader}`}
                        >
                          TYPE
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCellHeader} ${classes.tableHeader}`}
                        >
                          WAS PAID TIME OFF
                        </TableCell>
                        <TableCell
                          className={`${classes.tableCellHeader} ${classes.tableHeader}`}
                        >
                          REQUEST STATUS
                        </TableCell>
                      </TableHead>
                      {(timeOffHistory.length === 0 && (
                        <TableRow className={classes.tableRow}>
                          <TableCell colSpan={5} align="center">
                            This table is empty.
                          </TableCell>
                        </TableRow>
                      )) || (
                        <>
                          {timeOffHistory.map((vacation) => {
                            return (
                              <TableRow className={classes.tableRow}>
                                <TableCell className={classes.tableCell}>
                                  {vacation.start_date}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {vacation.end_date
                                    ? vacation.end_date
                                    : " N/A (Partial Day)"}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {vacation.vacation_type}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {vacation.available_hours_used ? "YES" : "NO"}
                                </TableCell>
                                <TableCell className={classes.tableCell}>
                                  {vacation.request_status}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </>
                      )}
                    </Table>
                  </Grid>
                </Grid>
              </div>
            </Paper>
            <Divider />
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default TimeOffAvailability;
