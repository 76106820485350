
import React, { useState, useEffect } from "react";
import ObjectForm from "../../../../Common/ObjectForm";
import AlertDialog from "../../../../Common/AlertDialog";
import http from "../../../../Common/RestAPIHandler"
import useStyles from "../../../../Common/Style/FormStyle";
import { Button, Dialog, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import commonConstants from "../../../../Common/CommonConstants";

const VacationProfileForm = props => {
  const classes = useStyles()
  const [formFields, setFormFields] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [obj, setObj] = useState();
  const [error, setError] = useState({});
  const [makeCopy, setMakeCopy] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const { requiredError, blankError } = commonConstants.error;

  useEffect(() => {
      setFormFields([
        { 
          id: "name", 
          label: "NAME *", 
          type: "text", 
        },
        {
          id: "max_vacations",
          label: "MAX VACATIONS *",
          type: "text", 
        },
        {
          id: "max_sick_days",
          label: "MAX SICK DAYS *",
          type: "text", 
        },
        {
          id: "max_personal_days",
          label: "MAX PERSONAL DAYS *",
          type: "text", 
        },
        {
          id: "max_moving_days",
          label: "MAX MOVING DAYS *",
          type: "text",
        },
      ]);
  }, []);

  const put = () => {
    http.Put(
      `hr_vacation_profiles/${obj.id}`,
      obj,
      (object) => { props.handleObjectSubmit() },
      (status, error) => {
        if (error["non_field_errors"]) {
          alert("Name already in use. Please enter another one.");
        }
      }
    );
  }

  const onSubmit = () => {
    http.Post(
      `hr_vacation_profiles`,
      obj,
      (object) => { props.handleObjectSubmit() },
      (status, error) => {
        setError(error);
        setIsSubmitted(false);
      }
    )
  } 

  const handleSave = () => {
    setAlertOpen(false);
    put();
  }

  const handleCopy = () => {
    setMakeCopy(true);
  }

  const onDisagree = () => {
    setAlertOpen(false);
    setMakeCopy(false);
  }

  const onChange = (e) => {
    setObj({...obj, [e.target.name]: e.target.value});
    setIsSubmitted(false);
    setError({...error, [e.target.name]: false, non_field_errors: false});
  }

  return(
    <>
      {obj && (
        <Dialog
          open={alertOpen && makeCopy && Boolean(obj)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          onClose={onDisagree}
        >
          <DialogTitle id="alert-dialog-title">Enter a new name:</DialogTitle>
          <DialogContent>
            <ValidatorForm onSubmit={onSubmit}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={3} align="right">
									<div style={{ fontWeight: 520 }}>
										<span className={classes.title}>NAME *</span>
									</div>
								</Grid>
                <Grid item xs={9}>
                  <TextValidator
                    id="name"
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    name="name"
                    value={obj.name || ""}
                    onChange={onChange}
                    inputProps={{ maxLength: 255 }}
                    validators={["required", "trim"]}
                    errorMessages={[requiredError, blankError]}
                    error={Boolean(error.name)}
                    helperText={
                      (error.name && error.name[0]) || (
                       error.non_field_errors && <span className={classes.error}>Name already in use.</span>
                    )}
                  />
                </Grid>
              </Grid>
              <div className={classes.footer}>
                <div className={classes.outlinedButtonContainer}>
                  <Button
                    variant="outlined"
                    margin="dense"
                    size="small"
                    className={classes.outlinedButton}
                    onClick={onDisagree}
                  >
                    Cancel
                </Button>
                  <Button
                    variant="outlined"
                    margin="dense"
                    size="small"
                    className={classes.filledButton}
                    type="submit"
                    disabled={isSubmitted}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </DialogContent>
        </Dialog>
      )}
      <AlertDialog
          open={alertOpen && !makeCopy}
          primaryMessage="Continue saving or create a new copy?"
          secondaryMessage="Saving this change to the profile will affect all associated users including their historical reports."
          onSave={handleSave}
          onCopy={handleCopy}
          onDisagree={onDisagree}
          isVacationProfileEdit
        />
      {formFields && 
      <ObjectForm 
        {...props} 
        url={`hr_vacation_profiles`} 
        formName={"Vacation Profile"} 
        formFields={formFields} 
        object={{...props.object, tenant: props.user.tenant}}
        setAlertOpen={props.objectId ? setAlertOpen : null}
        setObj={setObj}
      />}
    </>
  );
};

export default VacationProfileForm;
