import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {Divider, Typography, Paper, Grid, IconButton, Snackbar,} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FilterNoneIcon from "@material-ui/icons/FilterNone";
import { onCopy } from "../../Common/TableUtility";
import Alert from "@material-ui/lab/Alert";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";
import OTP from "../../Common/OTP";

import ServiceForm from "./ServiceForm";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CreateIcon from "@material-ui/icons/Create";

const ServiceDetails = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;
    var { serviceId } = props.match.params;  
    const [service, setService] = useState({});
	const [dataMappings, setDataMappings] = useState([]);
    const [message, setMessage] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if(props.objectId){
        serviceId = props.objectId;
      }
      http.Get(
        `companies/${id}/services/${serviceId}`,
        (service) => {
            setService(service);

            http.Get(
              `data_mappings?from_id=${service.id}`,
              (data) => {
                setDataMappings(data);
                setLoading(false);
              },
              () => {
              }
            );
        },
        () => {}
      );
    }, []);

    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState(400);
    const [startX, setStartX] = useState();
    const [endX, setEndX] = useState();
  
    const onDragStart = (e) => {
      setStartX(e.screenX);
    };
  
    const onDragEnd = (e) => {
      setEndX(e.screenX);
    };
  
    useEffect(() => {
      if(startX && endX){
        setWidth(width + startX - endX);
      }
    }, [startX, endX]);
    
    useEffect(() => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      setWidth(vw * 0.8);
    }, [window.innerWidth]);
  
    const handleObjectSubmit = () => {
      window.location.reload(false);
    };

    return (
        <>
        {loading &&
            <ScreenLoader/>
        }
        {!loading &&
        <Grid container spacing={1} className={classes.paper}>
            <Grid item xs={10}>
                <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}}>
                    {service.service_name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <div style={{paddingTop:10,float:"right"}}>
                    <IconButton
                        className={classes.iconButtonColored}
                        onClick={() => {
                            if(props.onClose){
                                props.onClose();
                            }
                            else{
                                props.history.push(
                                    process.env.PUBLIC_URL + `/company/${id}/other_services`
                            );
                            }
                        }}
                    >
                    <HighlightOffIcon />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12}>   
                <Divider/>
            </Grid>
            <Grid item xs={props.mobile?12:10}>
                <Paper>
                    <div
                        style={{ maxHeight: "calc(100vh - 280px)",
                            minHeight: "calc(100vh - 280px)",
                            overflowY: "auto",
                            overflowX: "auto",  
                            marginLeft:10,
                            padding:10
                        }}
                    >
                        <Snackbar
                            open={Boolean(message)}
                            autoHideDuration={3000}
                            onClose={() => {
                                setMessage(null);
                            }}
                            anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                            <Alert
                                onClose={() => {
                                    setMessage(null);
                                }}
                                severity="success"
                                elevation={6}
                                variant="filled"
                            >
                                {message}
                            </Alert>
                        </Snackbar>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>SERVICE NAME</span>
                            </Grid>
                            <Grid item xs={8}>
                                {service.service_name}                 
                    
                                <IconButton
                                    className={classes.iconButtonColored}
                                    style={{float:"right"}}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <CreateIcon />
                                </IconButton>
                                <Drawer
                                    anchor="right"
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    disableEnforceFocus
                                    classes={{
                                        paper: classes.drawerForm,
                                    }}
                                >
                                    <div style={{ width: width }}>
                                        <table>
                                        <tbody>
                                            <tr>
                                            <td
                                                draggable
                                                style={{ cursor: "ew-resize" }}
                                                onDragStart={onDragStart}
                                                onDragEnd={onDragEnd}
                                            >
                                                <DragIndicatorIcon />
                                            </td>
                                            <td width="100%">
                                                <ServiceForm
                                                    {...props}
                                                    closeOpen={(open) => setOpen(open)}
                                                    objectId={props.objectId}
                                                    handleObjectSubmit={handleObjectSubmit}
                                                />
                                            </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </Drawer>
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>SERVICE TYPE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {service.category_name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>DESCRIPTION</span>
                            </Grid>
                            <Grid item xs={8}>
                                {service.description}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>WEBSITE</span>
                            </Grid>
                            <Grid item xs={8}>
                                <FilterNoneIcon
                                    className={classes.iconButtonColored}
                                    fontSize="small"
                                    style={{
                                        cursor: "pointer",
                                        width: 15,
                                        verticalAlign: "bottom",
                                    }}
                                    onClick={() => {
                                        onCopy(props, service, "website");
                                        setMessage("Website Copied");
                                    }}
                                />&nbsp;&nbsp;{service.website}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>USERNAME</span>
                            </Grid>
                            <Grid item xs={8}>                                
                                <FilterNoneIcon
                                    className={classes.iconButtonColored}
                                    fontSize="small"
                                    style={{
                                        cursor: "pointer",
                                        width: 15,
                                        verticalAlign: "bottom",
                                    }}
                                    onClick={() => {
                                        onCopy(props, service, "username");
                                        setMessage("Username Copied");
                                    }}
                                />&nbsp;&nbsp;{service.username}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>PASSWORD</span>
                            </Grid>
                            <Grid item xs={8}>
                                <FilterNoneIcon
                                    className={classes.iconButtonColored}
                                    fontSize="small"
                                    style={{
                                        cursor: "pointer",
                                        width: 15,
                                        verticalAlign: "bottom",
                                    }}
                                    onClick={() => {
                                        onCopy(props, service, "password");
                                        setMessage("Password Copied");
                                    }}
                                />&nbsp;&nbsp;
                                <span
                                    className={classes.cursor}
                                    onClick={(e) => {
                                        if (e.target.innerHTML === "············") {
                                            e.target.innerHTML = String(service.password).replaceAll("<","&lt;").replaceAll(">","&gt;");
                                        } else {
                                            e.target.innerHTML = "············";
                                        }
                                    }}
                                >
                                    ············
                                </span>
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>NOTES</span>
                            </Grid>
                            <Grid item xs={8} style={{whiteSpace:"pre-wrap"}}>
                                {service.notes}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>OTP</span>
                            </Grid>
                            <Grid item xs={8}>
                                <OTP {...props} otpKey={service.otp}/>
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>LOCATION</span>
                            </Grid>
                            <Grid item xs={8}>
                                {service.site_name}
                            </Grid>
                        </Grid>                        
                    </div>
                </Paper>
                <Divider/>
            </Grid>
            {!props.mobile &&
            <Grid item xs={2}>
                <Paper>
                <div
                    style={{ maxHeight: "calc(100vh - 280px)",
                        minHeight: "calc(100vh - 280px)",
                        overflowY: "auto",
                        overflowX: "auto", 
                        marginLeft:10,
                        padding:10,
                    }}
                >
                <p style={{fontWeight:"bold"}}>Related Entries:</p>
                <DataMappings 
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
              </div>
              </Paper>
          </Grid>
            }
        </Grid>
        }
        </>
    );
};

export default ServiceDetails;