import React, { useEffect, useState } from "react";
import PortalUserForm from "./PortalUserForm";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import commonConstants from "../../Common/CommonConstants";
import EmailIcon from '@material-ui/icons/Email';
import SnackbarComponent from "../../Common/Snackbar";
import { formatDateTime } from "../../Common/Utility";
import { Tooltip } from "@material-ui/core";

const PortalUsers = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [severity, setSeverity] = useState("success");
  const url = 'company_employees';

  const actions = {
    delete: false,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };
  
  const tableColumns = [
    {
      id: "employee_name",
      label: "NAME",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "company_name",
      label: "COMPANY",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "role_name",
      label: "ROLE",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "is_billing_contact",
      label: "BILLING CONTACT",
      minWidth: 50,
      colored: true,
      sortable: true,
    },
    {
      id: "last_login",
      label: "LAST LOGIN",
      minWidth: 175,
      sortable: true,
    },
    {
      id: "resend_email",
      label: "",
      minWidth: 24,
      style: commonConstants.cellCursor,
      colored: true,
      sortable: false,
    }
  ];

  const resendEmail = (employee_id, email) => {
    http.Get(
      `company_employees/resend_invite/${employee_id}`,
      () => handleSnackBar(`Invite resent to ${email}.`, 'success'),
      (status, error) => handleSnackBar(error, 'error')
    );
  };

  const handleSnackBarClose = () => {
    setSnackBarOpen(false);
  };

  const handleSnackBar = (message, severity) => {
    setSnackBarOpen(true);
    setSnackBarMessage(message);
    setSeverity(severity);
  };

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      `company_employees?has_portal_access=ture`,
      (employees) => {
        employees.map((employee) => {
          employee['is_billing_contact'] = employee['is_billing_contact'] ? <CheckCircleIcon/> : "";
          employee['last_login'] = employee['last_login'] ? formatDateTime(employee['last_login']) : "NEVER LOGGED IN"
          employee['resend_email'] = 
            <Tooltip title="Resend Invite">
              <EmailIcon onClick={ employee['role_id'] && employee['email'] ? 
                                   () => resendEmail(employee.id, employee.email) : 
                                   () => {
                                     let message;
                                      if (!employee['role_id'] && employee['email']) message = `Please set portal role for ${employee.employee_name}.`;
                                      else if (employee['role_id'] && !employee['email']) message = `Please set email for ${employee.employee_name}.`;
                                      else message = `Please set portal role and email for ${employee.employee_name}.`;
                                      handleSnackBar(message, 'error') 
                                    }
                                  } 
              />
            </Tooltip>;
        });
        setData(employees);
        setLoadData(false);
      },
      () => {}
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
    <SnackbarComponent
        message={snackBarMessage}
        open={snackBarOpen}
        handleSnackBarClose={handleSnackBarClose}
        severity={severity}
    ></SnackbarComponent>
    {!props.mobile &&      
      <ObjectList
        {...props}
        moduleName={"Portal Users"}
        url={url}
        permissions={{
          create: ["Manage Portal Users"],
          edit: ["Manage Portal Users"],
          delete: ["Manage Portal Users"],
        }}
        objectForm={PortalUserForm}
        tableColumns={tableColumns}
        searchFields={["employee_name", "company_name", "role_name"]}
        orderBy={"employee_name"}
        data={data}
        fetchData={fetchData}
        pageHeader={(props.user.company_name || "") + " | Portal Management"}
        loadData={loadData}
        excludeAddButton={true}
      />
    }
    {props.mobile &&      
      <ObjectListMobile
        {...props}
        moduleName={"Portal Users"}
        url={url}
        permissions={{
          create: ["Manage Portal Users"],
          edit: ["Manage Portal Users"],
          delete: ["Manage Portal Users"],
        }}
        objectForm={PortalUserForm}
        tableColumns={tableColumns}
        searchFields={["employee_name", "company_name", "role_name"]}
        orderBy={"employee_name"}
        data={data}
        fetchData={fetchData}
        pageHeader={(props.user.company_name || "") + " | Portal Management"}
        loadData={loadData}
        excludeAddButton={true}
      />
    }
    </>
  );
};

export default PortalUsers;
