import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  TextValidator,
  SelectValidator,
  ValidatorForm,
} from "react-material-ui-form-validator";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import useStyles from "./style";
import http from "../../Common/RestAPIHandler";
import commonConstants from "../../Common/CommonConstants";
import Select from "@material-ui/core/Select";
import ScreenLoader from "../../Common/Loader";
import Input from "@material-ui/core/Input";

const CompanySettings = (props) => {
  const classes = useStyles();
  const [settings, setSettings] = useState({});
  const [settingsError, setSettingsError] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [address, setAddress] = useState({});
  const [logos, setLogos] = useState({});
  const [loadData, setLoadData] = useState(false);
  const { requiredError, fileError, imageFormatError, imageSizeError } =
    commonConstants.error;

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `tenants/${props.user.tenant}`,
      (data) => {
        delete data.address;
        setSettings(data);
        if (data.top_nav_back_colour == "Light") {
          localStorage.setItem("isDarkEnabled", false);
        } else {
          localStorage.setItem("isDarkEnabled", true);
        }
        localStorage.setItem(
          "colorScheme",
          JSON.stringify(data.top_nav_text_colour || "Blue")
        );
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
    http.Get(
      `tenant_address`,
      (data) => {
        setAddressList(data);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    var newAddr = addressList.filter(
      (item) => item.id === settings.business_main_location
    );
    if (newAddr && newAddr.length > 0) setAddress(newAddr[0]);
  }, [addressList, settings]);

  const onChange = (e) => {
    if (e.target.name === "business_main_location") {
      var newAddr = addressList.filter((item) => item.id === e.target.value)[0];
      setAddress(newAddr);
    }
    setSettings({
      ...settings,
      [e.target.name]: e.target.value,
    });
    setSettingsError({
      ...settingsError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const isValidImage = (name, value) => {
    if (value && !(value instanceof File)) {
      setSettingsError({
        ...settingsError,
        [name]: [fileError],
      });
      return false;
    }
    if (value && !value.name.match(/\.(png|PNG|jpeg|JPEG|jpg|JPG)$/)) {
      setSettingsError({
        ...settingsError,
        [name]: [imageFormatError],
      });
      return false;
    }
    if (value && value.size > parseInt(1 * 1024 * 1024, 10)) {
      setSettingsError({
        ...settingsError,
        [name]: [imageSizeError],
      });
      return false;
    }
    return true;
  };

  const onLogoChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    if (file) {
      if (!isValidImage(name, file)) {
        setLogos({
          ...logos,
          [name]: "",
          [name + "_name"]: file.name,
          [name + "_file"]: file,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = function () {
        setLogos({
          ...logos,
          [name]: reader.result,
          [name + "_name"]: file.name,
          [name + "_file"]: file,
        });
      };
      reader.readAsDataURL(file);
    } else {
      setLogos({
        ...logos,
        [name]: "",
        [name + "_name"]: "",
        [name + "_file"]: "",
      });
    }
    setSettingsError({
      ...settingsError,
      [e.target.name]: false,
    });
    setIsSubmitted(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (settingsError.logo || settingsError.mobile_logo) return;

    const logo = logos.logo ? logos.logo : settings.logo;
    const mobile_logo = logos.mobile_logo
      ? logos.mobile_logo
      : settings.mobile_logo;

    const settingsdata = {
      ...settings,
      logo: logo,
      mobile_logo: mobile_logo,
    };

    setIsSubmitted(true);
    http.Put(
      `tenants/${props.user.tenant}`,
      settingsdata,
      (data) => {
        setSettings(data);
        localStorage.setItem("date_format", data.date_format);
        localStorage.setItem("date_time_format", data.date_time_format);
        localStorage.setItem("notification_days", data.notification_days);
        if (data.top_nav_back_colour == "Light") {
          localStorage.setItem("isDarkEnabled", false);
        } else {
          localStorage.setItem("isDarkEnabled", true);
        }
        localStorage.setItem(
          "colorScheme",
          JSON.stringify(data.top_nav_text_colour || "Blue")
        );

				setIsSubmitted(false);
				window.location.reload();
			},
			(status, error) => {
				if (status === 400) setSettingsError(error);
				setIsSubmitted(false);
			}
		);
	};

	return (
		<div className={classes.root}>
			<ValidatorForm onSubmit={onSubmit}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<span className={classes.subTitle}>{props.user.company_name} </span> 
            			<span className={classes.link}>{" "}|{" "}</span>
						<span className={classes.subTitle}> Company Details</span>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h4" noWrap classes={{ h4: classes.title }}>
							Company Details
						</Typography>
					</Grid>
					<Grid item xs={12}>
						{loadData ? (
							<ScreenLoader />
						) : (
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Grid container spacing={2}>
										<Grid item xs={12}>
											<Grid container spacing={2} alignItems="center">
												<Grid
													item
													xs={4}
													sm={2}
													md={2}
													lg={2}
													className={classes.rightAlign}
												>
													<span className={classes.title}>
														ID
													</span>
												</Grid>
												<Grid item xs={8} sm={6} md={5} lg={5}>
													<span className={classes.title}>
														{settings.id}
													</span>													
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Grid container spacing={2} alignItems="center">
												<Grid
													item
													xs={4}
													sm={2}
													md={2}
													lg={2}
													className={classes.rightAlign}
												>
													<span className={classes.title}>Logo</span>
												</Grid>
												<Grid item xs={8} sm={4} md={4} lg={3}>
													<Button
														variant="text"
														fullWidth
														component="label"
														classes={{ label: classes.viewButtonLabel }}
														className={classes.uploadButton}
														color="primary"
													>
														<span className={classes.link}>Upload logo</span>
														<Input
															type="file"
															inputProps={{
																accept: "image/png, image/jpeg, image/jpg",
															}}
															name="logo"
															onChange={onLogoChange}
															className={classes.inputButton}
														/>
													</Button>
												</Grid>

                        <Grid className={classes.logoCaption}>
                          <Typography
                            variant="caption"
                            classes={{ caption: classes.subTitle }}
                          >
                            This logo will appear on the documents (estimates,
                            invoices, etc) that are created.
                          </Typography>
                          <div className={classes.smallText}>
                            Preferred Image Size: 240px x 240px @72 DPI Maximum
                            size of 1MB.
                          </div>
                        </Grid>
                      </Grid>

                      {Boolean(settingsError.logo) && (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                          <Grid item style={{ color: "red", fontSize: 12 }}>
                            {settingsError.logo[0]}
                          </Grid>
                        </Grid>
                      )}

                      {logos.logo_name && (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                          <Grid item>{logos.logo_name}</Grid>
                        </Grid>
                      )}
                    </Grid>

										<Grid item xs={12}>
											<Grid container spacing={2} alignItems="center">
												<Grid
													item
													xs={4}
													sm={2}
													md={2}
													lg={2}
													className={classes.rightAlign}
												>
													<span className={classes.title}>Mobile Logo</span>
												</Grid>
												<Grid item xs={8} sm={4} md={4} lg={3}>
													<Button
														variant="text"
														fullWidth
														component="label"
														classes={{ label: classes.viewButtonLabel }}
														className={classes.uploadButton}
														color="primary"
													>
                            							<span className={ classes.link }>Upload mobile logo</span>
														<Input
															type="file"
															inputProps={{
																accept: "image/png, image/jpeg, image/jpg",
															}}
															name="mobile_logo"
															onChange={onLogoChange}
															className={classes.inputButton}
														/>
													</Button>
												</Grid>
											</Grid>

                      {Boolean(settingsError.mobile_logo) && (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                          <Grid item style={{ color: "red", fontSize: 12 }}>
                            {settingsError.mobile_logo[0]}
                          </Grid>
                        </Grid>
                      )}

                      {logos.mobile_logo_name && (
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                          <Grid item xs>
                            {logos.mobile_logo_name}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          <span className={classes.title}>
                            Organization Name*
                          </span>
                        </Grid>
                        <Grid item xs={8} sm={6} md={5} lg={5}>
                          {/* Use Global Theme level overrides for this component, rest use InputProps */}
                          <TextValidator
                            id="company_name"
                            fullWidth
                            margin="dense"
                            variant="outlined"
                            name="company_name"
                            value={settings.company_name || ""}
                            onChange={onChange}
                            InputProps={{
                              className: classes.input,
                              outlined: classes.input,
                            }}
                            validators={["required"]}
                            errorMessages={[requiredError]}
                            error={Boolean(settingsError.company_name)}
                            helperText={
                              settingsError.company_name &&
                              settingsError.company_name[0]
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          <span className={classes.title}>
                            Business Main Location*
                          </span>
                        </Grid>
                        <Grid item xs={8} sm={6} md={5} lg={5}>
                          <SelectValidator
                            className={classes.input}
                            fullWidth
                            name="business_main_location"
                            variant="outlined"
                            margin="dense"
                            value={settings.business_main_location || ""}
                            onChange={onChange}
                            validators={["required"]}
                            errorMessages={[requiredError]}
                            InputProps={{ className: classes.input }}
                            error={Boolean(
                              settingsError.business_main_location
                            )}
                            helperText={
                              settingsError.business_main_location &&
                              settingsError.business_main_location[0]
                            }
                          >
                            {addressList && addressList.length === 0 ? (
                              <MenuItem value={""} selected disabled>
                                No addresses found
                              </MenuItem>
                            ) : (
                              addressList.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                  {option.address}
                                </MenuItem>
                              ))
                            )}
                          </SelectValidator>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      {address && (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={2}
                                lg={2}
                                className={classes.rightAlign}
                              >
                                <span className={classes.title}>
                                  Company Address
                                </span>
                              </Grid>
                              <Grid item xs={8} sm={9} md={9} lg={9}>
                                <TextValidator
                                  disabled
                                  id="address"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  onChange={onChange}
                                  name="address"
                                  value={address.address || ""}
                                  InputProps={{ className: classes.input }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={2}
                                lg={2}
                                className={classes.rightAlign}
                              ></Grid>
                              <Grid
                                item
                                xs={8}
                                sm={9}
                                md={9}
                                lg={9}
                                className={classes.address}
                              >
                                <TextValidator
                                  disabled
                                  id="country"
                                  fullWidth
                                  margin="dense"
                                  variant="outlined"
                                  onChange={onChange}
                                  name="country"
                                  value={address.country || ""}
                                  InputProps={{ className: classes.input }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                              <Grid
                                item
                                xs={4}
                                sm={2}
                                md={2}
                                lg={2}
                                className={classes.rightAlign}
                              ></Grid>
                              <Grid item xs={8} sm={9} md={9} lg={9}>
                                <Grid container spacing={2} alignItems="center">
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    className={classes.address}
                                  >
                                    <TextValidator
                                      disabled
                                      id="state"
                                      fullWidth
                                      margin="dense"
                                      variant="outlined"
                                      onChange={onChange}
                                      name="state"
                                      value={address.state || ""}
                                      InputProps={{ className: classes.input }}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    className={classes.address}
                                  >
                                    <TextValidator
                                      disabled
                                      id="city"
                                      fullWidth
                                      name="city"
                                      variant="outlined"
                                      margin="dense"
                                      value={address.city || ""}
                                      InputProps={{ className: classes.input }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    className={classes.address}
                                  >
                                    <TextValidator
                                      disabled
                                      id="postal"
                                      fullWidth
                                      margin="dense"
                                      placeholder="Zip/Postal Code"
                                      variant="outlined"
                                      onChange={onChange}
                                      name="postal"
                                      value={address.postal || ""}
                                      InputProps={{ className: classes.input }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    className={classes.address}
                                  >
                                    <TextValidator
                                      disabled
                                      id="phone"
                                      fullWidth
                                      margin="dense"
                                      placeholder="Phone"
                                      variant="outlined"
                                      onChange={onChange}
                                      name="phone"
                                      value={address.phone || ""}
                                      InputProps={{ className: classes.input }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    className={classes.address}
                                  >
                                    <TextValidator
                                      disabled
                                      id="fax"
                                      fullWidth
                                      margin="dense"
                                      placeholder="Fax"
                                      variant="outlined"
                                      onChange={onChange}
                                      name="fax"
                                      value={address.fax || ""}
                                      InputProps={{ className: classes.input }}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={4}
                                    className={classes.address}
                                  >
                                    <TextValidator
                                      disabled
                                      id="website"
                                      fullWidth
                                      margin="dense"
                                      placeholder="Website"
                                      variant="outlined"
                                      onChange={onChange}
                                      name="website"
                                      value={address.website || ""}
                                      InputProps={{ className: classes.input }}
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          <span className={classes.title}>Theme</span>
                        </Grid>
                        <Grid item xs={8} sm={3} md={3} lg={3}>
                          <SelectValidator
                            className={classes.input}
                            fullWidth
                            name="top_nav_back_colour"
                            variant="outlined"
                            value={settings.top_nav_back_colour || ""}
                            onChange={onChange}
                            validators={["required"]}
                            errorMessages={[requiredError]}
                            InputProps={{ className: classes.input }}
                            error={Boolean(settingsError.top_nav_back_colour)}
                            helperText={
                              settingsError.top_nav_back_colour &&
                              settingsError.top_nav_back_colour[0]
                            }
                          >
                            <MenuItem value={"Dark"}>Dark</MenuItem>
                            <MenuItem value={"Light"}>Light</MenuItem>
                          </SelectValidator>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          <span className={classes.title}>Color Scheme</span>
                        </Grid>
                        <Grid item xs={8} sm={3} md={3} lg={3}>
                          <SelectValidator
                            fullWidth
                            name="top_nav_text_colour"
                            variant="outlined"
                            value={settings.top_nav_text_colour || ""}
                            onChange={onChange}
                            validators={["required"]}
                            errorMessages={[requiredError]}
                            InputProps={{ className: classes.input }}
                            error={Boolean(settingsError.top_nav_text_colour)}
                            helperText={
                              settingsError.top_nav_text_colour &&
                              settingsError.top_nav_text_colour[0]
                            }
                          >
                            <MenuItem value={"Orange"}>Orange</MenuItem>
                            <MenuItem value={"Blue"}>Blue</MenuItem>
                          </SelectValidator>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          <span className={classes.title}>Date Format</span>
                        </Grid>
                        <Grid item xs={8} sm={3} md={3} lg={3}>
                          <Select
                            className={classes.input}
                            fullWidth
                            name="date_format"
                            variant="outlined"
                            value={settings.date_format || ""}
                            onChange={onChange}
                            error={Boolean(settingsError.date_format)}
                            helperText={
                              settingsError.date_format &&
                              settingsError.date_format[0]
                            }
                          >
                            <MenuItem value={"MMMM dd, yyyy"}>
                              MMMM dd, yyyy
                            </MenuItem>
                            <MenuItem value={"yyyy-MM-dd"}>yyyy-MM-dd</MenuItem>
                          </Select>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          <span className={classes.title}>
                            Date Time Format
                          </span>
                        </Grid>
                        <Grid item xs={8} sm={3} md={3} lg={3}>
                          <Select
                            className={classes.input}
                            fullWidth
                            name="date_time_format"
                            variant="outlined"
                            value={settings.date_time_format || ""}
                            onChange={onChange}
                            error={Boolean(settingsError.date_time_format)}
                            helperText={
                              settingsError.date_time_format &&
                              settingsError.date_time_format[0]
                            }
                          >
                            <MenuItem value={"MMMM dd, yyyy hh:mm a"}>
                              MMMM dd, yyyy hh:mm a
                            </MenuItem>
                            <MenuItem value={"yyyy-MM-dd HH:mm"}>
                              yyyy-MM-dd HH:mm
                            </MenuItem>
                          </Select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          <span className={classes.title}>
                            Notification Days
                          </span>
                        </Grid>
                        <Grid item xs={8} sm={3} md={3} lg={3}>
                          <Select
                            className={classes.input}
                            fullWidth
                            name="notification_days"
                            variant="outlined"
                            value={settings.notification_days || ""}
                            onChange={onChange}
                            error={Boolean(settingsError.notification_days)}
                            helperText={
                              settingsError.notification_days &&
                              settingsError.notification_days[0]
                            }
                          >
                            <MenuItem value={30}>30</MenuItem>
                            <MenuItem value={15}>15</MenuItem>
                          </Select>
                        </Grid>
                        <Grid
                          item
                          xs={4}
                          sm={2}
                          md={2}
                          lg={2}
                          className={classes.rightAlign}
                        >
                          {/* <span className={classes.title}>
                            Azure Single Sign-on
                          </span> */}
                        </Grid>
                        <Grid item xs={8} sm={3} md={3} lg={3}>
                          {/* <Select
                            className={classes.input}
                            fullWidth
                            name="single_signon"
                            variant="outlined"
                            value={settings.single_signon}
                            onChange={onChange}
                            // error={Boolean(settingsError.notification_days)}
                            helperText={settings.single_signon && settings.single_signon}
                          >
                            <MenuItem value={true}>On</MenuItem>
                            <MenuItem value={false}>Off</MenuItem>
                          </Select> */}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sm={2} md={2} lg={2}></Grid>
                    <Grid item xs={4} sm={2} lg={1}>
                      <Button
                        className={classes.saveButton}
                        variant="outlined"
                        margin="dense"
                        size="small"
                        type="submit"
                        onChange={onChange}
                        disabled={isSubmitted}
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </ValidatorForm>
    </div>
  );
};

export default CompanySettings;
