import React, { useEffect, useState } from "react";
import http from "../../Common/RestAPIHandler";
import DocumentForm from "./DocumentForm";
import Grid from "@material-ui/core/Grid";
import CancelIcon from "@material-ui/icons/Cancel";
import useStyles from "../../Common/Style/FormStyle";
import IconButton from "@material-ui/core/IconButton";
import { Divider, Radio, RadioGroup, Typography, Paper } from "@material-ui/core";
import FileViewer from "../../Common/FileViewer";
import { Dialog } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import { formatDateTime } from "../../Common/Utility";

const DocumentDetails = (props) => {
  const classes = useStyles();
  const { id } = props.match.params;
  const [data, setData] = useState([]);
  const [content, setContent] = useState();
  const [filePath, setFilePath] = useState();
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();


  useEffect(() => {
    http.Get(
      "tenant_documents",
      (data) => {
        setData(data);
      },
      () => {
      }
    );
    http.Get(
      `tenant_documents/${id}`,
      (item) => {
        http.Get(
          `tenant_document_files?tenant_document=${item.id}`,
          (files) => {
            item["files"] = files;
    
            http.Get(
              "tenant_document_versions?document_id=" + item.id,
              (data) => {
                var valueMap = {};
                for (let i = 0; i < data.length; i++) {
                  data[i].serialized_data = JSON.parse(data[i].serialized_data);
                  valueMap[data[i].serialized_data[0].fields.content] =
                    formatDateTime(data[i].serialized_data[0].fields.last_modified_time);
                }
                item["versions"] = valueMap;
                setContent(item);
              },
              () => {}
            );
    
          },
          () => {
          }
        );
      },
      () => {}
    );
    
  }, []);

  const onDragStart = (e) => {
    setStartX(e.screenX);
  };

  const onDragEnd = (e) => {
    setEndX(e.screenX);
  };

  useEffect(() => {
    if(startX && endX){
      setWidth(width + startX - endX);
    }
  }, [startX, endX]);
  
  useEffect(() => {
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    setWidth(vw * 0.8);
  }, [window.innerWidth]);

  const handleObjectSubmit = () => {
    window.location.reload(false);
  };

  return (
    <div>
      {filePath &&
        <Dialog
          fullWidth
          maxWidth="md"
          open={true}
          onClose={() => {
            setFilePath(null);
          }}>
          <FileViewer
            fileType={filePath.fileType.toLowerCase()}
            filePath={filePath.filePath}
            onError={() => {
              setFilePath(null);
            }}
          />
        </Dialog>
      }
      {content && (
        <div>
          <Drawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            disableEnforceFocus
            classes={{
              paper: classes.drawerForm,
            }}
          >
            <div style={{ width: width }}>
              <table>
                <tbody>
                  <tr>
                    <td
                      draggable
                      style={{ cursor: "ew-resize" }}
                      onDragStart={onDragStart}
                      onDragEnd={onDragEnd}
                    >
                      <DragIndicatorIcon />
                    </td>
                    <td width="100%">
                      <DocumentForm
                        {...props}
                        closeOpen={(open) => setOpen(open)}
                        objectId={content.id}
                        handleObjectSubmit={handleObjectSubmit}
                        template={{data:''}}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Drawer>
        <Grid container spacing={1}>
          <Grid item xs={10}>
            <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}} classes={{ h6: classes.title }}>
              {content.title}
            </Typography>
            <span className={classes.subText} >{formatDateTime(content.last_modified_time)} by {content.last_modified_by_name}</span>
          </Grid>
          <Grid item xs={2}>
            <div style={{paddingTop:10,float:"right"}}>
                <IconButton
                  className={classes.iconButtonColored}
                  onClick={() => {
                      setOpen(true);
                  }}
                >
                  <CreateIcon />
                </IconButton>
              
                <IconButton
                  className={classes.iconButtonColored}
                  onClick={() => {
                    var divToPDF = document.getElementById('divToPDF');
                    html2canvas(divToPDF)
                      .then((canvas) => {
                        var imgData = canvas.toDataURL('image/png');
                        var imgWidth = 210; 
                        var pageHeight = 292;
                        var imgHeight = canvas.height * imgWidth / canvas.width;
                        var heightLeft = imgHeight;
                        var doc = new jsPDF('p', 'mm');
                        var position = 0;
                        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                        heightLeft -= pageHeight;
                        
                        while (heightLeft >= 0) {
                          position = heightLeft - imgHeight;
                          doc.addPage();
                          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                          heightLeft -= pageHeight;
                        }
                        doc.save(content.id+".pdf");
                      })
                    ;
                  }}
                >
                  <SaveAltIcon />
                </IconButton>
                
                <IconButton
                  className={classes.iconButton}
                  onClick={() => {
                    props.history.push(
                      process.env.PUBLIC_URL + `/documents`
                    );}}
                >
                  <CancelIcon />
                </IconButton>     
            </div>
          </Grid>
          <Grid item xs={12}>   
            <Divider/>
          </Grid>
          <Grid item xs={10}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                <p
                  style={{ maxHeight: "calc(100vh - 350px)",
                  minHeight: "calc(100vh - 350px)",
                  overflowY: "auto",
                  overflowX: "auto", 
                  marginLeft:10}}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
                </Paper>
                <div id="divToPDF"
                  style={{ position:"absolute",
                    left:"-8000px",
                    width:"780px",
                    padding:"30px"}}
                  dangerouslySetInnerHTML={{ __html: content.content }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Paper>
                <p
                    style={{ maxHeight: "calc(100vh - 350px)",
                    minHeight: "calc(100vh - 350px)",
                    overflowY: "auto",
                    overflowX: "auto",
                    marginLeft:10 }}
                >
                <p style={{fontWeight:"bold"}}>Attachments:</p>
                  {content.files && content.files.map((file) => (
                    <p key={file.id}>
                      <a className={classes.link} href="#" onClick={()=>{
                        if(file.file_src){
                          setFilePath({filePath:file.file_src,fileType:file.file.split('?')[0].split('.').pop()});
                        }
                        else{
                          alert("File Not Found!");
                        }
                        }}>
                        {file.name}
                      </a>
                      <IconButton
                        className={classes.iconButtonColored}
                        onClick={() => {
                          if(file.file_src){
                            var a = window.document.createElement("a");
                            a.href = file.file_src;
                            a.download = file.name;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                          }
                          else{
                            alert("File Not Found!");
                          }
                        }}
                      >
                        <SaveAltIcon/>
                      </IconButton>
                    </p>
                  ))}
                <br/>
                <p style={{fontWeight:"bold"}}>Related Documents:</p>
                  {content.related_documents && content.related_documents.split(",").map((doc) => {
                    var items = data.filter((d) => {return d.id===doc});
                    if(items.length === 1){
                      var item = items[0];
                      return (
                        <p key={doc}>
                          {item["title"]}
                        </p>
                      )
                    }
                  })}
                  <br/>
                <p style={{fontWeight:"bold"}}>Versions:</p>
                  <RadioGroup>
                    {content.versions && Object.entries(content.versions).map(([key, value]) => (
                      <span key={key}>
                        <Radio value={value} onClick={() => {setContent({...content,"content":key});}}/>{value}
                      </span>
                    ))}
                  </RadioGroup>
                </p>
                
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        </div>
      )}
    </div>
  );
};

export default DocumentDetails;
