import React, { useState, useEffect } from "react";
import ObjectForm from "../../Common/ObjectForm";
import http from "../../Common/RestAPIHandler";
import countryCodes from "../../Common/CountryCodes";
import ScreenLoader from "../../Common/Loader";

const PortalUserForm = (props) => {
  const [roles, setRoles] = useState([]);
  const [formFields, setFormFields] = useState();
  const [loadData, setLoadData] = useState(false);
  var countryCodeMap = {};

  Object.keys(countryCodes).forEach(function (c) {
    if (!countryCodeMap[countryCodes[c].code]) {
      countryCodeMap[countryCodes[c].code] = countryCodes[c].name;
    } else {
      countryCodeMap[countryCodes[c].code] =
        countryCodeMap[countryCodes[c].code] + " / " + countryCodes[c].name;
    }
  });

  useEffect(() => {
    setLoadData(true);
    http.Get(
      `portal-roles`,
      (data) => {
        const map = {
          '-1': 'NONE'
        };
        data = data.filter((role) => role.status === true);
        for (let i = 0; i < data.length; i++) {
          map[data[i].id] = data[i].role_name;
        }
        setRoles(map);
        setLoadData(false);
      },
      () => {}
    );
  }, []);

  useEffect(() => {
    setFormFields([
      {
        id: "company_name",
        label: "COMPANY",
        type: "readonly",
      },
      {
        id: "employee_name",
        label: "EMPLOYEE NAME",
        type: "readonly",
      },
      {
        id: "email",
        label: "EMAIL",
        type: "readonly",
      },
      {
        id: "mobile",
        label: "MOBILE",
        type: "text",
      },
      {
        id: "country_code",
        label: "COUNTRY CODE",
        type: "select",
        valueMap: countryCodeMap,
      },
      {
        id: "is_hidden",
        label: "HIDDEN",
        type: "radio",
      },
      {
        id: "is_billing_contact",
        label: "BILLING CONTACT",
        type: "radio",
      },
      {
        id: "role_id",
        label: "PORTAL ROLE",
        type: "select",
        valueMap: roles,
        onChange: (e, object, setObject) => {
          setObject({
            ...object,
            [e.target.name]: e.target.value !== '-1' ? e.target.value : null,
          });
        },
      },
    ]);
  }, [roles]);

  return (
    <div>
      {loadData && <ScreenLoader/>}
      {formFields && (
        <ObjectForm
          {...props}
          url={`company_employees`}
          formName={"Portal User"}
          formFields={formFields}
        />
      )}
    </div>
  );
};

export default PortalUserForm;
