import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import http from "../../Common/RestAPIHandler";
import CheckIcon from '@material-ui/icons/Check';
import ToTenantForm from "./ToTenantForm";
import commonConstants from "../../Common/CommonConstants";

const ToTenants = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const url = `tenant_tenant_mappings?from_tenant=${props.user.tenant}`;
  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "to_tenant_name",
      label: "TO TENANT",
      minWidth: 200,
      sortable: true,
      colored: true,
      style: commonConstants.cellCursor,
      onClick: (props, object, property) => {
        props.history.push(process.env.PUBLIC_URL + `/settings/to_tenants/${object.to_tenant}/details`);
      },
    },
    {
      id: "is_accepted",
      label: "ACCEPTED",
      minWidth: 300,
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        data.forEach((mapping) => {
          mapping["is_accepted"] = mapping.is_accepted ? <CheckIcon/> : "";
        });
        setData(data);
        setLoadData(false);
      },
      () => {}
    );
  };

  return (
    <>
    {!props.mobile && 
    <ObjectList
      {...props}
      moduleName={"Tenants"}
      url={url}
      actions={actions}
      objectForm={ToTenantForm}
      tableColumns={tableColumns}
      searchFields={["to_tenant_name",]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
    />
    }
    {props.mobile && 
    <ObjectListMobile
      {...props}
      moduleName={"Tenants"}
      url={url}
      actions={actions}
      objectForm={ToTenantForm}
      tableColumns={tableColumns}
      searchFields={["to_tenant_name",]}
      data={data}
      fetchData={fetchData}
      pageHeader={"Tenants"}
      loadData={loadData}
    />
    }
    </>
  );
};

export default ToTenants;
