import React, { useState, useEffect } from "react";
import useStyles from "../../Common/Style/FormStyle";
import http from "../../Common/RestAPIHandler";
import {Divider, Typography, Paper, Grid, IconButton,} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { formatDate } from "../../Common/Utility";
import DataMappings from "../DataMappings";
import ScreenLoader from "../../Common/Loader";

import SoftwareLicenseForm from "./SoftwareLicenseForm";
import Drawer from "@material-ui/core/Drawer";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import CreateIcon from "@material-ui/icons/Create";

const SoftwareLicenseDetails = (props) => {
    const classes = useStyles();
    const { id } = props.match.params;
    var { softwareLicenseId } = props.match.params;  
    const [softwareLicense, setSoftwareLicense] = useState({});
	const [dataMappings, setDataMappings] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      if(props.objectId){
        softwareLicenseId = props.objectId;
      }
      http.Get(
        `software_licenses/${softwareLicenseId}/`,
        (softwareLicense) => {
            setSoftwareLicense(softwareLicense);

            http.Get(
              `data_mappings?from_id=${softwareLicense.id}`,
              (data) => {
                setDataMappings(data);
                setLoading(false);
              },
              () => {
              }
            );
        },
        () => {}
      );
    }, []);

    const [open, setOpen] = useState(false);
    const [width, setWidth] = useState(400);
    const [startX, setStartX] = useState();
    const [endX, setEndX] = useState();
  
    const onDragStart = (e) => {
      setStartX(e.screenX);
    };
  
    const onDragEnd = (e) => {
      setEndX(e.screenX);
    };
  
    useEffect(() => {
      if(startX && endX){
        setWidth(width + startX - endX);
      }
    }, [startX, endX]);
    
    useEffect(() => {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      setWidth(vw * 0.8);
    }, [window.innerWidth]);
  
    const handleObjectSubmit = () => {
      window.location.reload(false);
    };

    return (
        <>
        {loading &&
            <ScreenLoader/>
        }
        {!loading &&
        <Grid container spacing={1} className={classes.paper}>
            <Grid item xs={10}>
                <Typography variant="h6" component="h6" style={{paddingTop:10,paddingBottom:10}}>
                    {softwareLicense.name}
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <div style={{paddingTop:10,float:"right"}}>
                    <IconButton
                        className={classes.iconButtonColored}
                        onClick={() => {
                            if(props.onClose){
                                props.onClose();
                            }
                            else{
                                props.history.push(
                                    process.env.PUBLIC_URL + `/company/${id}/software_licenses`
                            );
                            }
                        }}
                    >
                    <HighlightOffIcon />
                    </IconButton>
                </div>
            </Grid>
            <Grid item xs={12}>   
                <Divider/>
            </Grid>
            <Grid item xs={10}>
                <Paper>
                    <div
                        style={{ maxHeight: "calc(100vh - 280px)",
                            minHeight: "calc(100vh - 280px)",
                            overflowY: "auto",
                            overflowX: "auto",  
                            marginLeft:10,
                            padding:10
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>NAME</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.name}             
                    
                                <IconButton
                                    className={classes.iconButtonColored}
                                    style={{float:"right"}}
                                    onClick={() => {
                                        setOpen(true);
                                    }}
                                >
                                    <CreateIcon />
                                </IconButton>
                                <Drawer
                                    anchor="right"
                                    open={open}
                                    onClose={() => setOpen(false)}
                                    disableEnforceFocus
                                    classes={{
                                        paper: classes.drawerForm,
                                    }}
                                >
                                    <div style={{ width: width }}>
                                        <table>
                                        <tbody>
                                            <tr>
                                            <td
                                                draggable
                                                style={{ cursor: "ew-resize" }}
                                                onDragStart={onDragStart}
                                                onDragEnd={onDragEnd}
                                            >
                                                <DragIndicatorIcon />
                                            </td>
                                            <td width="100%">
                                                <SoftwareLicenseForm
                                                    {...props}
                                                    closeOpen={(open) => setOpen(open)}
                                                    objectId={props.objectId}
                                                    handleObjectSubmit={handleObjectSubmit}
                                                />
                                            </td>
                                            </tr>
                                        </tbody>
                                        </table>
                                    </div>
                                </Drawer>

                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>LICENSE TYPE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.category_name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>SERIAL NUMBER</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.serial_number}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>INSTALL DATE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {formatDate(softwareLicense.install_date)}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>EXPIRATION DATE</span>
                            </Grid>
                            <Grid item xs={8}>
                                {formatDate(softwareLicense.expiration_date)}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>LOCATION</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.site_name}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>SOFTWARE ASSET TAG</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.software_asset_tag}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>WORKSTATION ASSET TAG</span>
                            </Grid>
                            <Grid item xs={8}>
                                {softwareLicense.workstation_asset_tag}
                            </Grid>
                            <Grid item xs={4}>
                                <span style={{fontWeight:"bold"}}>NOTES</span>
                            </Grid>
                            <Grid item xs={8} style={{whiteSpace:"pre-wrap"}}>
                                {softwareLicense.notes}
                            </Grid>
                        </Grid>                        
                    </div>
                </Paper>
                <Divider/>
            </Grid>
            <Grid item xs={2}>
                <Paper>
                <div
                    style={{ maxHeight: "calc(100vh - 280px)",
                        minHeight: "calc(100vh - 280px)",
                        overflowY: "auto",
                        overflowX: "auto", 
                        marginLeft:10,
                        padding:10,
                    }}
                >
                <p style={{fontWeight:"bold"}}>Related Entries:</p>
                <DataMappings 
                  {...props}
                  dataMappings={dataMappings}
                  setDataMappings={null}
                />
              </div>
              </Paper>
          </Grid>
        </Grid>
        }
        </>
    );
};

export default SoftwareLicenseDetails;