import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import GlobeIcon from "@material-ui/icons/Public";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import NavigateIcon from "@material-ui/icons/ImportExport";
import SelectIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import Paper from "@material-ui/core/Paper";
import KeyboardEventHandler from "react-keyboard-event-handler";
import ScreenLoader from "../Loader";

const useStyles = makeStyles((theme) => ({
  leftSearchPane: {
    padding: 5,
    width: "100%",
    height: "100%",
  },
  searchBar: {
    height: 60,
    width: "97%",
  },
  searchResults: {
    overflow: "auto",
    height: "calc(100vh - 300px)",
  },
  searchOptions: {
    height: 40,
    bottom: 0,
    width: "97%",
  },
  searchText: {
    fontWeight: 500,
  },
  searchResultPrimary: {
    fontSize: "small",
    fontWeight: 500,
  },
  searchResultSecondary: {
    fontSize: "smaller",
  },
  icon: {
    color: theme.icons
  },
  iconText: {
    fontSize: "smaller",
    margin: 2,
  },
  navIcon: {
    height: "25px !important",
    width: "25px !important",
  },
  selected: {
    backgroundColor: "#d4edea !important",
  },
  hover: {
    backgroundColor: "#ebebeb",
  },
  esc: {
    fontSize: "10px",
    fontWeight: 600,
  },
  shortcutsHide: {
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
    display: "block",
  },
  optionsHide: {
    [theme.breakpoints.down(800)]: {
      display: "none",
    },
    display: "block",
  },
  smallLabelSize: {
    color: theme.text,
    [theme.breakpoints.down(600)]: {
      overflow: "hidden",
      fontSize: "5px !important",
    },
  },
}));

const ShowSearches = (props) => {
  const classes = useStyles();

  useEffect(() => {}, [props.data]);

  const setKeyboardEventData = (key, e) => {
    props.setEventData(key, e);
  };

  return (
    <Paper className={classes.leftSearchPane}>
      <Grid
        container
        spacing={1}
        direction="column"
        justify="space-between"
        alignItems="stretch"
      >
        <Grid item className={classes.searchBar}>
          <Grid
            container
            spacing={1}
            justify="flex-start"
            alignItems="flex-end"
          >
            <Grid item xs={2} sm={1}>
              <GlobeIcon color="action" className={classes.icon}/>
            </Grid>
            <Grid item xs={10} sm={11} className={classes.searchText}>
              <TextField
                autoFocus
                id={props.component_id}
                name="text"
                label={props.company?"Company Search":"Global Search"}
                margin="dense"
                className={classes.smallLabelSize}
                fullWidth
                value={props.text || ""}
                onChange={(e) => props.onInputChange(e)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item className={classes.searchResults}>
          <KeyboardEventHandler
            handleKeys={["up", "down", "enter", "esc"]}
            onKeyEvent={(key, e) => setKeyboardEventData(key, e)}
          >
            {props.loadData ? (
              <ScreenLoader />
            ) : (
              <List dense>
                {props.data &&
                  props.data.map((item, index) => (
                    <ListItem
                      key={item.id}
                      selected={props.selectedIndex === index}
                      classes={{
                        selected: classes.selected,
                      }}
                      className={
                        props.hoverIndex === index ? classes.hover : null
                      }
                      button
                      onClick={(event) =>
                        props.handleListItemClick(item, index)
                      }
                      onDoubleClick={(event) =>{
                        if(item.model === "Company"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.id;
                        }
                        else if(item.model === "Site"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/sites?input_search=" + props.text + "&show_id=" + item.id;
                        }
                        else if(item.model === "Employee"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/employees?input_search=" + props.text + "&show_id=" + item.id;
                        }
                        else if(item.model === "Document"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/documents/"+ item.id + "/details";
                        }
                        else if(item.model === "Server and Device"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/servers_devices?input_search=" + props.text + "&show_id=" + item.id;
                        }
                        else if(item.model === "Service"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/other_services?input_search=" + props.text + "&show_id=" + item.id;
                        }
                        else if(item.model === "Software License"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/software_licenses?input_search=" + props.text + "&show_id=" + item.id;
                        }
                        else if(item.model === "Customized Data"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/json_datas/" + item.json_schema + "?input_search=" + props.text + "&show_id=" + item.id;
                        }
                        else if(item.model === "File"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/files?input_search=" + props.text + "&show_id=" + item.id;
                        }
                        else if(item.model === "Domain"){
                          window.location = process.env.PUBLIC_URL + "/company/" + item.company_id + "/domains?input_search=" + props.text + "&show_id=" + item.id;
                        }
                      }}
                    >
                      <Grid
                        container
                        direction="row"
                        justify="flex-start"
                        alignItems="center"
                      >
                        <Grid item xs={12} sm={6}>
                          <ListItemText
                            id={item.id}
                            classes={{
                              primary: classes.searchResultPrimary,
                              secondary: classes.searchResultSecondary,
                            }}
                            primary={item.name}
                            secondary={item.company || item.industry_type}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify={
                              (window.innerWidth <= 480 && "flex-start") ||
                              "flex-end"
                            }
                            alignItems="center"
                          >
                            <Grid item className={classes.navIcon}>
                              <item.icon className={`${classes.iconText}`} />
                            </Grid>
                            <Grid item>
                              <Typography className={`${classes.iconText}`}>
                                {item.model}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
              </List>
            )}
          </KeyboardEventHandler>
        </Grid>

        <Grid
          item
          className={`${classes.searchOptions} ${classes.optionsHide}`}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            alignContent="center"
          >
            <Grid item>
              <Typography variant="caption" className={classes.shortcutsHide}>
                Options/ Shortcuts
              </Typography>
            </Grid>
            <Grid item>
              <Grid
                container
                spacing={2}
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item className={classes.optionsHide}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <NavigateIcon fontSize="small" color="action" />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">navigate</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.optionsHide}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <SelectIcon fontSize="small" color="action" />
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">select</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item className={classes.optionsHide}>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className={classes.esc}>esc</Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption">cancel</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          className={`${classes.searchOptions} ${classes.optionsHide}`}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="flex-end"
            alignContent="center"
          >
            <Grid item>
            </Grid>
            <Grid item>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid item className={classes.optionsHide}>
                  <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="center"
                  >
                    <Grid item>      
                      <Typography variant="caption" className={classes.shortcutsHide}>
                        Click to show info / Double click to open entry
                      </Typography>              
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ShowSearches;
