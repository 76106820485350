import React  from "react";
import ObjectForm from "../../../Common/ObjectForm";

const JobPostingTemplateForm = (props) => {
  const formFields = [
    {
      id: "title",
      label: "TITLE",
      type: "text",
    },
    {
      id: "data",
      label: "TEMPLATE",
      type: "ckeditor",
    },
  ];

  return (
    <ObjectForm
      {...props}
      objectId={props.objectId}
      url={`hr_job_posting_templates`}
      formName={"Job Posting Template"}
      formFields={formFields}
      object={{ tenant:props.user.tenant,data:""}}
    />
  );
};

export default JobPostingTemplateForm;
