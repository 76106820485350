import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import http from "../../Common/RestAPIHandler";
import ResourceForm from "./ResourceForm";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DeleteAlertDialog from "../../Common/AlertDialog";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Tooltip from "@material-ui/core/Tooltip";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import ScreenLoader from "../../Common/Loader";
import { Drawer, Divider, IconButton } from "@material-ui/core";
import useStyles from "../Style/style";

const Resource = (props) => {
  const classes = useStyles();
  const [resources, setResources] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [resourceId, setResourceId] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [width, setWidth] = useState(400);
  const [startX, setStartX] = useState();
  const [endX, setEndX] = useState();
  const [startWidth, setStartWidth] = useState();
  const [loadData, setLoadData] = useState(false);

  useEffect(() => {
    setLoadData(true);
    http.Get(
      "resources/",
      (resources) => {
        setResources(resources);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  const onDragStart = (e) => {
    setStartX(e.screenX);
    setStartWidth(width);
  };

  const onDrag = (e) => {
    setEndX(e.screenX);
    setWidth(startWidth + startX - endX);
  };

  const handleArrowClick = () => {
    setCollapse(!collapse);
    props.setLink(collapse);
  };

  const handleSetOpen = (open) => {
    setOpen(open);
  };

  const handleAddClick = () => {
    setIsEdit(false);
    setResourceId(null);
    setOpen(true);
  };

  const handleEditClick = (e, id) => {
    e.stopPropagation();
    setIsEdit(true);
    setResourceId(id);
    setOpen(true);
  };

  const handleDeleteClick = (e, id) => {
    e.stopPropagation();
    setResourceId(id);
    setAlertOpen(true);
  };

  const handleDeleteAgree = (id) => {
    http.Delete(
      `resources/${id}/`,
      null,
      () => {
        const updatedResources = resources.filter((item) => item.id !== id);
        setResources([...updatedResources]);
        setResourceId(null);
        setAlertOpen(false);
      },
      () => {}
    );
  };

  const handleDeleteDisagree = () => {
    setResourceId(null);
    setAlertOpen(false);
  };

  const handleResourceSubmit = (resource, edit) => {
    if (edit) {
      const updatedResources = resources.map((item) =>
        item.id === resource.id ? resource : item
      );
      setResources([...updatedResources]);
      setOpen(false);
    } else {
      setResources([...resources, resource]);
      setOpen(false);
    }
  };

  return (
    <div className={classes.root}>
      <DeleteAlertDialog
        open={alertOpen}
        onAgree={handleDeleteAgree}
        onDisagree={handleDeleteDisagree}
        id={resourceId}
        primaryMessage="Do you want to delete?"
        secondaryMessage="If you agree, data will be deleted permanently."
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={11}>
              <span className={classes.title}>SHARED LINKS</span>
              &nbsp;
              <IconButton size="small" onClick={handleAddClick}>
                <Tooltip arrow={true} title="Add" placement="right-end">
                  <PlaylistAddIcon className={classes.themedButton} />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item xs={1} className={classes.icons}>
              {(collapse && (
                <ArrowDropDownIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )) || (
                <ArrowDropUpIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )}
            </Grid>
          </Grid>
          <Divider
            classes={{ root: classes.dividers }}
            component="hr"
            orientation="horizontal"
          />
        </Grid>

        {loadData ? (
          <ScreenLoader />
        ) : (
          <React.Fragment>
            {!collapse && (
              <Grid item xs={12} className={classes.contentGrid}>
                <Grid container spacing={3}>
                  {resources.map((resource) => {
                    return (
                      <Grid item xs={12} key={resource.id}>
                        <Paper elevation={0} classes={{ root: classes.paper }}>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item xs={11}>
                              <Tooltip title={resource.link}>
                                <span
                                  className={classes.title}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    window.open(resource.link);
                                  }}
                                >
                                  {resource.title}
                                </span>
                              </Tooltip>
                            </Grid>
                            <Grid item xs={1}>
                              <Grid
                                container
                                spacing={1}
                                direction="row"
                                justify="flex-end"
                                alignItems="center"
                              >
                                <Grid item xs={6}>
                                  <Tooltip title="Edit">
                                    <EditIcon
                                      fontSize="small"
                                      className={classes.themedButton}
                                      onClick={(e) =>
                                        handleEditClick(e, resource.id)
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                                <Grid item xs={6}>
                                  <Tooltip title="Delete">
                                    <DeleteOutlineIcon
                                      fontSize="small"
                                      className={classes.themedButton}
                                      onClick={(e) =>
                                        handleDeleteClick(e, resource.id)
                                      }
                                    />
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}
          </React.Fragment>
        )}
      </Grid>

      <Drawer
        anchor="right"
        open={open}
        onClose={() => {
          handleSetOpen(false);
        }}
        disableEnforceFocus
        classes={{
          paper: classes.drawerForm,
        }}
      >
        <div style={{ width: width }}>
          <table>
            <tbody>
              <tr>
                <td
                  draggable
                  style={{ cursor: "ew-resize" }}
                  onDragStart={onDragStart}
                  onDrag={onDrag}
                >
                  <DragIndicatorIcon />
                </td>
                <td width="100%">
                  <ResourceForm
                    isEdit={isEdit}
                    onClose={handleSetOpen}
                    resourceId={resourceId}
                    handleResourceSubmit={handleResourceSubmit}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Drawer>
    </div>
  );
};
export default Resource;
