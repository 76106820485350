import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Logo from "../../Images/logo.png";
import useStyles from "./style";
import http from "../Common/RestAPIHandler";
import { setUser } from "../../redux-tools/actions/userActions";
import { connect } from "react-redux";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Alert from "@material-ui/lab/Alert";
import commonConstants from "../Common/CommonConstants";

const Login = (props) => {
  const classes = useStyles();
  const [user, setUser] = useState({});
  const [invalid, setInvalid] = useState(false);
  const errorText = commonConstants.error;
  const [invalidUser, setInvalidUser] = useState(false);
  const [invalidTenant, setInvalidTenant] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const resetAll = () => {
    setInvalid(false);
    setInvalidUser(false);
    setInvalidTenant(false);
    setIsSubmitted(false);
  };

  const onInputChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
    resetAll();
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetAll();
    setIsSubmitted(true);   
    http.Get(
      `login?email=${user.email}`,
      (response) => {
        console.log(response);
        if(response.next_step === "onelogin"){
          localStorage.setItem("onelogin_user_email",user.email);
          localStorage.setItem("onelogin_url",response.onelogin_url);
          localStorage.setItem("onelogin_client_id",response.onelogin_client_id);

          if (response.theme == "Light") {
            localStorage.setItem("isDarkEnabled", false);
          } else {
            localStorage.setItem("isDarkEnabled", true);
          }
          localStorage.setItem(
            "colorScheme",
            JSON.stringify(response.colour_scheme || "Orange")
          );
          localStorage.setItem("date_format", response.date_format);
          localStorage.setItem("date_time_format", response.date_time_format);
          localStorage.setItem("notification_days", response.notification_days);
          props.history.replace(process.env.PUBLIC_URL + "/oneloginsso");
        }
        if(response.next_step === "azuresso"){
          localStorage.setItem("azure_user_email",user.email);
          localStorage.setItem("azure_tenant_id",response.azure_tenant_id);
          localStorage.setItem("azure_client_id",response.azure_client_id);
          localStorage.setItem("azure_client_secret",response.azure_client_secret);

          if (response.theme == "Light") {
            localStorage.setItem("isDarkEnabled", false);
          } else {
            localStorage.setItem("isDarkEnabled", true);
          }
          localStorage.setItem(
            "colorScheme",
            JSON.stringify(response.colour_scheme || "Orange")
          );
          localStorage.setItem("date_format", response.date_format);
          localStorage.setItem("date_time_format", response.date_time_format);
          localStorage.setItem("notification_days", response.notification_days);
          props.history.replace(process.env.PUBLIC_URL + "/azuresso");
        }
        else{
          localStorage.setItem("user_email",user.email);
          if (response.theme == "Light") {
            localStorage.setItem("isDarkEnabled", false);
          } else {
            localStorage.setItem("isDarkEnabled", true);
          }
          localStorage.setItem(
            "colorScheme",
            JSON.stringify(response.colour_scheme || "Orange")
          );
          localStorage.setItem("date_format", response.date_format);
          localStorage.setItem("date_time_format", response.date_time_format);
          localStorage.setItem("notification_days", response.notification_days);
          props.history.replace(process.env.PUBLIC_URL + "/passwordlogin");
        }
      },
      () => {
        setInvalidUser(true);
      }
    );
  };

  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <ValidatorForm onSubmit={onSubmit}>
          <Grid
            container
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.container}
          >
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} className={classes.centerElement}>
                  <Typography variant="h6" color="inherit" noWrap>
                    <img
                      src={Logo}
                      alt="Not Available"
                      className={classes.logo}
                    />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="h4"
                    component="h5"
                    color="textSecondary"
                    classes={{ h4: classes.title }}
                  >
                    Login
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    id="outlined-email-input"
                    label="E-mail"
                    type="text"
                    name="email"
                    variant="outlined"
                    fullWidth
                    value={user.email || ""}
                    onChange={onInputChange}
                    validators={["required", "trim", "isEmail"]}
                    errorMessages={[
                      errorText.requiredError,
                      errorText.blankError,
                      errorText.invalidEmailError,
                    ]}
                  />
                </Grid>

                {(invalidTenant || invalidUser) && (
                  <Grid item xs={12}>
                    <Alert severity="error" variant="filled">
                      Username incorrect!
                    </Alert>
                  </Grid>
                )}

                {invalid && (
                  <Grid item xs={12}>
                    <Alert severity="error" variant="filled">
                      An error has occurred, please try again later.
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Link
                        component={RouterLink}
                        to={process.env.PUBLIC_URL + "/forgetPassword?userType=Tenant User"}
                        color="textPrimary"
                        className={classes.link}
                      >
                        Forgot password?
                      </Link>
                      <br/>
                      <Link
                        component={RouterLink}
                        to={process.env.PUBLIC_URL + "/portal/"}
                        color="textPrimary"
                        className={classes.link}
                      >
                        Client portal
                      </Link>
                      <br/>
                      <span 
                        style={{cursor:"pointer"}}
                        onClick={()=>{window.location="/users/register";}}
                        className={classes.link}
                      >
                        Register
                      </span>
                      <br/>
                      <span 
                        style={{cursor:"pointer"}}
                        onClick={()=>{window.open("http://kb.syncmonkey.com/");}}
                        className={classes.link}
                      >
                        Documentation
                      </span>
                    </Grid>
                    <Grid item xs={6} className={classes.rightElement}>
                      <Button
                        type="submit"
                        className={classes.button}
                        variant="outlined"
                        disabled={isSubmitted}
                      >
                        Next
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ValidatorForm>
      </div>
    </div>
  );
};
export default connect(null, { setUser })(Login);
