import React from "react";
import { Paper } from "@material-ui/core/";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import useStyles from "./style";



const FeaturedContent = props => {
  const classes = useStyles();
  const content = props.content;
  var label;

  if (content.title === 'Moneris'){label = 'Payment Gateway';}
  else if(content.title === 'Azure SSO' || content.title === 'OneLogin SSO'){label = 'Authentication';}
  else{label = 'PSA';}


  return (
    <Grid item xs={7} sm={4} md={4} lg={3} className={classes.gridItem}>
      <Paper className={classes.paper}>
        <img
          className={classes.image}
          src={content.logo}
          alt="Image not found"
          height="70"
          width="70"
        />
        <Divider orientation="vertical" />
        <div className={classes.featured}>
          <Typography>
            <span className={classes.label}>
            {label}
            </span>
          </Typography>
          <Typography className={classes.typogrpahy}>
            {content.title}
          </Typography>
          <div>
            <Typography
              variant="caption"
              className={
                (props.installed && classes.alreadyInstalledButtons) ||(content.installable&&!props.installed && classes.alreadyInstalledButtons)||
                (!content.installable&&classes.featuredButtons)
              }
              clickable
              onClick={() => {
                !content.installable&&!props.installed && content.onClick(content);
              }}
            >
              {(props.installed && "Installed") || (!content.installable&&"Install")||(!props.installed &&"Another provider already installed")}
            </Typography>
          </div>
        </div>
      </Paper>
    </Grid>
  );
};

export default FeaturedContent;
