import React, { useEffect, useState } from "react";
import commonConstants from "../../../../Common/CommonConstants";
import http from "../../../../Common/RestAPIHandler";
import {
  Chip,
  Divider,
  Typography,
  Paper,
  Grid,
  List,
  ListItem,
  ListItemText,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell} from "@material-ui/core";
import ScreenLoader from "../../../../Common/Loader";
import useStyles from "../../../../Common/Style/CommonStyle";

const VacationOverview = (props) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const vacationTypes = [{type: 'Vacation'}, {type: 'Sick'}, {type: 'Personal'}, {type: 'Moving'}, {type: 'Other'}];

  // Need to process data for 'OTHER' column of report before rendering.
  useEffect(() => {
    setLoadData(true);
    props.results.map((item) => {
      
      for (const t of vacationTypes) {
        console.log(t);
        item[t.type].filter((v) => v.request_status === 'ACCEPTED');
        const keyUsed = t.type + '_days_used';
        const keyTotal = t.type + '_days_total';
        item[keyUsed] = 0;
        item[keyTotal] = 0;
        item[t.type].map((v) => {
          if (v.available_hours_used) item[keyUsed] += v.hours;
          item[keyTotal] += v.hours;
        });
        item[keyUsed] /= 8;
        item[keyTotal] /= 8;
      }

      // Create 'other' entries by counting and listing for each unique reason.
      item['other_overview'] = [];
      if (item.Other.length > 0) {
        let otherDict = {};
        for (const entry of item.Other) {
          if (entry.reason in otherDict) otherDict = {...otherDict, [entry.reason]: otherDict[entry.reason] + entry.hours}
          else otherDict = {...otherDict, [entry.reason]: entry.hours}
        }
        for (const [key, value] of Object.entries(otherDict)) {
          item['other_overview'].push({reason: key, count: value / 8});
        }
      }
    });
    setData(props.results);
    setLoadData(false);
  }, []);

  // Export pdf file of vacation details. File is created from backend template.
  const exportData = () => {
    http.ExportToPdf(
      `hr/report/vacations/export_pdf`,
      data,
      (blob) => {
        //window.open(window.URL.createObjectURL(blob));
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = `Vacation Overview.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
      () => {}
    );
  }

  return (
      <>
      {loadData ? <ScreenLoader /> :(
          <Grid container spacing={1}>
              <Grid item xs={9}>
                  <Typography variant="h6" component="h6" className={classes.text} style={{paddingBottom:10, paddingLeft:10}}>
                      Vacation Overview
                  </Typography>
              </Grid>
              <Grid item xs={3}>
                  <div style={{paddingBottom:10,float:"right"}}>
                    <Chip
                      label="EXPORT"
                      clickable
                      className={classes.chip}
                      color="primary"
                      onClick={exportData}
                    />
                  </div>
              </Grid>
              <Grid item xs={12}>   
                  <Divider/>
              </Grid>
              <Grid item xs={12}>
                  <Paper>
                      <div
                          style={{ overflowY: "auto",
                              overflowX: "auto",  
                              marginLeft:10,
                              padding:10
                          }}
                      >
                          <Grid container>
                              <Grid item xs={12}>
                                  <Table className={{root: classes.table}}>
                                      <TableHead>
                                          <TableCell className={classes.tableCellHeader} style={{minWidth: 150}}>EMPLOYEE</TableCell>
                                          <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>LOCATION</TableCell>
                                          <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>VACATION</TableCell>
                                          <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>SICK</TableCell>
                                          <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>PERSONAL</TableCell>
                                          <TableCell className={classes.tableCellHeader} style={{minWidth: 100}}>MOVING</TableCell>
                                          <TableCell className={classes.tableCellHeader} style={{minWidth: 150}}>OTHER</TableCell>
                                      </TableHead>
                                      <TableBody>
                                        {data.length === 0 && (
                                          <TableRow className={classes.tableRow}>
                                            <TableCell colSpan={7} align="center" className={classes.subText}>This table is empty.</TableCell>
                                          </TableRow>
                                          ) ||
                                        data.map((row, index) => {
                                            return (
                                                <TableRow className={classes.tableRow} key={index}>
                                                    <TableCell className={`${classes.tableCell} ${classes.cellColor}`}>
                                                      <span style={commonConstants.cellCursor} onClick={() => props.setDetails(row)}>
                                                        {row.name}
                                                      </span>
                                                    </TableCell>
                                                    <TableCell className={`${classes.tableCell} ${classes.subText}`}>{row.location}</TableCell>
                                                    <TableCell className={`${classes.tableCell} ${classes.subText}`}>
                                                      {`${row.Vacation_days_used} / ${row.max_vacations}`}
                                                    </TableCell>
                                                    <TableCell className={`${classes.tableCell} ${classes.subText}`}>
                                                      {`${row.Sick_days_used} / ${row.max_sick_days}`}
                                                    </TableCell>
                                                    <TableCell className={`${classes.tableCell} ${classes.subText}`}>
                                                      {`${row.Personal_days_used} / ${row.max_personal_days}`}
                                                    </TableCell>
                                                    <TableCell className={`${classes.tableCell} ${classes.subText}`}>
                                                      {`${row.Moving_days_used} / ${row.max_moving_days}`}
                                                    </TableCell>
                                                    <TableCell className={`${classes.tableCell} ${classes.subText}`}>
                                                      <List>
                                                      {row.other_overview.length === 0 && (
                                                          <ListItem disablePadding>
                                                              <ListItemText primary={"None"} />
                                                          </ListItem>
                                                      ) || row.other_overview.map((entry, index) => {return (
                                                        <ListItem disablePadding key={index}>
                                                          <ListItemText primary={`${entry.count} (${entry.reason})`} />
                                                        </ListItem>
                                                      )})}
                                                      </List>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                      </TableBody>
                                  </Table>
                              </Grid>
                          </Grid>                        
                      </div>
                  </Paper>
                  <Divider/>
              </Grid>
          </Grid>
      )}
      </>
  );
};

export default VacationOverview;
