const commonConstants = {
  background: "#fafafa",
  applicationSLD: "localhost",
  serverName: window.location.protocol +  "//" + window.location.hostname + (window.location.port==="3000" ? ":8000/api/" : "/api/api/"),
  Papa: require("papaparse/papaparse.min.js"),
  cellLength: 50,
  cellCursor: { cursor: "pointer" },
  cellColor: { color: "#f7a27c" },
  cell: { color: "#f7a27c", cursor: "pointer" },
  smallFont: { fontSize: "75%" },
  error: {
    requiredError: "This field is required.",
    blankError: "This field cannot be left blank.",
    urlOrIpError: "Enter a valid url or IP address.",
    urlError: "Enter a valid url. https://www.example.com",
    invalidEmailError: "Please enter a valid email address.",
    fileError: "File is not valid.",
    imageFormatError: "The selected file's format is not supported.",
    imageSizeError: "Size must not exceed 1MB.",
    uniqueError: "Already exist.",
    eightChar: "Must be at least 8 characters.",
    uppercase: "Must contain at least one uppercase character.",
    numeric: "Must contain at least one numeric character.",
    specialChar:
      "Must contain at least one special character ( ! @ # $ % ^ & * ' + ).",
    passwordInvalid: "Password is not valid.",
    passwordMismatch: "Passwords do not match.",
    bothRequired: "Both fields are required.",
    setPasswordSuccess:
      "Your password has been set successfully. Please login.",
    miscallenousError: "An error has occurred, please try again later.",
    invalidEmailPassword: "Email or Password is invalid.",
    noUserFound: "User does not exist.",
    wrongPassword: "Password is incorrect.",
    websitePlaceholder: "https://www.example.com",
    csvFormatError: "Only csv file format is supported.",
    csvEmptyError:
      "Uploaded csv file is empty or the csv file format does not match.",
    csvFormatMismatchError: "The csv file format does not match.",
    NoClientPortalAccess: "Sorry, you do not have access to client portal.",
    packageLimitReached: "Cannot add more! Please upgrade package!",
  },
  companyHeader: [
    "company_name",
    "industry_type",
    "main_phone_number",
    "site_name",
    "address",
    /*
    "service_name",
    "service_username",
    "service_password",
    "service_website",
    "service_notes",
    "service_description",
    "service_location",
    
    "license_name",
    "license_serial_number",
    "license_install_date (M/d/yyyy)",
    "license_expiration_date (M/d/yyyy)",
    "license_software_asset_tag",
    "license_workstation_asset_tag",
    "license_location",
    "license_notes",

    "device_name",
    "device_description",
    "device_url",
    "device_username",
    "device_password",
    "device_tag_number",
    "device_notes",
    "device_location",
    "device_category",
    "device_cw_configuration_id",
    
    "employee_email",
    "employee_name",
    "employee_phone_number",
    "employee_status",
    "employee_short_note",
    "employee_portal_access",
    "employee_work_station",
    "employee_location",
    "employee_category",
    
    "credential_employee",
    "credential_category",
    "credential_username",
    "credential_password",
    "credential_description",
    "credential_website",
    
    "domain_name",
    "domain_registrar",
    "domain_expiration_date (M/d/yyyy)",
    "ssl_expiration_date (M/d/yyyy)",
    */
  ],
  configurationHeader: [
    "name",
    "username",
    "password",
    "url",
    "notes",
  ],
  serviceHeader: [
    "service_name",
    "username",
    "password",
    "website",
    "notes",
    "description",
    "location",
  ],
  licenseHeader: [
    "name",
    "serial_number",
    "install_date (M/d/yyyy)",
    "expiration_date (M/d/yyyy)",
    "software_asset_tag",
    "workstation_asset_tag",
    "location",
    "notes",
  ],
  deviceHeader: [
    "device_name",
    "description",
    "url",
    "username",
    "password",
    "tag_number",
    "notes",
    "location",
    "category",
    "cw_configuration_id",
  ],
  employeeHeader: [
    "email",
    "employee_name",
    "phone_number",
    "status",
    "short_note",
    "portal_access",
    "work_station",
    "location",
    "category",
    "cw_contact_id",
  ],
  credentialHeader: [
    "employee",
    "category",
    "username",
    "password",
    "description",
    "website",
  ],
  domainHeader: [
    "domain_name",
    "registrar",
    "expiration_date (M/d/yyyy)",
    "ssl_expiration_date (M/d/yyyy)",
  ],

  companyExportFormat: {
    id: "Id",
    company_name: "Company Name",
    industry_type: "Industry Type",
    main_phone_number: "Main Phone Number",
  },

  deviceExportFormat: {
    id: "Id",
    company_name: "company",
    site_name: "location",
    category_name: "category",
    device_name: "device_name",
    created_by_name: "created_by_name",
    created_time: "created_time",
    last_modified_by_name: "last_modified_by_name",
    last_modified_time: "last_modified_time",
    url: "url",
    description: "description",
    username: "username",
    password: "password",
    tag_number: "tag_number",
    notes: "notes",
    cw_configuration_id: "cw_configuration_id",
  },

  licenseExportFormat: {
    id: "Id",
    company_name: "company",
    site_name: "location",
    created_by_name: "created_by_name",
    created_time: "created_time",
    last_modified_by_name: "last_modified_by_name",
    last_modified_time: "last_modified_time",
    name: "name",
    serial_number: "serial_number",
    install_date: "install_date (M/d/yyyy)",
    expiration_date: "expiration_date (M/d/yyyy)",
    software_asset_tag: "software_asset_tag",
    workstation_asset_tag: "workstation_asset_tag",
    notes: "notes",
  },
  
  employeeExportFormat: {
    id: "Id",
    company_name: "company",
    site_name: "location",
    category_name: "category",
    created_by_name: "created_by_name",
    created_time: "created_time",
    last_modified_by_name: "last_modified_by_name",
    last_modified_time: "last_modified_time",
    email: "email",
    employee_name: "employee_name",
    phone_number: "phone_number",
    status: "status",
    short_note: "short_note",
    portal_access: "portal_access",
    work_station: "work_station",
    cw_contact_id: "cw_contact_id",
  },
  
  serviceExportFormat: {
    id: "Id",
    company_name: "company",
    site_name: "location",
    created_by_name: "created_by_name",
    created_time: "created_time",
    last_modified_by_name: "last_modified_by_name",
    last_modified_time: "last_modified_time",
    service_name: "service_name",
    website: "website",
    description: "description",
    username: "username",
    password: "password",
    notes: "notes",
  },

  domainExportFormat: {
    id: "Id",
    company_name: "company",
    created_by_name: "created_by_name",
    created_time: "created_time",
    last_modified_by_name: "last_modified_by_name",
    last_modified_time: "last_modified_time",
    domain_name: "domain_name",
    registrar: "registrar",
    expiration_date: "expiration_date (M/d/yyyy)",
    ssl_expiration_date: "ssl_expiration_date (M/d/yyyy)",
  },

  dateTimeFields: [
    "created_time",
    "last_modified_time",
    "install_date",
    "expiration_date",
  ],
  deviceImportFormatItGlue: {
    id: "Id",
    company: "organization",
    location: "location",
    category: "configuration_type",
    device_name: "name",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    url: "hostname",
    description: "configuration_interfaces",
    username: "Username",
    password: "Password",
    tag_number: "asset_tag",
    notes: "notes",
    cw_configuration_id: "Cw Configuration Id",
  },
  licenseImportFormatItGlue: {
    id: "Id",
    company: "Company",
    location: "Site",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    name: "Name",
    serial_number: "License Key(s)",
    "install_date (M/d/yyyy)": "Purchase Date",
    "expiration_date (M/d/yyyy)": "Renewal Date",
    software_asset_tag: "Version",
    workstation_asset_tag: "Seats",
    notes: "Notes",
  },
  employeeImportFormatItGlue: {
    id: "Id",
    company: "Company",
    location: "Site",
    category_name: "Category",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    email: "Email",
    employee_name: "Employee Name",
    phone_number: "Phone Number",
    status: "Enabled",
    short_note: "Short Note",
    portal_access: "Portal Access",
    work_station: "Work Station",
    cw_contact_id: "Cw Contact Id",
  },
  serviceImportFormatItGlue: {
    id: "Id",
    company: "Company",
    location: "Site",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    service_name: "Service Name",
    website: "Website",
    description: "Description",
    username: "Username",
    password: "Password",
    notes: "Notes",
  },
  credentialImportFormatItGlue: {
    id: "Id",
    employee:"organization",
    category:"password_category",
    username:"username",
    password:"password",
    description:"notes",
    website:"url",
  },
  domainImportFormatItGlue: {
    id: "Id",
    company_name: "Company",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    domain_name: "name",
    registrar: "notes",
    "expiration_date (M/d/yyyy)": "expires_on",
    "ssl_expiration_date (M/d/yyyy)": "SSL Expiration Date",
  },
  deviceImportFormat: {
    id: "Id",
    company: "Company",
    location: "Site",
    category: "Category",
    device_name: "Device Name",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    url: "Url",
    description: "Description",
    username: "Username",
    password: "Password",
    tag_number: "Tag Number",
    notes: "Notes",
    cw_configuration_id: "Cw Configuration Id",
  },
  licenseImportFormat: {
    id: "Id",
    company: "Company",
    location: "Site",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    name: "Name",
    serial_number: "Serial Number",
    install_date: "Install Date",
    expiration_date: "Expiration Date",
    software_asset_tag: "Software Asset Tag",
    workstation_asset_tag: "Workstation Asset Tag",
    notes: "Notes",
  },
  employeeImportFormat: {
    id: "Id",
    company: "Company",
    location: "Site",
    category_name: "Category",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    email: "Email",
    employee_name: "Employee Name",
    phone_number: "Phone Number",
    status: "Enabled",
    short_note: "Short Note",
    portal_access: "Portal Access",
    work_station: "Work Station",
    cw_contact_id: "Cw Contact Id",
  },
  serviceImportFormat: {
    id: "Id",
    company: "Company",
    location: "Site",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    service_name: "Service Name",
    website: "Website",
    description: "Description",
    username: "Username",
    password: "Password",
    notes: "Notes",
  },
  credentialImportFormat: {
    id: "Id",
    employee:"employee",
    category:"category",
    username:"username",
    password:"password",
    description:"description",
    website:"website",
  },
  domainImportFormat: {
    id: "Id",
    company_name: "Company",
    created_by_name: "Creator Name",
    created_time: "Created Time",
    last_modified_by_name: "Last Modifier Name",
    last_modified_time: "Last Modified Time",
    domain_name: "Domain Name",
    registrar: "Registrar",
    expiration_date: "Expiration Date",
    ssl_expiration_date: "SSL Expiration Date",
  },
};

export default commonConstants;
