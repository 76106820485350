import React, { useEffect, useState } from "react";
import ObjectList from "../../../../Common/ObjectList";
import ObjectListMobile from "../../../../Common/ObjectListMobile";
import http from "../../../../Common/RestAPIHandler";
import VacationProfileForm from "./VacationProfileForm";

const VacationProfiles = (props) => {
  const [data, setData] = useState([]);
  const [loadData, setLoadData] = useState(false);
  const [vacationProfilesUsed, setVacationProfilesUsed] = useState({});
  const url = `hr_vacation_profiles`;

  const actions = {
    delete: true,
    importCSV: false,
    exportCSV: false,
    exportPDF: false,
  };

  const tableColumns = [
    {
      id: "name",
      label: "NAME",
      minWidth: 150,
      sortable: true,
    },
    {
      id: "max_vacations",
      label: "VACATIONS",
      sortable: true,
    },
    {
      id: "max_sick_days",
      label: "SICK DAYS",
      sortable: true,
    },
    {
      id: "max_personal_days",
      label: "PERSONAL DAYS",
      sortable: true,
    },
    {
      id: "max_moving_days",
      label: "MOVING DAYS",
      sortable: true,
    },
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        const profiles = {};
        for (const profile of data) {
          if (profile.employee_count > 0) profiles[profile.id] = true;
        }
        setVacationProfilesUsed(profiles);
        
        setData(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  };

  return (
    <>
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={"Vacation Profiles"}
          url={url}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          permissions={{ create: ["HR"], edit: ["HR"], delete: ["HR"] }}
          fetchData={fetchData}
          orderBy={"name"}
          searchFields={["name"]}
          pageHeader={(props.user.company_name || "") + " | HR"}
          loadData={loadData}
          objectForm={VacationProfileForm}
          vacationProfilesUsed={vacationProfilesUsed}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Vacation Profiles"}
          url={url}
          tableColumns={tableColumns}
          data={data}
          actions={actions}
          permissions={{ create: ["HR"], edit: ["HR"], delete: ["HR"] }}
          fetchData={fetchData}
          orderBy={"name"}
          searchFields={["name"]}
          pageHeader={(props.user.company_name || "") + " | HR"}
          loadData={loadData}
          objectForm={VacationProfileForm}
          vacationProfilesUsed={vacationProfilesUsed}
        />
      )}
    </>
  );
};

export default VacationProfiles;
