import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import Paper from "@material-ui/core/Paper";
import { onWebsiteClick, onCopy } from "../TableUtility";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor-linkpoint/ckeditor5-build-classic";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  adornedStart: {
    marginRight: "0px !important"
  },
  bold: {
    fontWeight: 600
  },
  copy: {
    cursor: "pointer",
    color: "#909497",
    height: 25,
    width: 25,
    padding: 5,
    border: "1px solid #c4c4c4",
    borderRadius: "4px"
  },
  divider: {
    backgroundColor: theme.dividers
  },
  itemLabel: {
    padding: 5,
    color: theme.text
  },
  link: {
    cursor: "pointer",
    color: theme.linkColorFaded,
    "&:hover": {
      color: theme.linkColor
    }
  },
  passwordIcon: {
    padding: "5px !important"
  },
  passwordField: {
    padding: "5px !important"
  },
  rightSearchPane: {
    padding: 5,
    width: "100%",
    height: "100%"
  },
  rightText: {
    fontSize: "small",
  },
  smallText: {
    fontSize: "small",
    color: theme.text,
    paddingLeft: theme.spacing(3) + "px !important",
    paddingRight: theme.spacing(3) + "px !important"
  },
  title: {
    color: theme.text,
    paddingLeft: theme.spacing(3)
  },
  visibilityIcon: {
    width: ".90rem",
    height: ".90rem"
  },
}));

const ShowDetails = props => {
  const classes = useStyles();
  const [ModelType, setModelType] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [copy, setCopy] = useState(false);
  const [object, setObject] = useState({});

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  useEffect(() => {
    setShowPassword(false);
  }, [props.data]);

  const company = [
    { id: "name", label: "Name", type: "text" },
    { id: "industry_type", label: "Industry Type", type: "text" },
    { id: "website", label: "Website", type: "link", onClick: onWebsiteClick },
    { id: "email", label: "Email", type: "text" },
    { id: "phone_number", label: "Phone Number", type: "text" },
    { id: "status", label: "Status", type: "text" },
    { id: "notes", label: "Notes", type: "editor" }
  ];

  const site = [
    { id: "name", label: "Name", type: "text" },
    { id: "website", label: "Website", type: "link", onClick: onWebsiteClick },
    { id: "address", label: "Address", type: "text" },
    { id: "primary_contact_number", label: "Contact Number", type: "text" },
    { id: "company", label: "Company", type: "text" }
  ];

  const document = [
    { id: "name", label: "Name", type: "text" },
    { id: "category", label: "Category", type: "text" },
    { id: "company", label: "Company", type: "text" },
    { id: "content", label: "Content", type: "editor" }
  ];

  const device = [
    { id: "name", label: "Name", type: "text" },
    { id: "device_type", label: "Device Type", type: "text" },
    { id: "description", label: "Description", type: "text" },
    { id: "username", label: "Username", type: "text" },
    { id: "password", label: "Password", type: "password", onClick: onCopy },
    { id: "tag_number", label: "Tag Number", type: "text" },
    { id: "location", label: "Location", type: "text" },
    { id: "company", label: "Company", type: "text" }
  ];

  const service = [
    { id: "name", label: "Name", type: "text" },
    { id: "username", label: "Username", type: "text" },
    { id: "password", label: "Password", type: "password", onClick: onCopy },
    { id: "website", label: "Website", type: "link", onClick: onWebsiteClick },
    { id: "description", label: "Description", type: "text" },
    { id: "notes", label: "Notes", type: "text" },
    { id: "location", label: "Location", type: "text" },
    { id: "company", label: "Company", type: "text" }
  ];

  const software_license = [
    { id: "name", label: "Name", type: "text" },
    { id: "serial_number", label: "Serial Number", type: "text" },
    { id: "install_date", label: "Install Date", type: "text" },
    { id: "software_asset_tag", label: "Software Asset Tag", type: "text" },
    {
      id: "workstation_asset_tag",
      label: "Workstation Asset Tag",
      type: "text"
    },
    { id: "location", label: "Location", type: "text" },
    { id: "company", label: "Company", type: "text" }
  ];

  const employee = [
    { id: "name", label: "Name", type: "text" },
    { id: "email", label: "Email", type: "text" },
    { id: "phone_number", label: "Phone Number", type: "text" },
    { id: "portal_access", label: "Portal Access", type: "text" },
    { id: "location", label: "Location", type: "text" },
    { id: "company", label: "Company", type: "text" }
  ];

  const jsonData = [
    { id: "model", label: "Model", type: "text" },
    { id: "json_schema_name", label: "Schema Name", type: "text" },
    { id: "name", label: "Name", type: "text" },
    { id: "searchable", label: "Key Words", type: "text" },
    { id: "company", label: "Company", type: "text" }
  ];

  const companyFile = [
    { id: "model", label: "Model", type: "text" },
    { id: "name", label: "Name", type: "text" },
    { id: "notes", label: "Notes", type: "text" },
    { id: "company", label: "Company", type: "text" }
  ];

  const companyDomain = [
    { id: "model", label: "Model", type: "text" },
    { id: "name", label: "Domain Name", type: "text" },
    { id: "registrar", label: "Registrar", type: "text" },
    { id: "expiration_date", label: "Expiration Date", type: "text" },
    { id: "ssl_expiration_date", label: "SSL Expiration Date", type: "text" },
    { id: "company", label: "Company", type: "text" },
    { id: "sub_domains", label: "Sub Domains", type: "html" }
  ];

  const editorConfiguration = {
    toolbar: {
      items: []
    }
  };

  const onEditorInit = editor => {
    editor.editing.view.change(writer => {
      writer.setStyle(
        "height",
        "calc(100vh/4)",
        editor.editing.view.document.getRoot()
      );
    });
  };
  const showProperty = item => {
    if (props.data[item.id]) {
      if (item.type === "password") {
        return (
          <React.Fragment key={item.id}>
            <Grid item xs={4} sm={3} className={classes.smallText}>
              {item.label}
            </Grid>
            <Grid item xs={5} sm={6}>
              <TextField
                key="outlined-password-input"
                type={"text"}
                name="password"
                variant="outlined"
                fullWidth
                size="small"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.adornedStart}
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="start"
                        className={classes.passwordIcon}
                      >
                        {showPassword ? (
                          <Visibility className={classes.visibilityIcon} />
                        ) : (
                          <VisibilityOff className={classes.visibilityIcon} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  readOnly: true,
                  className: `${classes.bold} ${classes.smallText}`
                }}
                inputProps={{
                  className: `${classes.passwordField}`
                }}
                value={showPassword ? props.data[item.id] : "Show password"}
              />
            </Grid>
            <Grid item xs={3} sm={3}>
              <Tooltip
                onClose={() => {
                  setCopy(false);
                }}
                title={
                  copy ? (window.innerWidth < 800 && "Copied") || "" : "Copy"
                }
              >
                <CopyIcon
                  className={classes.copy}
                  fontSize="small"
                  onClick={() => {
                    item.onClick(props, props.data, item.id);
                    setCopy(true);
                  }}
                />
              </Tooltip>
            </Grid>
            <Snackbar
              open={copy}
              autoHideDuration={15000}
              anchorOrigin={{ horizontal: "center", vertical: "top" }}
              onClose={() => setCopy(false)}
            >
              <MuiAlert
                elevation={6}
                variant="filled"
                onClose={() => setCopy(false)}
                severity="success"
              >
                Password copied!
              </MuiAlert>
            </Snackbar>
          </React.Fragment>
        );
      } else if (item.type === "link") {
        return (
          <React.Fragment key={item.id}>
            <Grid item xs={4} sm={3} className={classes.smallText}>
              {item.label}
            </Grid>
            <Grid item xs={8} sm={9}>
              <span
                className={`${classes.rightText} ${classes.link}`}
                onClick={
                  item.onClick
                    ? () => {
                        item.onClick(props, props.data, item.id);
                      }
                    : () => {}
                }
              >
                {props.data[item.id]}
              </span>
            </Grid>
          </React.Fragment>
        );
      } else if (item.type === "editor") {
        return (
          <React.Fragment key={item.id}>
            <Grid item xs={4} sm={3} className={classes.smallText}>
              {item.label}
            </Grid>
            <Grid item xs={8} sm={9}>
              <CKEditor
                editor={ClassicEditor}
                config={editorConfiguration}
                onInit={onEditorInit}
                disabled
                data={props.data[item.id]}
              />
            </Grid>
          </React.Fragment>
        );
      } else if (item.type === "html") {
        return (
          <React.Fragment key={item.id}>
            <Grid item xs={12} sm={12} className={classes.smallText}>
              {item.label}
              <hr/>
            </Grid>
            <Grid item xs={12} sm={12} className={classes.smallText}>
              <div dangerouslySetInnerHTML={{ __html: props.data[item.id] }}></div>
            </Grid>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={item.id}>
            <Grid item xs={4} sm={3} className={classes.smallText}>
              {item.label}
            </Grid>
            <Grid
              item
              xs={8}
              sm={9}
              className={`${classes.bold} ${classes.rightText}`}
            >
              {props.data[item.id]}
            </Grid>
          </React.Fragment>
        );
      }
    }
  };

  var option = {
    Company: company,
    Site: site,
    Document: document,
    "Server and Device": device,
    Service: service,
    "Software License": software_license,
    Employee: employee,
    "Customized Data": jsonData,
    "File": companyFile,
    "Domain": companyDomain,
  };

  useEffect(() => {
    setModelType(option[props.data.model]);
  }, [props.data, showPassword, copy]);

  return (
    <React.Fragment>
      <Paper className={classes.rightSearchPane}>
        <Grid
          container
          spacing={1}
          direction="column"
          justify="space-between"
          alignItems="stretch"
        >
          <Grid item>
            <Typography
              variant="h4"
              component="h4"
              className={classes.title}
            >
              {props.data.name}
            </Typography>
            <Divider variant="middle" className={classes.divider}/>
          </Grid>
          <Grid item>
            <Grid
              container
              direction="row"
              justify="flex-start"
              alignItems="center"
              spacing={1}
              className={classes.rightText}
            >
              {ModelType && ModelType.map(item => showProperty(item))}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default ShowDetails;
