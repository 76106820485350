import React, { useEffect, useState } from "react";
import ObjectList from "../../Common/ObjectList";
import ObjectListMobile from "../../Common/ObjectListMobile";
import commonConstants from "../../Common/CommonConstants";
import http from "../../Common/RestAPIHandler";
import { onWebsiteClick } from "../../Common/TableUtility";
import { formatPhoneNumber } from "../../Common/Utility";
import DeleteIcon from '@material-ui/icons/Delete';

const tableColumns = [
  {
    id: "company_name",
    label: "COMPANY NAME",
    minWidth: 180,
    sortable: true,
    colored: true,
    style: commonConstants.cellCursor,
    onClick: (props, object, property) => {
      window.location = process.env.PUBLIC_URL + `/company/${object.company}`;
    },
  },
  {
    id: "from_tenant_name",
    label: "FORM TENANT",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "main_phone_number",
    label: "PHONE",
    minWidth: 100,
    sortable: true,
  },
  {
    id: "industry_type",
    label: "INDUSTRY TYPE",
    minWidth: 150,
    sortable: true,
  },
  {
    id: "website",
    label: "WEBSITE",
    minWidth: 150,
    sortable: true,
    colored: true,
    style: commonConstants.cellCursor,
    onClick: onWebsiteClick,
  },
  {
    id: "status",
    label: "STATUS",
    minWidth: 80,
    sortable: true,
  },
  {
    id: "actions",
    label: "",
    minWidth: 80,
    sortable: true,
    colored: true,
  },
];

const Sharings = (props) => {
  const [data, setData] = useState([]);
  const [tenants, setTenants] = useState();
  const [loadData, setLoadData] = useState(false);
  const [error, setError] = useState();

  const url = `sharings?tenant=${props.user.tenant}`;

  useEffect(() => {
    http.Get(
      `tenant_tenant_mappings?to_tenant=${props.user.tenant}&is_accepted=true`,
      (data) => {
        setTenants(data);
      },
      (status, error) => {
      }
    );
  }, []);

  useEffect(() => {
    if(tenants){
      fetchData();
    }
  }, [tenants]);

  const fetchData = () => {
    setLoadData(true);
    http.Get(
      url,
      (data) => {
        var companies = data.filter((c)=>(tenants.some((t)=>(t.from_tenant===c.from_tenant))));
        companies.forEach(
          (item) => (
            (item.rp_disabled = !item.status),
            (item.status = item.status ? "Active" : "Inactive"),
            (item.company_name =
              item.company_name === "Syncro Customer"
                ? item.first_name + " " + item.last_name
                : item.company_name),
            (item.main_phone_number = formatPhoneNumber(
              item.main_phone_number
            )),
            (item.actions = (item.readonly? "READONLY": 
            <DeleteIcon 
              style={{margin:10, cursor:"pointer"}} 
              onClick={()=>{   
                setLoadData(true);
                http.Delete(
                  `sharings/${item.id}`,
                  {},
                  (data) => {
                    fetchData();
                  },
                  (status, error) => {
                    setError(error);
                    setTimeout(() => {
                      setError(null);
                    }, 5000);
                    setLoadData(false);
                  }
                );
            }}/>))
          )
        );
        setData(companies);
        setLoadData(false);
      },
      (status, error) => {
        setError(error);
        setTimeout(() => {
          setError(null);
        }, 5000);
        setLoadData(false);
      }
    );
  };

  return (
    <div>
      {!props.mobile && (
        <ObjectList
          {...props}
          moduleName={"Companies From Other Tenants"}
          url={"sharings"}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"company_name"}
          searchFields={["company_name"]}
          pageHeader={"Companies From Other Tenants"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
      {props.mobile && (
        <ObjectListMobile
          {...props}
          moduleName={"Companies From Other Tenants"}
          url={"sharings"}
          tableColumns={tableColumns}
          data={data}
          fetchData={fetchData}
          orderBy={"company_name"}
          searchFields={["company_name"]}
          pageHeader={"Companies From Other Tenants"}
          loadData={loadData}
          excludeAddButton={true}
        />
      )}
    </div>
  );
};

export default Sharings;
