import React, { useEffect, useState } from "react";
import MuiTableCell from "@material-ui/core/TableCell";
import http from "../../../../Common/RestAPIHandler";
import { withStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ScreenLoader from "../../../../Common/Loader";
import {
  Divider,
  Table,
  TableHead,
  TableRow,
  TableSortLabel,
  TableBody,
  Collapse,
  Grid,
  TableContainer,
  Paper,
} from "@material-ui/core";
import useStyles from "../Style/style";

const TableCell = withStyles({
  root: {
    borderBottom: "none",
  },
})(MuiTableCell);

const LocationData = (props) => {
  const classes = useStyles();
  const [tenantAddresses, setTenantAddresses] = useState([]);
  const [collapse, setCollapse] = useState(false);
  const [loadData, setLoadData] = useState(false);
  var rowCount = 0;
  useEffect(() => {
    setLoadData(true);
    http.Get(
      "tenant_address",
      (data) => {
        setTenantAddresses(data);
        setLoadData(false);
      },
      () => {
        setLoadData(false);
      }
    );
  }, []);

  const handleArrowClick = () => {
    setCollapse(!collapse);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.gridItem}>
          <Grid
            container
            spacing={3}
            alignItems="center"
            className={classes.collapse}
          >
            <Grid item xs={11}>
              <span className={classes.title}>
                NUMBER OF EMPLOYEES PER LOCATION
              </span>
            </Grid>
            <Grid item xs={1} className={classes.icons}>
              {(collapse && (
                <ArrowDropDownIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )) || (
                <ArrowDropUpIcon
                  fontSize="default"
                  className={classes.collapseButton}
                  onClick={handleArrowClick}
                />
              )}
            </Grid>
          </Grid>
          <Divider
            classes={{ root: classes.dividers }}
            component="hr"
            orientation="horizontal"
          />
        </Grid>

        {loadData ? (
          <ScreenLoader />
        ) : (
          <Collapse in={!collapse}>
            <Grid item xs={12} className={classes.tableGrid}>
              <TableContainer
                component={Paper}
                classes={{ root: classes.tableContainer }}
              >
                <Table
                  stickyHeader
                  aria-label="simple table"
                  className={classes.table}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        <TableSortLabel>LOCATION</TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="left"
                        className={`${classes.ellipsis} ${classes.tableHeader}`}
                      >
                        TOTAL
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tenantAddresses.map((row) => {
                      rowCount = rowCount + 1;
                      return (
                        <TableRow
                          id="tableRow"
                          key={row.id}
                          className={classes.tableRow}
                          // style={{
                          //   height: "30px",
                          //   backgroundColor:
                          //     rowCount % 2 === 1 ? "#EEEEEE" : "white",
                          // }}
                        >
                          <TableCell
                            className={`${classes.ellipsis} ${classes.tableCell}`}
                            component="td"
                            scope="row"
                          >
                            {row.location_str}
                          </TableCell>
                          <TableCell
                            className={`${classes.tableCell} ${classes.ellipsis}`}
                            component="td"
                            scope="row"
                          >
                            {row.employee_count}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Collapse>
        )}
      </Grid>
    </div>
  );
};
export default LocationData;
